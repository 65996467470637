import { DatePicker } from "@mui/lab";
import React,{useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import { Button } from "react-bootstrap";
import { SingleToDouble } from "../Common Components/CommonFunctions";
import firebase from "../Common Components/firebase";



export default function EditAffliationModal({setOpenModal, userId, affliationId }){
    
    const [name, setName] = useState("")
    const [post, setPost] = useState("")
    const [startTimeMonth, setstartTimeMonth] = useState("01")
    const [endTimeMonth, setEndTimeMonth] = useState("01")
    const endYear = (new Date()).getFullYear()
    const [startTimeYear, setStartTimeYear] = useState(endYear.toString())
    const [endTimeYear, setEndTimeYear] = useState(endYear.toString())
    const [yearArray, setYearArray] = useState([])
    const [tillToday, setTillToday] = useState(false)
    const [endDisabled, setEndDisabled] = useState(false)

    useEffect(()=>{
        firebase.firestore().collection("users").doc(userId).collection("affliations").doc(affliationId).get().then((doc)=>{
            setName(doc.data().organizationname)
            doc.data().tilltoday == 'yes' ? setTillToday(true) : setTillToday(false)
            setStartTimeYear(doc.data().starttimeyear)
            setEndTimeYear(doc.data().endtimeyear)
            setstartTimeMonth(doc.data().starttimemonth)
            setEndTimeMonth(doc.data().endtimemonth)
            setPost(doc.data().post)
        })
    },[])

    function EditAffliations(){
        
        firebase.firestore().collection("users").doc(userId).collection("affliations").doc(affliationId).update(
            {
                organizationname : name,
                post : post,
                tilltoday : tillToday ? "yes" : "no",
                starttimemonth : startTimeMonth,
                starttimeyear : startTimeYear,
                endtimemonth : endTimeMonth,
                endtimeyear : endTimeYear,
                affliationid : affliationId

            }
        ).then(()=>{
            setOpenModal(false)
            window.location.reload()
        })
    }

    useEffect(()=>{
        if(tillToday){
            setEndTimeYear(((new Date()).getFullYear()).toString())
            setEndDisabled(true)
            setEndTimeMonth(SingleToDouble(((new Date()).getMonth()+1).toString()))
        } else{
            setEndDisabled(false)
        }
    },[tillToday])

    useEffect(()=>{
        let temp=[]
        for(let i=0; i<100; i++){
            temp.push(endYear-i)
        }
        setYearArray(temp)
    },[])
    return(    <Modal show={true}    onHide={setOpenModal}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
        <h1 style={{fontSize:"20px"}}>Edit Your Affliation</h1> 
        </Modal.Header>
        <Modal.Body>
        
        <div className="inputBx1">
        <span>Organization's Name</span>
        <input type="text" value={name} onChange={(e)=> setName(e.target.value)}/>
        </div>
        <div className="inputBx1">
        <span>Post</span>
        <input type="text" value={post} onChange={(e)=> setPost(e.target.value)}/>
        </div>
        <div className="inputBx1">
        <span>Start Time</span> <br/>
            <select value={startTimeMonth} style={{width:"35%"}} onChange={(e)=> setstartTimeMonth(e.target.value)}>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
            </select>
            &nbsp; &nbsp; &nbsp;
            <select value={startTimeYear} style={{width:"35%"}} onChange={(e)=> setStartTimeYear(e.target.value)}>
                {yearArray.map((index)=>(
                    <option value={index.toString()}>{index.toString()}</option>
                ))}
            </select>
        </div>

        <div className="inputBx1">
        <span>End Time</span> <br/>
            <select disabled={endDisabled}value={endTimeMonth} style={{width:"35%"}} onChange={(e)=> setEndTimeMonth(e.target.value)}>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
            </select>
            &nbsp; &nbsp; &nbsp;
            <select disabled={endDisabled} value={endTimeYear} style={{width:"35%"}} onChange={(e)=> setEndTimeYear(e.target.value)}>
                {yearArray.map((index)=>(
                    <option value={index.toString()}>{index.toString()}</option>
                ))}
            </select> OR <br/> <input type={"checkbox"} style={{width:"5%", cursor:"pointer"}} checked={tillToday} onClick={()=> setTillToday(!tillToday)} /> Till Today
            </div>
   
        </Modal.Body>
        <Modal.Footer>
              <Button variant="primary" onClick={EditAffliations} >
                Save
              </Button>
              <Button variant="secondary" onClick={()=> setOpenModal(false)}>
                Close
              </Button>
    
            </Modal.Footer>
        </Modal>)
}
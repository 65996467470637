import React from 'react';
import { AuthProvider } from './auth/Auth';
import Login from './auth/Login';
import Welcome from './Welcome';
import PrivateRoute from "./Common Components/PrivateRouter";
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import MemberDirectory from './MemberDirectory/MemberDirectory';
import Memberdetails from './MemberDirectory/memberdetails';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import UpdateProfile from './Update Profile/UpdateProfile';
import './App.css'
import MemberVerification from './Registration/MemberVerification';
import BloodDonation from './Blood Bank/BloodDonation';
import Notice from './Notice/Notice'
import Chats from "./Chatbox/Chats"
import BloodBankFamily from './Blood Bank/BloodBankFamily';
import MyProducts from './Products/MyProducts';

import Secreteriat from './Committees/Secreteriat';

import NewRegData from './NewRegData/NewregData';
import AllMembers from './MemberDirectory/AllMembers';
import AdminState from './AdminContext/AdminState';
import AllBusinesses from './AllBusinesses.js/AllBusinesses';
import GalleryMain from './Gallery/Gallery';
import BusinessDetails from './AllBusinesses.js/BusinessDetails';

function App() {

  return (
    <BrowserRouter>

         <AuthProvider>
          <AdminState>
        
          <Routes>
          {/* <PrivateRoute exact path="/gallery" component={Gallery}/>
          <PrivateRoute exact path ="/chats" component={Chats}/>
          <PrivateRoute exact path="/" component={Welcome} />
          <PrivateRoute exact path="/family-bloodbank" component ={BloodBankFamily} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/import" component={ImportNewReg} />
          <Route exact path="/registration" component={MemberVerification} />
          <PrivateRoute exact path="/notice" component={Notice} />
          <PrivateRoute exact path="/blood-bank" component={BloodDonation} />
          <PrivateRoute exact path="/member-portal" component={MemberDirectory} />
          <PrivateRoute exact path="/member-portal/:id"  component={Memberdetails} />
          <PrivateRoute exact path="/update-profile"  component={UpdateProfile} />
          <PrivateRoute exact path="/my-profession"  component={MyBusiness} />
          <PrivateRoute exact path="/my-profession/:id/products"  component={MyProducts} />
          <PrivateRoute exact path='/executive-committee' component={ExecutiveCommittee} />
          <PrivateRoute exact path='/secretariat' component={Secreteriat} />
          <PrivateRoute exact path='/convenor' component={Convenor} />
          <PrivateRoute exact path='/special-invitee' component={SpecialInvitee} />
          <PrivateRoute exact path='/permanent-invitee' component={PermanentInvitee} />
          <PrivateRoute exact path='/founding-members' component={FoundingMembers} />
          <PrivateRoute exact path='/products' component={AllProducts} />
          <PrivateRoute exact path='/businesses' component={AllBusinesses} />
          <PrivateRoute exact path='/newreg' component={NewRegData} />
          <PrivateRoute exact path="/all-members" component={AllMembers} /> */}
      
          <Route path='/login' element={<Login/>}/>
          <Route path="/" element={<PrivateRoute><Welcome/></PrivateRoute>} />
          <Route path='/member-portal' element={<PrivateRoute> <MemberDirectory/>  </PrivateRoute>} />
          <Route path='/member-portal/:id' element={<PrivateRoute> <Memberdetails/> </PrivateRoute>} />
          <Route path="/registration" element={<MemberVerification/>} />
          <Route path="/all-members" element={<PrivateRoute><AllMembers/></PrivateRoute>} />
          <Route path="/newreg" element={<PrivateRoute><NewRegData/></PrivateRoute>}/>
          <Route path='/update-profile' element={<PrivateRoute><UpdateProfile/></PrivateRoute>}/>
          <Route path='/blood-bank' element={<PrivateRoute><BloodDonation/> </PrivateRoute>} />
          <Route path="/family-bloodbank" element={<PrivateRoute><BloodBankFamily/></PrivateRoute>} />
          <Route path='/businesses' element={<PrivateRoute><AllBusinesses/>  </PrivateRoute>} />
          <Route path='/gallery' element={<PrivateRoute> <GalleryMain/> </PrivateRoute>} /> 
          <Route path='/businesses/:id' element={<PrivateRoute> <BusinessDetails/> </PrivateRoute>} />
          <Route path='/secretariat' element={<PrivateRoute> <Secreteriat/> </PrivateRoute>}/>
          
          </Routes>
        

        </AdminState>
        </AuthProvider>
      
      </BrowserRouter>
    
     
      
  
  );
}

export default App;
import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow, MDBDataTable} from "mdbreact";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Alert, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import "../Modal.css";
import "./BloodBank.css"
import TitleCase from 'react-title-case';
import { AuthContext } from "../auth/Auth";
import LoaderUi from '../Common Components/LoaderUi';
import SideMenuBar from "../Navbar/SideMenuBar"
import { LoginNavbar } from '../Navbar/LoginNavbar';
import { JsonArrUnique, placeholderManImage, toTitleCase } from '../Common Components/CommonFunctions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ExportCSVCommon } from '../Common Components/ExportCSVCommon';
import FamilyBloodBankModal from '../Modal/FamilyBloodBankAddModal';
import DeleteIcon from '@mui/icons-material/Delete';
import Footer from '../Footer/Footer';





function BloodDonation() {
 
  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [userType, setUserType] = useState("")
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("A+")
  const[searchTerm, setSearchTerm] = useState(" ");
  const [displayArray, setDisplayArray] = useState([])
  const {currentUser} = useContext(AuthContext);
  const [users,  setusers] = useState([])
  const [value, setValue] = useState("1")
  const [bloodBankDisplay, setBloodBankDisplay] = useState([])
  const uid = currentUser.uid
  const [myFamily, setMyFamily] = useState([])
  const [myFamilyDisplay, setMyFamilyDisplay] = useState([])
  const [name, setName] = useState("")
  const [addFamilyModalOpen, setAddFamilyModalOpen] = useState(false)

  const ref1 = firebase.firestore().collection('users').doc(uid)
  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
          let x = doc.data().salutation ? doc.data().salutation +" " :"" 
          setName(x+ toTitleCase(doc.data().name))
          setUserType(doc.data().type)
        
               } else {
          // doc.data() will be undefined in this case
        }
      })
      .catch((error) => {});
     
    
  }, []);
  


  
const changeSelectOptionHandler = (event) => {
	setSelectedBloodGroup(event.target.value);
  setSearchTerm("")
 
};


let  ref = firebase.firestore().collection("users").orderBy('name');


 

 function getSchools(){

   setloading(true);
   const items=[];
   const items1 = [];
   ref.onSnapshot((querySnapshot)=>{
    
     querySnapshot.forEach((doc)=>{
       items1.push(doc.data())
        if(doc.data().eligible != undefined && doc.data().eligible == "yes" && doc.data().memstatus == 'active'){
          let x={}
          x['Name'] = doc.data().salutation +" " + toTitleCase(doc.data().name)
          x['Blood Group'] = doc.data().bloodgroup
          x['Membership Id'] = doc.data().mid
          x["Relationship"] = "Self"
          x["Email"] = doc.data().email
          x["Whatsapp"] = doc.data().whatsapp
          x["Mobile"] = doc.data().phone
          x ["Address"] = toTitleCase(doc.data().address)

          items.push(x);
        }
           
        
       
     });
     setusers(items1)

     setschools(items);
     setloading(false);
   })
 }  

 function getFamilyData(){
   setloading(true)
   const famRef = firebase.firestore().collection("BloodBankFamily")
  let items =[]
  let items1 =[]
   famRef.onSnapshot((querySnapshot)=>{
     querySnapshot.forEach((doc)=>{
       let x ={}
       x['Name'] =  toTitleCase( doc.data().name)
       x['Blood Group'] = doc.data().bloodgroup
       x['Email'] = doc.data().email
       x['Mobile'] = doc.data().phone
       x["Membership Id"] = "N/A"
       x['Address'] = doc.data().address
      x['Relationship'] = doc.data().relation +" (" + doc.data().oname+" )"
      x['Whatsapp'] = doc.data().whatsapp
      x['MyRelationship'] = doc.data().relation
       items.push(x)

       if(doc.data().userid == uid){
        x['relationshipid'] = doc.id
        items1.push(x)
       }
       
     })
     
     setschools(schools => [...schools, ...items])
      console.log(items1)

     setMyFamily(items1)
     setloading(false)
   })
 }
 
 useEffect(()=>{
   getSchools();
   getFamilyData();
  
   
 },[]);

 

 useEffect(()=>{
  let temp =[]

  for(let i=0; i<schools.length; i++){
    if(schools[i]['Blood Group'] === selectedBloodGroup)
      temp.push(schools[i])

  }
  // console.log(temp)
  
  setDisplayArray(JsonArrUnique(temp))

 },[selectedBloodGroup, schools])

 function getNameByUserId(userId){
  
 for(let i=0; i<users.length; i++){
   
   if(users[i]["userid"] == userId){
     
      return users[i]["name"]
   }
 }
   }

   
  useEffect(()=>{
 
   
    let postsArray = JSON.parse(JSON.stringify(displayArray));
    let userData = [];
    postsArray.map((item, index) => {
      item.id = (
        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.mid}</div>
      );
      item.serialno =(<div><b>{index+1}</b></div>)
      item.action = (
          <>
              <EmailIcon style={{cursor:"pointer", marginRight:"2px"}} onClick={()=> window.location.href=`mailTo:${item.Email}`} />
              <WhatsAppIcon style={{cursor:"pointer",  marginRight:"2px"}} onClick={()=> window.location.href=`https://wa.me/91${item.Whatsapp}`} />
              <PhoneInTalkRoundedIcon style={{cursor:"pointer",  marginRight:"2px"}}  onClick={()=> window.location.href=`tel:${item.Mobile}`} />
              <LocationOnRoundedIcon style={{cursor:"pointer",  marginRight:"2px"}}  onClick={()=>window.location.href=`https://www.google.com/maps/dir//${item.Address}`} />
          </>
      );
      userData.push(item);
    });
  setBloodBankDisplay(userData);
  }, [displayArray]);


    const data = {
        columns: [
          {
            label: 'Sl No.',
            field: 'serialno',
            sort: 'asc',
            width: 150,
          },
           
            {
                label: 'Membership ID',
                field: 'Membership Id',
                sort: 'asc',
                width: 150,
                
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 150,
              },
              {
                label:'Blood Group',
                field: 'Blood Group',
                sort: 'asc',
                width: 150
              },
              {
                label:'Relationship',
                field: 'Relationship',
                sort: 'asc',
                width: 150
              },
              {
                label:'Email',
                field: 'Email',
                sort: 'asc',
                width: 300
              },
              {
                label:'Whatsapp',
                field: 'Whatsapp',
                sort: 'asc',
                width: 150
              },
              {
                label:'Mobile',
                field: 'Mobile',
                sort: 'asc',
                width: 150
              },  {
                label:'Address',
                field: 'Address',
                sort: 'asc',
                width: 150
              },
           {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: bloodBankDisplay

      };

      function DeleteBloodBankFamily(id){
  
            if(window.confirm(`Are You Sure ?\nThis can't be undone !!`)){
              setloading(true)
              firebase.firestore().collection("BloodBankFamily").doc(id).delete().then(()=>{
                window.alert("Data Deleted !!")
                window.location.reload();
              })
            }
         
      }
    
      useEffect(()=>{

        let postsArray = JSON.parse(JSON.stringify(myFamily));
        let userData = [];
        postsArray.map((item, index) => {
          item.serialno =(<div><b>{index+1}</b></div>)
          item.action = (
              <>
                <DeleteIcon style={{color:"red", cursor:"pointer"}} onClick={()=>DeleteBloodBankFamily(item.relationshipid)}/>
              </>
          );
          userData.push(item);
        });
      setMyFamilyDisplay(userData);
      }, [myFamily]);
    
    
        const familyData = {
            columns: [
              {
                label: 'Sl No.',
                field: 'serialno',
                sort: 'asc',
                width: 150,
              },
               
                {
                    label: 'Membership ID',
                    field: 'Membership Id',
                    sort: 'asc',
                    width: 150,
                    
                  },
                  {
                    label: 'Name',
                    field: 'Name',
                    sort: 'asc',
                    width: 150,
                  },
                  {
                    label:'Blood Group',
                    field: 'Blood Group',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label:'Relationship',
                    field: 'MyRelationship',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label:'Email',
                    field: 'Email',
                    sort: 'asc',
                    width: 300
                  },
                  {
                    label:'Whatsapp',
                    field: 'Whatsapp',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label:'Mobile',
                    field: 'Mobile',
                    sort: 'asc',
                    width: 150
                  },  {
                    label:'Address',
                    field: 'Address',
                    sort: 'asc',
                    width: 250
                  },
               {
                label: "Action",
                field: "action",
                width: 100,
              },
            ],
            rows: myFamilyDisplay
    
          };
      const handleChange = (event, newValue) => {
        setValue(newValue);

      };

   
 
 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
     <IdleTimeContainer/>
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
      <div className='flex-wrapper'>
      <div style={{ paddingTop: "10vh" }}></div>
      {addFamilyModalOpen && (<FamilyBloodBankModal setOpenModal={setAddFamilyModalOpen} oname={name}/>)}
      <TabContext value={value}>
      <div className="sideMargin">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Blood Bank" value="1" />
            <Tab label="Family Blood Bank" value="2" />
      
           
          </TabList>

       
      <TabPanel value='1'>
      <Alert severity='info'>The members who have marked themselves as Eligible for Blood Donation are shown here !!</Alert>
<br/>
    <MDBContainer  
    >
      <MDBRow>  

      <MDBCol md="4" >
      <select  style={{width:"100%",color:"#4D3A78", cursor:"pointer"}} value={selectedBloodGroup}onChange={changeSelectOptionHandler}  >
        <option value="A+">A+</option>
        <option value="A-">A-</option>
        <option value="B+">B+</option>
        <option value="B-">B-</option>
        <option value="O+">O+</option>
        <option value="O-">O-</option>
        <option value="AB-">AB-</option>
        <option value="AB+">AB+</option>
      </select>
      </MDBCol>
      <MDBCol md="6"></MDBCol>
       
      {/* <MDBCol md='3'>{userType=="admin"?<MemberPortalDownload />:""}</MDBCol> */}
      <MDBCol md='2'>{userType== "admin"  && (<ExportCSVCommon fileName={`MVT_${selectedBloodGroup}_Eligible_Donors`} buttonText={`Export ${selectedBloodGroup}`} csvData={displayArray}/>)} </MDBCol>
      </MDBRow>
     
    </MDBContainer>
   {/* <MDBContainer>
     <MDBRow>
    <MDBCol md="2"></MDBCol>
    <MDBCol md="8"><h1 style={{textAlign:'center'}}>{heading}</h1></MDBCol>
    <MDBCol md="2">    </MDBCol>
    </MDBRow>
    </MDBContainer>
    */}


    <MDBDataTable striped small scrollX data={data} />
    </TabPanel>
    <TabPanel value='2'>
      <Alert severity='info'>You can add your Family Members who are eligible for Blood Donation</Alert>
      <div style={{ justifyContent:"flex-end",alignContent:"flex-end", display:"flex", marginRight:"2%"}}>
          <button className='buttons'  onClick={()=> setAddFamilyModalOpen(true)}>Enroll Family Member</button>
      </div>
      <MDBDataTable striped small scrollX data={familyData}/>
    </TabPanel>
    </div>
    </TabContext>
 
    </div>
    <div style={{flexGrow:1}}></div>
    <Footer/>
  
    </Fragment>   
  );
}

export default BloodDonation;

import React, {
    Fragment, useEffect, useState,

  } from "react";
  import firebase from "../Common Components/firebase"
  import "./SideMenuBar.css";
  import { useLocation } from "react-router-dom";
import { SidebarData}  from "./SideMenuItems";

import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { ButtonSignOut } from '../Common Components/ButtonSignOut';
import { logo } from "../Common Components/CommonFunctions";
import MVTLogo from "../Assets/MVTBackgroundlessLogo.png"
import { useContext } from "react";
import { AuthContext } from "../auth/Auth";
import LoaderGif from "../Assets/MVT_GIF.gif";
import AdminContext from "../AdminContext/AdminContext";
  
  function Sidebar() {
    const usertype= useContext(AdminContext)
    const [currentUserType, setCurrentUserType] = useState(usertype.currentUserType)
    const {currentUser} = useContext(AuthContext)
 
    console.log()
    const userid = currentUser.uid

    const state = useLocation()
    let path = state.pathname;
    let arr = path.split("/");
    let mid = arr[1];
     function checkPath(id){
       let x= id.split("/")
  
       return x[1]
     }
   
    return (
      <Fragment>
       <div className="Sidebar" style={{float:"left", position:"fixed", zIndex:"300"}}>
            <div className="SidebarList ">
            <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {LoaderGif} width={150}/>
          </div></div>
             <hr/>
               {SidebarData.map((val, key)=>{
                    return(
                        <li key={key} className="row" onClick={()=>{window.location.pathname= val.url}}>
                         
                            <div style={{cursor:'pointer'}} className={checkPath(val.url)==mid?"selected":""}>{val.title}</div>                            
                        </li>

                        
                    )
               }) }
             <li  className="row" onClick={()=>{window.location.pathname= '/secretariat'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/secretariat')==mid?"selected":""}> Secretariat</div>                            
                     </li>

                     {currentUserType == 'admin'?<>
                     <li  className="row" onClick={()=>{window.location.pathname= '/all-members'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/all-members')==mid?"selected":""}>All Members</div>                            
                     </li>
                     <li  className="row" onClick={()=>{window.location.pathname= '/newreg'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/newreg')==mid?"selected":""}>Pending Registrations</div>                            
                     </li>

                     </>:""}


                     {/* <li  className="row" onClick={()=>{window.location.pathname= '/executive-committee'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/executive-committee')==mid?"selected":""}>Executive Committee</div>                            
                     </li>
                     <li  className="row" onClick={()=>{window.location.pathname= '/peronal-invitee'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/peronal-invitee')==mid?"selected":""}>Personal Invitee</div>                            
                     </li>
                     <li  className="row" onClick={()=>{window.location.pathname= '/special-invitee'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/special-invitee')==mid?"selected":""}>Special Invitee</div>                            
                     </li>
                     <li  className="row" onClick={()=>{window.location.pathname= '/founding-members'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/founding-members')==mid?"selected":""}>Founding Members</div>                            
                     </li> */}
             <li className="row">  <ButtonSignOut style={{fontSize:"20px", color:"#4D3A78", paddingBottom:"11px"}}><ExitToAppIcon/></ButtonSignOut> </li>
            </div>

       </div>

      </Fragment>
    );
  }
  
  export default Sidebar;
  
import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";

function AddSecreteriatModal({ setOpenModal, users, secreteriatid, secArray, availablePosts, currentuserid}) {

 const [selectedPost, setSelectedPost] = useState('')
 const [loading, setLoading] = useState(false)
 const [buttonDisabled, setButtonDisabled] = useState(false)
 const [selectedUserId, setSelectedUserId] = useState('')
 const [committeeDisplay,setCommitteeDisplay] = useState('')
 const [displayOrderJSON, setDisplayOrderJSON] = useState({})
 const [radiobuttonValue, setRadiobuttonvalue] = useState("selectlist")
 const [name, setName] = useState("")
 const [address, setAddress] = useState("")
 const [mobile, setMobile] = useState("")
 const [Whatsapp, setWhatsapp] = useState("") 
 const [email, setEmail] = useState("")

 useEffect(()=> {
     GetCommitteeYearDisplay()
     GetDisplayOrder()
},[])
useEffect(()=>{
  if(radiobuttonValue == "selectlist"){
      if(selectedUserId == ""|| selectedPost == '')
          setButtonDisabled(true)
      else
          setButtonDisabled(false)
  }else{
      if(name.trim() =="" || mobile.length !=10 || Whatsapp.length!=10 || email.trim() =="" || address.trim() =="" || selectedPost =="")
          setButtonDisabled(true)
      else
          setButtonDisabled(false)
  }
},[radiobuttonValue, selectedPost, selectedUserId,name,mobile,Whatsapp,email, address])

 function AddSecreteriat(){
     setLoading(true)
     const ref = firebase.firestore().collection('secreteriat').doc(secreteriatid)
     ref.get().then((doc)=>{
        if(!doc.exists)
         ref.set({[`${selectedPost}`]: 'r'})
        
        else
            ref.update({[`${selectedPost}`]:"r"})
        });
     const ref1 = ref.collection('posts').doc(selectedPost)
     ref1.set({
         postid : selectedPost,
         userid : selectedUserId['value'],
         adminid : currentuserid,
         postname : GetPostName(selectedPost),
         displayorder: displayOrderJSON[selectedPost],
         secreteriatid: secreteriatid
         
     }).then(()=>{
         window.location.reload()
         window.alert("New Secreteriat Added !!")
         setOpenModal(false)
      
     })
 }

 function AddInputSecretiat(){
  setLoading(true)
  let  x= (new Date()).getTime().toString()
  let id = 'inputsecreteriat'+mobile.trim()+x;
  firebase.firestore().collection('inputsecreteriat').doc(id).set({
    name:name,
    whatsapp: Whatsapp,
    phone:mobile,
    address:address,
    email:email,
    userid: id,
    image:""
  }).then(()=>{
    firebase.firestore().collection('secreteriat').doc(secreteriatid).get().then((doc)=>{
      if(!doc.exists)
      firebase.firestore().collection('secreteriat').doc(secreteriatid).set({[`${selectedPost}`]: 'r'})
      
      else
      firebase.firestore().collection('secreteriat').doc(secreteriatid).update({[`${selectedPost}`]:"r"})
      });
     firebase.firestore().collection('secreteriat').doc(secreteriatid).collection('posts').doc(selectedPost).set({
        postid : selectedPost,
        userid : id,
        adminid : currentuserid,
        postname : GetPostName(selectedPost),
        displayorder: displayOrderJSON[selectedPost],
        secreteriatid: secreteriatid
        
    }).then(()=>{
        window.location.reload()
        window.alert("New Secretariat Added !!")
        setOpenModal(false)
     
    })
  })
 }
 
    function GetCommitteeYearDisplay(){
        
        for(let i=0; i< secArray.length; i++){
            if(secArray[i]['id'] == secreteriatid){
                setCommitteeDisplay(secArray[i]['value'])
                return
            }
        }
    }
 function GetPostName(postid){
     for(let i=0; i< availablePosts.length; i++){
         if(availablePosts[i]['id'] == postid){
            return availablePosts[i]['displayname']
         }

     }
 }
 function GetDisplayOrder(){

     firebase.firestore().collection('secreteriat').doc('displayorder').get().then((doc)=>{
         setDisplayOrderJSON(doc.data())
     })
 }


  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"60vw", overflowY:"scroll"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Add New Secretariat !! </h2> </center>

        </div>
        {loading ? <ModalLoaderUi/> :<>
        <div className="body">{availablePosts.length !=0 ? <>
        <div className="inputBx1">
        <input type="radio" value="selectlist" name="secreteriatselection" onChange={(e)=> setRadiobuttonvalue(e.target.value) } checked={radiobuttonValue === "selectlist"} style={{width:"5%"}}/> Select From List
         <input type="radio" value="inputdata" name="secreteriatselection"onChange={(e)=> setRadiobuttonvalue(e.target.value) } checked={radiobuttonValue === "inputdata"} style={{width:"5%",marginLeft:"1vw"}}/> Input Data <br/>
        </div>
        <div className="inputBx1">
                 <span>Committee Year</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={committeeDisplay} disabled={true}/>
               </div>
        <div className="inputBx1">
                 <span>Position</span><span style={{color:"red"}}>*</span><br/>
                 <select value={selectedPost}  onChange={(e)=> setSelectedPost(e.target.value) }>         
                 <option value="">Choose</option>
                {availablePosts.map((val, key)=> (<option value={val.id} key={key}>{toTitleCase(val.displayname)}</option>))}
                   </select>  
               </div>
{radiobuttonValue == "selectlist" ? 
               <div className="inputBx1">
                 <span>Choose Member</span><span style={{color:"red"}}>*</span><br/>
               
                <Select options={users} placeholder="Select User... Type to Search" value={selectedUserId} onChange={(e)=> setSelectedUserId(e)} />
               </div>
               :<>
                  <div className="inputBx1">
                 <span>Name</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={name}  onChange={(e)=> setName(e.target.value)}/>
               </div>
               <div className="inputBx1">
                 <span>Mobile No</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={mobile} maxLength={10} onChange={(e)=> setMobile(e.target.value)} />
               </div>
               <div className="inputBx1">
                 <span>Whatsapp</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={Whatsapp} maxLength={10} onChange={(e)=> setWhatsapp(e.target.value)} />
               </div>
               <div className="inputBx1">
                 <span>Email</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={email} onChange={(e)=> setEmail(e.target.value)} />
               </div>
               <div className="inputBx1">
                 <span>Address</span><span style={{color:"red"}}>*</span><br/>
                <input type="text" value={address} onChange={(e)=> setAddress(e.target.value)} />
               </div>
               </>}
               </>
            :<>
                <h1>No More Positions available for selected Year !</h1>
            </>}
        </div>
        <div className="footer">
       <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
          {availablePosts.length !=0 ?
     
          <button
            onClick={() => {
              {radiobuttonValue== "selectlist"?  AddSecreteriat() :AddInputSecretiat()}
            }}
             disabled={buttonDisabled}
          >
          Add Secreteriat
          </button>
          :""}
        </div>
        </>}
      </div>
   
    </div>
  );
}

export default AddSecreteriatModal;
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toTitleCase } from '../Common Components/CommonFunctions';

export default function ViewAffliationDialog({setDialogueClose, membername}) {
 



  return (
    <div>
      
      <Dialog
        open={true}
        onClose={()=>setDialogueClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        View Affliations 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No Affliations Present for  {toTitleCase(membername)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={()=>setDialogueClose(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

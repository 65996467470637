import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Button.css';
import firebase from "../Common Components/firebase"
import "../Navbar.css"

const STYLES = [
    'btn--primary',
    'btn--outline'
]

const SIZES = [
    'btn--medium',
    'btn--large'
]

export const ButtonSignOut =({children,type, style, buttonStyle, buttonSize})=>{
    const navigate = useNavigate()
    const checkButtonStyle = STYLES.includes(buttonStyle) ?buttonStyle:SIZES[0];
    const checkButtonSizes = STYLES.includes(buttonSize) ?buttonSize:SIZES[0];
    function SignOut(){
        firebase.auth().signOut().then(()=>{
            navigate("/login")
        })
    }
  

    return(<button className={`btn ${checkButtonStyle} nav-links`}  onClick={SignOut }  style={style} type={type} >{children}</button>)

}
// ${checkButtonSizes}`}
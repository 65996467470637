import React, { useEffect, useState } from "react";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import { toTitleCase, placeholderManImage, JsonArrUnique } from "../Common Components/CommonFunctions";
import { LoginNavbar } from "../Navbar/LoginNavbar"
import { Navbar } from "../Navbar/Navbar";
import SideMenuBar from "../Navbar/SideMenuBar"
import { MDBCol, MDBContainer, MDBRow} from "mdbreact"
import './AllBusinesses.css'
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { InfoRounded } from "@mui/icons-material";
import { Alert, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../View.css'
import {TextField } from '@mui/material';

import AllBusinessImageModal from "../Modal/AllBusinessImageModal";
import BusinessDetailsModal from "../Modal/BusinessDetailsModal";
import PersonIcon from '@mui/icons-material/Person';
import Footer from "../Footer/Footer";
export default function AllBusinesses(){
      const [business,setBusiness] = useState([])
    const [loading, setLoading] = useState(true)
    const [businessCategory, setBusinessCategory] = useState([])
    const [businessSubCategory, setBusinessSubCategory] = useState([])
    const [biztype, setBiztype] = useState("")
    const [bizsubtype, setBizsubtype] = useState("")
    const [displayBusinessSubCategory, setDisplayBusinessSubCategory] = useState([[]])
    const [displayArray, setDisplayArray] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [imgObj, setImgObj] = useState({})
    const [openImageModal, setOpenImageModal] = useState(false)
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [finalDisplayArray, setFinalDisplayArray] = useState([])
    const [detailsObj,setDetailsObj] = useState({})
    function GetBusiness(){
        const ref =  firebase.firestore().collection('users')
       
        ref.onSnapshot((querySnapshot)=>{
          querySnapshot.forEach((usersdoc)=>{
              const ref1 =  ref.doc(usersdoc.id).collection('business')
              let temp =[]
              ref1.onSnapshot((querySnapshot1)=>{
                  querySnapshot1.forEach((businessdoc)=>{
                    temp.push(businessdoc.data())
                  })
                  setBusiness(businesses=>[...businesses,...temp])
                  setLoading(false)
              })
              
  
          })
        })

      
    }
    function getbiztype(){
        const ref = firebase.firestore().collection("BusinessCategories")  
        ref.onSnapshot((querySnapshot)=>{
            let items=[]
            let items1=[]
            let itemsJson ={}
            querySnapshot.forEach((doc)=>{
               items.push(doc.id)
               itemsJson[doc.id] = Object.keys(doc.data())
               items1.push(itemsJson)
            });
            setBusinessCategory(items)
            setBusinessSubCategory(items1)
          })
    }
    
    const handleBizType=(e)=>{
      
        let bsub = [...displayBusinessSubCategory]
       
        if(e== ""){
         bsub=[]
        } 
        else{
         
         bsub= businessSubCategory[0][e.toLowerCase()]
   
        }
        setDisplayBusinessSubCategory(bsub)
      }
      Array.prototype.insert = function(index,item){
        this.splice(index,0, item)
      };
        
    useEffect(()=>{
        getbiztype()
       
        
    },[])

    function OpenBusinessImagesModal(doc){
      setImgObj(doc)
      setOpenImageModal(true)
    }
    function OpenBusinessDetailsFunc(doc){
      setDetailsObj(doc)
      setOpenDetailsModal(true)
    }

    useEffect(()=>{
        GetBusiness()
    },[])
    useEffect(()=>{
        if(biztype == "" ){
            setDisplayArray(JsonArrUnique(business))
        }else {
            if(bizsubtype == ""){
                let temp = business.filter((val)=>{if(val.biztype == biztype) {return val}})
                setDisplayArray(JsonArrUnique( temp))
            }else{
                let temp = business.filter((val)=>{if(val.biztype == biztype && val.bizsubtype == bizsubtype) {return val}})
                setDisplayArray(JsonArrUnique(temp))
            }
        }
    },[business, bizsubtype, biztype])

    useEffect(()=>{
      let temp = displayArray.filter((val)=>{ if(searchTerm.trim() == ""){  return val}
      else if(val.bizname.toLowerCase().includes(searchTerm.toLowerCase()) ){return val}   }   )
      setFinalDisplayArray(temp)
    },[searchTerm,displayArray])
    if(loading){
        return (<LoaderUi/>)
    }
    return(<><div className="flex-wrapper">

 <IdleTimeContainer />
      {/* <LoginNavbar */}
      <SideMenuBar />
    <Navbar/>
{openImageModal && (<AllBusinessImageModal imgObj={imgObj} setOpenModal={setOpenImageModal} />)}
{openDetailsModal && (<BusinessDetailsModal productObj={detailsObj} setOpenModal={setOpenDetailsModal} />)}
      <MDBContainer >
    <MDBRow>
        <MDBCol md="3"></MDBCol>
        <MDBCol md="3">
        <div className="inputBx1" style={{marginTop:"100px", marginBottom:"2px"}}>
                 <span>Business Type</span><span style={{color:"red"}}>*</span><br/>
                 <select value={biztype}  onChange={(e)=> {setBiztype(e.target.value); handleBizType(e.target.value)} }>         
                 <option value="">All</option>
                {businessCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>))}
                   </select>  
               </div></MDBCol>

        <MDBCol md="3"> 
        <div className="inputBx1 BusinessTopMargin" >
                 <span>Business Sub Type</span><span style={{color:"red"}}>*</span><br/>
                 <select value={bizsubtype}  name="bizsubtype" onChange={(e)=> setBizsubtype(e.target.value)} >         
                 <option value="">All</option>
                {displayBusinessSubCategory != undefined && displayBusinessSubCategory.length >0 &&(displayBusinessSubCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>)))}
                   </select>  
               </div>
      </MDBCol>
        <MDBCol md="3"></MDBCol>
        
    </MDBRow>
  </MDBContainer>
      <MDBContainer>
    <MDBRow>
        <MDBCol md="3"></MDBCol>
        <MDBCol md="6"> 
        
        <TextField id="standard-basic" label="Search By Name" style={{width :"100%", fontSize:"20px"}} variant="standard" value={searchTerm} onChange={(e)=> setSearchTerm(e.target.value)} />

        </MDBCol>
        <MDBCol md="3"></MDBCol>
        
    </MDBRow>
  </MDBContainer>
  <div className="sideMargin img-grid" style={{marginTop:"2vh"}}>

    {finalDisplayArray.length == 0 &&(<Alert severity="info">No Data to Display</Alert>)}
    {finalDisplayArray.map((doc)=>(
        <Card style={{width:300, height: 550, cursor:"pointer"}}>
          <CardMedia 
          component="img"
          id='myimg'
          height="250"
          onClick={()=> window.location.href=`/businesses/${doc.id}`}
          image= {doc.images[0]}/>
          <CardContent>
            <h1 style={{fontsize:"25px"}}>{doc.bizname}</h1>
            <PersonIcon/>{doc.bizowner} <br/>
            <hr/>

            <EmailIcon/> <span style={{cursor:"pointer"}} onClick={()=>window.location.href=`mailTo:${doc.bizemail}`}>{doc.bizemail}</span>  <br/>
            <PhoneInTalkRoundedIcon/> <span style={{cursor:"pointer"}} onClick={()=> window.location.href=`tel:${doc.bizmobile}`}>{doc.bizmobile}</span><br/>
            <WhatsAppIcon/> <span style={{cursor:"pointer"}} onClick={()=> window.location.href=`https://wa.me/91${doc.bizwhatsapp}`}>{doc.bizwhatsapp}</span><br/>
            <LocationOnRoundedIcon onClick={()=>`https://www.google.com/maps/dir//${doc.bizaddress}`}/> {doc.bizaddress}<br/>
          </CardContent>
        </Card>
      ))}
  </div>
    </div>
     <div style={{flexGrow:1}}></div>
     <Footer/>
     </>)
}
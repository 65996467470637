import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
//import { useHistory } from 'react-router-dom';
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import MultiImageInput from 'react-multiple-image-input';

import {toTitleCase} from "../Common Components/CommonFunctions"
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";
import { Button, Modal } from "react-bootstrap";

function UpdateBusinessModal({ setOpenModal, businessId}) {


    const [urls, setUrls] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const storage = getStorage()
   
    const [showNext, setShowNext] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("Please Enter a valid Email Address")
    
    const [showMobileError, setShowMobileError] = useState(false)
    const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")
    
    const [businessCategory, setBusinessCategory] = useState([])
    const [businessSubCategory, setBusinessSubCategory] = useState([])
    const [dbBizSubtype, setDbBizSubtype] = useState([])
    const [displayBusinessSubCategory, setDisplayBusinessSubCategory] = useState([])
    const [biztype, setBiztype] = useState("")
    const [bizsubtype, setBizsubtype] = useState("")
    const [bizwhatsapp, setBizwhatsapp] = useState("")
    const [bizowner, setBizowner] = useState("")
    const [bizmobile, setBizmobile] = useState("")
    const [bizemail, setBizemail] = useState("")
    const [bizname, setBizname] = useState("")
    const [images, setImages] = useState({})
    const [bizaddress, setBizaddress] = useState("")
    const [time, setTime] = useState("")
  
    const [fileCount, setFileCount] = useState(0)
   
   const {currentUser} = useContext(AuthContext)
   const userID = currentUser.uid
  function getbiztype(){
      const ref = firebase.firestore().collection("BusinessCategories")
  
      ref.onSnapshot((querySnapshot)=>{
          let items=[]
          let items1=[]
          let itemsJson ={}
          querySnapshot.forEach((doc)=>{
             items.push(doc.id)
             itemsJson[doc.id] = Object.keys(doc.data())
             items1.push(itemsJson)
          });
        
          setBusinessCategory(items)
          setBusinessSubCategory(items1)
        })
  }
  
  
      
  useEffect(()=>{
      getbiztype()
     GetBusiness()
     GetFileCount()
      
  },[])
  function GetBusiness(){
   
    const ref = firebase.firestore().collection("users").doc(userID).collection("business").doc(businessId)
    ref.get().then((doc)=>{
      if(doc.exists){
      
      setBizwhatsapp(doc.data().bizwhatsapp)
      setBizowner(doc.data().bizowner)
      setBiztype(doc.data().biztype)
      // setBizsubtype(doc.data().bizsubtype)
      setBizemail(doc.data().bizemail)
      setBizsubtype(doc.data().bizsubtype)
      setBizname(doc.data().bizname)
      setBizmobile(doc.data().bizmobile)
      setBizaddress(doc.data().bizaddress)
     // GetBusinessImages(parseInt(doc.data().images))
     setImages(doc.data().images)
      
    
      }else{
        window.alert("Business doesn't Exist !!")
        // history.push("/my-profession") 
      }
    })
  }
  
    const ValidateEmail = (value) =>{
      if (value !== "undefined") {
  
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
        if (!pattern.test(value)) {
      
          setShowEmailError(true)
          
      
         
      
        } else {
          setShowEmailError(false)
        }
      
      }
    }
      
  
    
  useEffect(()=>{
  
   if(biztype.trim() != "" && bizsubtype.trim()!= "" && bizaddress.trim() !="" && bizname.trim() !="" && bizemail.trim() != "" && bizmobile.trim().length == 10 && bizwhatsapp.trim().length == 10 && Object.values(images).length >0)
     setButtonDisabled(false)
  else{
      setButtonDisabled(true)
  
    }
  },[biztype, bizemail, bizmobile, bizname,bizaddress, bizowner, bizsubtype, bizwhatsapp, images])
   
   
      
    function checkMobile(value){
        if(value.length>10)
          return value.slice(0,10)
        else
          return value
    }
  
   

    const UpdateBusiness=(y,x)=>{
      setLoading(true)
    
      const business = firebase.firestore().collection("users").doc(userID).collection('business');
    
        
        // let x = (new Date()).getTime().toString()
        // let y = "business" + x
        const ref = business.doc(y)
       // setBusinessID(y)
     
     ref.update({
      biztype: biztype,
      bizsubtype: bizsubtype,
      bizowner: bizowner.trim(),
      bizemail : bizemail.trim(),
      bizmobile : bizmobile.trim(),
      bizaddress : bizaddress.trim(),
      bizname: bizname.trim(),
      bizwhatsapp: bizwhatsapp.trim(),
      time: x,
      id:y,
      
  
     }).then(()=>{
    //  UploadImages(y)
    window.alert("Business Data Updated!!")
    setOpenModal(false)
    window.location.reload()
     }).catch(function(error){
       
     })
   
    } 
  
    function UploadImages(){
      setLoading(true)
     
      const z = Object.values(images)
     let temp=[]
      let y =-1
      
      z.forEach(async(image, index)=>{
        y++
        let fileId = fileCount+ y +1
        if(!image.includes("https")){
          // const ref = firebase.storage().ref(`business/${userID}/${businessId}`).child(`images${fileId}.png`)
          // const arr = image.split(",")
       
          // ref.putString(arr[1], 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
          //    snapshot.ref.getDownloadURL().then((url)=>{
          //      let x = y+1
          //     setUrls(prevstate=>[...prevstate,url])
         
          //    })
            
          // })
          const fileRef=ref(storage,`business/${userID}/${businessId}/images${fileId}.png`)
          // const arr = image.split(",")
                   const snapshot= await uploadString(fileRef,image,'data_url' )
                   const photoURL= await getDownloadURL(fileRef)
          
        //  temp.push(photoURL)
          setUrls((prevState) => [...prevState, photoURL]);
        }else{
          temp.push(image)
        }
        })
        setUrls(prevstate=>[...prevstate, ...temp])
      }
      
      function GetFileCount(){
        let y =0
        var listRef =firebase.storage().ref(`business/${userID}/${businessId}`)
        

// Find all the prefixes and items.
        listRef.listAll()
        .then((res) => {
    
            res.items.forEach((itemRef) => {
                y++
              
            });
            setFileCount(y)
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });

      
    }
      
     // window.alert("New Business Added")
      // // history.push("/my-profession")
    
  
  useEffect(()=>{
    const z = Object.values(images)
    let temp={}
    if(z.length > 0 && (z.length == urls.length)){
      urls.forEach((url, index)=>{
        temp[index] = url
    
      })
     
      const businessRef = firebase.firestore().collection("users").doc(userID).collection('business').doc(businessId);
      businessRef.set({images : temp}).then(()=>{
        // window.alert("New Business Added !!")
        UpdateBusiness(businessId, time)
     //   // history.push("/my-profession")
      })
      
    }
   
  },[urls])
   
  
    
     const handleBizType=(e)=>{
      
       let bsub = [...displayBusinessSubCategory]
      
       if(e== ""){
        bsub=[]
       } 
       else{
        
        bsub= businessSubCategory[0][e.toLowerCase()]
  
       }
       setDisplayBusinessSubCategory(bsub)
     }
     Array.prototype.insert = function(index,item){
       this.splice(index,0, item)
     };
   
     useEffect(()=>{
      if(biztype !="")
       handleBizType(biztype)
     },[biztype])


  return (
   <Modal show={true} onHide={setOpenModal}
   backdrop="static"
   keyboard={false}>
    <Modal.Header closeButton> <h1 style={{fontSize:"20px"}}>Edit Business Details </h1> </Modal.Header>
    <Modal.Body>

   
{loading ? <LoaderUi/>:<>

        <div className="inputBx1">
                 <span>Business Type</span><span style={{color:"red"}}>*</span><br/>
                 <select value={biztype}  onChange={(e)=> setBiztype(e.target.value) }>         
                 <option value="">Choose</option>
                {businessCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>))}
                   </select>  
               </div>
               <div className="inputBx1">
                 <span>Business Sub Type</span><span style={{color:"red"}}>*</span><br/>
                 <select value={bizsubtype}  name="bizsubtype" onChange={(e)=> setBizsubtype(e.target.value)} >         
                 <option value="">Choose</option>
                {displayBusinessSubCategory != undefined && displayBusinessSubCategory.length >0 &&(displayBusinessSubCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>)))}
                   </select>  
               </div>
               <div className="inputBx1">
                 <span>Business Name</span><span style={{color:"red"}}>*</span><br/>
                 <input type="text" value={bizname}  onChange={(e)=> setBizname(e.target.value)}/> 
               </div>
               <div className="inputBx1"  >
                 <span>Business Mobile</span><span style={{color:"red"}}>*</span><br/>
                 <input type="text"  value={bizmobile} maxLength={10} name="bizmobile" onChange={(e)=> setBizmobile(e.target.value)}/> 
                 {showMobileError ?<p style={{color:"#4D3A78"}}> {mobileErrorMessage} </p> : ""}
               </div>
               <div className="inputBx1">
                 <span> Business Email</span> <span style={{color:"red"}}>*</span><br/>
                 <input type="text" value={bizemail} name="bizemail"  onChange={(e)=> setBizemail(e.target.value)}/> 
                 {showEmailError ?<p style={{color:"#4D3A78"}}> {emailErrorMessage} </p> : ""}
               </div>

               <div className="inputBx1">
                 <span>Business Owner</span><span style={{color:"red"}}>*</span> <br/>
                 <input type="text" value={bizowner}  name="bizowner" onChange={(e)=> setBizowner(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Business Whatsapp</span> <br/>
                 <input type="text" value={bizwhatsapp} maxLength={10}  name="bizwhatsapp" onChange={(e)=> setBizwhatsapp(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Business Address</span><span style={{color:"red"}}>*</span> <br/>
                 <input type="text" value={bizaddress}  name="bizaddress" onChange={(e)=> setBizaddress(e.target.value)}/> 
               </div>

               <div className="inputBx1">
                 <span>Business Image</span><span style={{color:"red"}}>*</span><span><i style={{fontSize:"12px"}}>(Maximum 5)</i></span><br/>
                 <MultiImageInput
      images={images}
      setImages={setImages}
      theme="light"
      max={5}
      allowCrop={false}
   
    />

               </div>


               </>}

        </Modal.Body>
    
    <Modal.Footer>
      {loading ? <></>:<>   <Button
            onClick={() => {
             setOpenModal(false)
             
            }}
            variant="secondary"
          >
          Cancel
          </Button>
      
     
          <Button
            onClick={() => {
           UploadImages()
            }}
             disabled={buttonDisabled}

             variant={"primary"}
          >
          Add Business
          </Button>
          </>}
 
    </Modal.Footer>
   </Modal>
  
  );
}

export default UpdateBusinessModal;
import React, { useState, useEffect} from "react";
import "./UpdateModal.css";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import Gallery from 'react-grid-gallery';



export default function AllBusinessImageModal({ setOpenModal, imgObj}) {
 

 const [loading, setLoading] = useState(true)
 const [imagesArr, setImagesArr ] = useState([])
 useEffect(()=>{
 let y = Object.values(imgObj)
 console.log(y)
 let temp=[]
 for(let i=0; i<y.length; i++){
     let x ={}
     x['src'] = y[i]
     x['thumbnail'] = y[i]
     x['thumbnailWidth'] = 100
     x['thumbnailHeight'] = 100
     temp.push(x)
 } 
 setImagesArr(temp)
 setLoading(false)
 },[])

   

  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"60vw",overflowY:"scroll", display:"flex", flexDirection:"column"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  </h2> </center>
        </div>
        {loading ? <ModalLoaderUi/> :<>
   <div className="body" style={{flex:"50% !important", display:"flex !important"}}>
    <Gallery images={imagesArr} margin={8} enableImageSelection={false} enableLightbox={false}  />

      </div>
        <div className="footer">

       <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
        </div>
        </>}
      </div>
   
    </div>
  );
}

import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow, MDBDataTable} from "mdbreact";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import { AuthContext } from "../auth/Auth";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { placeholderManImage, toTitleCase } from '../Common Components/CommonFunctions';
import MemberImageDisplayModal from '../Modal/MemberImageDisplayModal';

import EditIcon from '@mui/icons-material/Edit';
import { Edit, EditAttributes, RemoveRedEye, ViewAgenda } from '@mui/icons-material';
import { ExportCSVCommon } from '../Common Components/ExportCSVCommon';
import ExportRegisteredMembersModal from '../Modal/ExportRegisteredMembersModal';
import EditRegisteredMembersModal from '../Modal/EditRegisteredMembersModal';
import ViewRegisteredMemberModal from '../Modal/ViewRegisteredMemberModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Footer from '../Footer/Footer';

function AllMembers() {

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [modalImage, setModalImage] = useState("");
  const [userType, setUserType] = useState("")
  const[searchTerm, setSearchTerm] = useState("");
  const [imageModal, setImageModal] = useState(false)
  const {currentUser} = useContext(AuthContext);
  const uid = currentUser.uid
  const [modalOpen, setModalOpen] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [editModalOpen , setEditModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [editUserDetails, setEditUserDetails] = useState({})
  const [viewUserDetails, setViewUserDetails] = useState(false)
  const [allRegisteredMembers, setAllRegisteredMembers] = useState([])
  const [activeRegisteredMembers, setActiveRegisteredMembers] = useState([])
  const [inactiveRegisteredMembers, setInActiveRegisteredMembers] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [selectedStatus, setSelectedSatus] = useState("all")
  function CheckBusiness (){
      /*firebase.firestore().collection("users").doc(uid).collection("business").limit(1).get().then((query)=>{
    if(query.size==0)
      setModalOpen(true)
    });*/
    	
  }

  useEffect(()=>{
   CheckBusiness()
  },[])

  const ref1 = firebase.firestore().collection('users').doc(uid)
  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
          
          setUserType(doc.data().type)
        
               } else {
          // doc.data() will be undefined in this case
        }
      })
      .catch((error) => {});
     
    
  }, []);
  


let  ref = firebase.firestore().collection("users");





useEffect(()=>{
   setloading(true);
   const items=[];
   const all =[];
   const active =[];
   const inactive =[];
   firebase.firestore().collection("users").onSnapshot((querySnapshot)=>{
    
     querySnapshot.forEach((doc,key)=>{
            let x={}
      
           x["Membership Id"] = doc.data().mid
           x["Membership Status"] = toTitleCase(doc.data().memstatus)
           x["Membership Type"] = doc.data().memtype
           x["Name"] =  doc.data().salutation  +" "+  toTitleCase(doc.data().name)
           x["Aliasname"] = doc.data().aliasname
           x["Father's Name / Husband Name"] = toTitleCase(doc.data().fathername)
           x["Whatsapp"] = doc.data().whatsapp
           x["Mobile"] = doc.data().mobile
           x["Email"] = doc.data().email
           x["Address"] = doc.data().address
           x["Blood Group"] = doc.data().bloodgroup
           x["Eligible for Donation"] = toTitleCase(doc.data().eligible)
           x["D.O.B"] = doc.data().dob
           x["PAN"] = doc.data().pan
           x["Aadhar"] = doc.data().aadhar
           x["D.O.A"] = doc.data().doa
           x['Spouse Name'] = doc.data().spouse
           x["Spouse Mobile"] =doc.data().spousemobile
           x["Spouse DOB"] = doc.data().spousedobirth
            all.push(x)
            if(doc.data().memstatus == "active"){
              active.push(x)
            }
            else{
              inactive.push(x)
            }
            items.push(doc.data());
     });
     setschools(items);
     setAllRegisteredMembers(all)
     setActiveRegisteredMembers(active)
     setInActiveRegisteredMembers(inactive)
     setloading(false);
     


     
   })
  },[])
 

  useEffect(()=>{
    let userData = [];
 
  if(selectedStatus == 'all'){
    let postsArray = JSON.parse(JSON.stringify(allRegisteredMembers));
    postsArray.map((item, index) => {   
      item.serial=(<div>{index+1}</div>)
      item.action = (
          <>
    
    <EditIcon style={{color:"green", cursor:"pointer", margin:"2px"}} onClick={()=> EditButton(item)}/>
    <VisibilityIcon style={{color:"red", cursor:"pointer", margin:"2px"}} onClick={()=> ViewButton(item)}/>
    
</>
      );
      userData.push(item);
    });
    setAllMembers(userData);
  }else
  if(selectedStatus == 'active'){
    let postsArray = JSON.parse(JSON.stringify(activeRegisteredMembers));
    postsArray.map((item, index) => {   
      item.serial=(<div>{index+1}</div>)
      item.action = (
          <>
    
    <EditIcon style={{color:"green", cursor:"pointer", margin:"2px"}} onClick={()=> EditButton(item)}/>
    <VisibilityIcon style={{color:"red", cursor:"pointer", margin:"2px"}} onClick={()=> ViewButton(item)}/>
    
</>
      );
      userData.push(item);
    });
    setAllMembers(userData);
  }
  else  if(selectedStatus == 'inactive'){
    let postsArray = JSON.parse(JSON.stringify(inactiveRegisteredMembers));
    postsArray.map((item, index) => {   
      item.serial=(<div>{index+1}</div>)
      item.action = (
          <>
    
    <EditIcon style={{color:"green", cursor:"pointer", margin:"2px"}} onClick={()=> EditButton(item)}/>
    <VisibilityIcon style={{color:"red", cursor:"pointer", margin:"2px"}} onClick={()=> ViewButton(item)}/>
    
</>
      );
      userData.push(item);
    });

  }
  
  setAllMembers(userData);
  }, [allRegisteredMembers, activeRegisteredMembers, inactiveRegisteredMembers, selectedStatus]);


    const data = {
        columns: [
          {
            label: 'Sl No.',
            field: 'serial',
            sort: 'asc',
            width: 50,
          },
           
            {
                label: 'Membership ID',
                field: 'Membership Id',
                sort: 'asc',
                width: 150,
                
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 150,
              },
              {
                label: 'Membership Status',
                field: 'Membership Status',
                sort: 'asc',
                width: 150
              },
           {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: allMembers

      };
    

 function EditButton(userObject){
    setEditUserDetails(userObject)
    setEditModalOpen(true)
 }
 function ViewButton(userObject){
  for(let i=0; i< schools.length; i++){
    if(schools[i]['mid'] == userObject['Membership Id']){
      setViewUserDetails(schools[i])
      setViewModalOpen(true)
    }
  }

 }
 function OpenImageModal(imageSrc){
   setModalImage(imageSrc)
   setImageModal(true)
 }
 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
      <IdleTimeContainer/>
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
      {imageModal && (<MemberImageDisplayModal imageSrc={modalImage} setOpenModal={setImageModal}/>)}
      {exportModalOpen &&(<ExportRegisteredMembersModal setOpenModal={setExportModalOpen} activeRegisteredMembers={activeRegisteredMembers} allRegisteredMembers={allRegisteredMembers} inactiveRegisteredMembers={inactiveRegisteredMembers}/>)}
      {editModalOpen && (<EditRegisteredMembersModal setOpenModal={setEditModalOpen} userDetails={editUserDetails}/>)}
      {viewModalOpen && (<ViewRegisteredMemberModal setOpenModal={setViewModalOpen} userDetails={viewUserDetails}/>)}
      
      <div className="divmargin flex-wrapper">
  
           <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer  
    >
      <MDBRow>  <MDBCol md="1" >
      </MDBCol>
      
      <MDBCol md="6"> 
      <select value={selectedStatus} onChange={(e)=> setSelectedSatus(e.target.value)}>
          <option  value="all">All Members</option>
          <option value="inactive">Inactive Members</option>
          <option value="active">Active Members</option>
        </select>
      </MDBCol>
      <MDBCol md='4'></MDBCol> 
      <MDBCol md='1'><button className='buttons' onClick={()=> setExportModalOpen(true)}>Export</button></MDBCol>
      
      </MDBRow>
     
    </MDBContainer>
  
  
  
    

          <MDBDataTable       striped
      bordered
      small
      scrollX  data={data} />
 
 </div>   
 <div style={{flexGrow:1}}></div>
 <Footer/>
    </Fragment>   
  );
}

export default AllMembers;

import React, { Fragment, useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "@mui/material";
import axios from "axios";
import "../Registration.css"
import RegistrationPart4 from "./RegistrationPart4";
import Carousel from "react-material-ui-carousel";
//import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
export default function RegistrationPart3(props) {
 
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState(Date.parse(props.doa))
  const [address, setAddress] = useState(props.address)
  const [spouse, setSpouse] = useState(props.spouse);
  const [confirmPassword, setConfirmPassword] = useState("")
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")

  const[showConfirmPassword, setShowConfirmPassword] = useState(false)
 
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isMarried, setIsMarried] = useState(props.married)
  const [marriedDisplay, setMarriedDisplay] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [eligible, setEligible] = useState(props.eligible)
  const [spouseEmail,setSpouseEmail] = useState("")
  const [spouseMobile, setSpouseMobile] = useState(props.spousemobile)

  const [showNextPart, setShowNextPart] = useState(false)
  const [spouseDob, setSpouseDob] = useState(Date.parse(props.spousedob))
 
  

  const [aliasName, setAliasName] = useState(props.aliasname)
  const [pan, setPan] = useState(props.pan)
  const [aadhar, setAadhar] = useState(props.aadhar)

  function nextPart(){
      setShowNextPart(true)
  }




 
  useEffect(()=>{
    if(isMarried)
      setMarriedDisplay(true)
    else{
      setMarriedDisplay(false)
      setSpouse("")
      setSpouseEmail("")
      setDoa()
      setSpouseMobile("")

    }
  },[isMarried])

  useEffect(()=>{
      if(isMarried){
        if(spouse.trim() != "" && doa !="" && spouseDob !="" && spouseMobile.trim().length ==10)
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
      } else{
          setButtonDisabled(false)
      }

  },[isMarried, doa, spouse, spouseDob,spouseMobile])
 
 function toTitleCase(str) {
  if(str!="" && typeof(str)=="string"){
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );} else return str
}

  function CheckEligible(value){
    if(value)
      return "eligible"
    else
      return "ineligible"
    
  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }
  useEffect(()=>{
    if(spouseMobile.length != 10 && spouseMobile.length != 0){
      setShowMobileError(true)
    }
    else
      setShowMobileError(false)
  },[spouseMobile])
  

  return (
    
    <Fragment>
      {/* <LoginNavbar/>
      <LoginPageNavbar/> */}
    {!showNextPart ? 

            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>       
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               <div className="inputBx">
                   <label style={{ fontSize: "20px"}} > Are You Married ?
     </label>  <label style={{marginLeft:"15px", fontSize: "20px", color:"#4D3A78"}} > No
       </label>    <Switch
      checked={isMarried}
      onClick={()=> setIsMarried(!isMarried)}
      color="primary"
      style={{ fontSize: "20px"}}
    />
    <label style={{ fontSize: "20px", color:"#4D3A78"}} > yes
      </label>
    <br/>
               </div>
               {marriedDisplay ?<> 
              
               <div className="inputBx">
                 <span>Date of Anniversary</span><br/>
                 <DatePicker selected={doa} onChange={(date) => setDoa(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown dateFormat={'dd/MM/yyyy'}/>
               </div>
               <div className="inputBx">
                 <span>Spouse's Name</span>
                 <input type="text" value={spouse} autoComplete="off" onChange={(e)=> setSpouse(e.target.value)}/> 
               </div>
               <div className="inputBx">
                 <span>Spouse's Mobile</span>
                 <input type="text" value={spouseMobile} autoComplete="off" onChange={(e)=> setSpouseMobile(e.target.value)}/> 
               </div>
               <div className="inputBx">
                 <span>Spouse's DOB</span><br/>
                 <DatePicker selected={spouseDob} onChange={(date) => setSpouseDob(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown dateFormat={'dd/MM/yyyy'}/>
               </div>
            

              

               </>:""}
               <div className="inputBx">             
                 <input type="button" value="Next"  onClick={nextPart} disabled={buttonDisabled} /> 
               </div>

            
           </div>
         </div>
       </section>
         :<RegistrationPart4  name={name} salutation={props.salutation} gender={gender}  memstatus={props.memstatus} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} mobile={mobile} membershipId={membershipId} eligible={eligible} whatsapp={whatsapp}  address={address} dob={dob} doa={dateFormat(doa,"dd/mm/yyyy")} spousedob={dateFormat(spouseDob,"dd/mm/yyyy")} spouse={spouse} married={isMarried} pan={pan} aadhar={aadhar} aliasname={aliasName} spousemobile={spouseMobile} images={props.images}/>}

         
    </Fragment>
  );
}


import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import { IconButton } from '@mui/material';
import { Navbar } from "../Navbar/Navbar";
import "../MemberDirectory.css";
import "../Modal.css";
import { AuthContext } from "../auth/Auth";
import SideMenuBar from "../Navbar/SideMenuBar"
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { Add, AddAPhoto, DeleteForever, Edit, EditAttributesOutlined, EditLocationOutlined, EditOutlined } from '@mui/icons-material';
//import { useHistory } from 'react-router-dom';
import UpdateProductModal from "../Modal/UpdateProductModal"
import EditProductsModal from '../Modal/EditProductsModal';

function MyProducts() {

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [editProductsModalOpen, setEditProductsModalOpen] = useState(false)
  const [bizName, setBizname] = useState("")
  const [productid, setProductid] = useState("")

  let downloadUrl = []
  let url ={}
  const [downloadUrlArray, setDownloadUrlArray] = useState([])
  let x=""
 // const history = useHistory()
  const {currentUser} = useContext(AuthContext);
  const uid = currentUser.uid

  const ref1 = firebase.firestore().collection('users').doc(uid)
 
  



const urlPath = window.location.pathname;
const urlArr = urlPath.split("/")
const businessID = urlArr[urlArr.length -2]

let  ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID).collection("products");

function ValidBusiness(){
  const ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID)
  ref.get().then((doc)=>{

      if(!doc.exists){
          window.alert("Business Does not Exists !!")
          // history.push("/my-profession")
      }
      else{
            setBizname(doc.data().bizname)
      }
  })
}

useEffect(()=>{
  ValidBusiness()
},[businessID])
 
 function getDownloadURLArray(mid,url){
   let temp =[]
   const storageref = firebase.storage().ref().child(url)
   storageref.getDownloadURL().then(onResolve, onReject)

   function onResolve(foundUrl){
    let x ={}
  
     x[mid] = foundUrl
  
     downloadUrl.push(x)   
     setDownloadUrlArray(downloadUrl)
    

   }

   function onReject(err){
    let x ={}
     x[mid] =""

   }

 }


 function getSchools(){

   setloading(true);
   const items=[];
   ref.onSnapshot((querySnapshot)=>{
   
     querySnapshot.forEach((doc)=>{
          
           items.push(doc.data());
     });
     setschools(items);
     setloading(false);
    
   })
 }  
 
 function getDownloadUrlJson(){
   let tempKeys =[]
   let tempValues= []
   
  for (let i=0; i<downloadUrlArray.length; i++){
    
    for(let value of Object.values(downloadUrlArray[i])){
      tempValues.push(value)
    }

    for(let value of Object.keys(downloadUrlArray[i])){
      tempKeys.push(value)
   }

   


  }
  for (let i=0; i<tempKeys.length; i++){
    url[tempKeys[i]] = tempValues[i]
  }
 
  x=JSON.stringify(url)
 
 }
 useEffect(()=>{
   getSchools();
 },[]);

 function AddNewProducts(){
     if(schools.length >= 10){
         window.alert("Only 10 Products are allowed")
     }
     else{
         setProductModalOpen(true)
     }
 }
 
 function EditProducts(id){
   setProductid(id)
   setEditProductsModalOpen(true)
 }
 function DeleteProducts(id){
     let x = window.confirm("Are You Sure ? \n This action can't be undone!!")
     if(x){
       setloading(true)
     const ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID).collection("products").doc(id)
     const storageRef =  firebase.storage().ref(`business/${uid}/${id}/products/${id}`)
     
     storageRef.listAll().then((listResults) => {
       const promises = listResults.items.map((item) => {
         return item.delete();
       });
       Promise.all(promises);
     });
   
     ref.delete().then(()=>{
       window.alert("Product Deleted !!")
       window.location.reload()
   })
    
    }
 }

 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
      <IdleTimeContainer/>
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
      {productModalOpen && (<UpdateProductModal setOpenModal={setProductModalOpen} businessId={businessID} businessName={bizName} userID={uid}/>)}
      {editProductsModalOpen && (<EditProductsModal setOpenModal={setEditProductsModalOpen} businessId={businessID} businessName={bizName} userID={uid} productid={productid}/>)}
      <div className="divmargin">
  
           <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer  
    >
      <MDBRow>  <MDBCol md="8" ><h1 style={{color:"#4D3A78"}}>{bizName}</h1>
      </MDBCol>
      
      <MDBCol md="1"></MDBCol>
      
      <MDBCol md='2'><button className	="btn btn-primary nav-links" style={{background:"#4D3A78", color:"white",fontSize:"20px"}}  onClick={AddNewProducts}>Add New Products</button></MDBCol>
      <MDBCol md='1'></MDBCol> 
      
      </MDBRow>
     
    </MDBContainer>
  
  
  
    {schools.length == 0 ?<MDBContainer  
    >
      <MDBRow>  <MDBCol md="2" >
      </MDBCol>
      
      <MDBCol md="8" ><h1>No Products to Display !!</h1><h1> Please Add to View</h1></MDBCol>
      
      <MDBCol md='2'></MDBCol>
      
      
      </MDBRow>
     
    </MDBContainer>: <>

      {schools.map((school,key)=>(       
 
        <div  key={school.mid} >     
    
        <div  style={{padding:"10px",margin:"50px",cursor:"pointer", boxShadow:" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} className="d-flex bd-highlight example-parent justify-content-around">
        <div className="p-2  bd-highlight col-example" ></div>
   
        <div className="p-2 flex-fill flex-grow-1 bd-highlight col-example" style={{textAlign:"center", border:"2px solid #6256E0"}} onClick={()=> window.location.href=`/member-portal/${school.mid}`}>
 
         <p style={{color:"black", fontSize:"30px" }} > {school.productname}</p>
         
         <p style={{color:"#4D3A78", fontSize:"30px" }} > Price:-  {school.productprice} </p>
 
        
          </div>
          
          <div className="p-2 flex-shrink bd-highlight col-example" >
            
           <IconButton className="IconButton" onClick={()=> DeleteProducts(school.id)}  ><DeleteForever style={{fontSize:80}}  /> </IconButton> 
           <IconButton className="IconButton" onClick={()=> EditProducts(school.id)}  > <EditOutlined style={{fontSize:80}}  /> </IconButton>
           {/* <IconButton className="IconButton" href={"tel:"+  school.phone}   > <Add style={{fontSize:80}}  /> </IconButton> */}
            </div>
        </div></div>
        
      
      
            
      ))}
 </> }
 </div>   
    </Fragment>   
  );
}

export default MyProducts;

import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "../Common Components/firebase";
import { Navbar } from "../Navbar/Navbar.js";
import { AuthContext } from "../auth/Auth";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";

import Switch from '@mui/material/Switch';
import axios from "axios";
import Sidebar from "../Navbar/SideMenuBar";
import LoaderUi from "../Common Components/LoaderUi";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import "../UpdateProfile.css";
import { toTitleCase } from "../Common Components/CommonFunctions";
import { UpdateSms } from "../Common Components/SmsCommunication";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from "react-router-dom";
import Timer from "../Common Components/Timer";
import { Alert } from "@mui/material";
import Footer from "../Footer/Footer";

export default function UpdateProfile() {
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [bloodGroup, setBloodGroup] = useState("")
  const [gender, setGender] = useState("")
  const [dob, setDob] = useState()
  const [doa, setDoa] = useState()
  const [address, setAddress] = useState("")
  const [spouse, setSpouse] = useState("")
  const [whatsapp, setWhatsapp] = useState("");
  const [spousemob, setSpousemob] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [pan, setPan] = useState("")
  const [aadhar,setAadhar] = useState("")
  const [memNo, setMemNo] = useState("");
  const [mobile, setMobile] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [isMarried, setIsMarried] = useState(false)
  const [marriedDisplay, setMarriedDisplay] = useState("")
  const [eligible, setEligible] = useState(false)
  const [mid, setMid] = useState("")
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState("1")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Passwords doesn't Match !!")
  const [passwordButtonDisabled, setPasswordButtonDisabled] = useState(true)
  const [salutation, setSalutation] = useState("")
  const [aliasname, setAliasname] = useState("")
  const [emailButtonDisabled, setEmailButtonDisabled] = useState(true)
  const [newEmail, setNewEmail] = useState("")
  const [password, setPassword] = useState("")
  const [spouseDobirth, setSpouseDobirth] = useState("")
  const [newWhatsapp, setNewWhatsapp] = useState("")
  const [otpDisabled, setOtpDisabled] = useState(true)
  const [showOtp, setShowOtp] = useState(false)
  const [otpRecieved, setOtpRecieved] = useState("")
  const [mobileOtpRecieved, setMobileOtpRecieved] = useState("")
  const [newWhatsappDisabled, setNewWhatsappDisabled] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState("")
  const[errorMessage, setErrorMessage] = useState("")
  const [otpToBeChecked, setOtpToBeChecked] = useState("")
  const [newMobileDisabled, setNewMobileDisabled] = useState(false)
  const [newMobile,setNewMobile] = useState("")
  const [mobileOtpDisabled, setMobileOtpDisabled] = useState(true)
  const [showMobileOtp, setShowMobileOtp] = useState(false)
  const [mobileOtpToBeChecked, setMobileOtpToBeChecked] = useState("")
  const navigate = useNavigate()
  
const {currentUser} = useContext(AuthContext)
const userID = currentUser.uid

useEffect(()=>{
  if(newWhatsapp.trim().length == 10)
    setOtpDisabled(false)
  else
    setOtpDisabled(true)
},[newWhatsapp])

useEffect(()=>{
  if(newMobile.trim().length == 10)
    setMobileOtpDisabled(false)
  else
    setMobileOtpDisabled(true)
},[newMobile])

useEffect(()=>{
  if(newEmail.trim() !="" && password.trim()!=""){
    setEmailButtonDisabled(false)
  }
  else{
    setEmailButtonDisabled(true)
  }
},[newEmail, password])
function ChangeEmail(){
  setLoading(true)
const emailCred = firebase.auth.EmailAuthProvider.credential(
    firebase.auth().currentUser.email,
    password
  );

  firebase
  .auth()
  .currentUser.reauthenticateWithCredential(emailCred)
  .then(() => {
    const db = firebase.firestore();
    const users = db.collection("users").doc(userID);
 
 
  firebase.auth().currentUser.updateEmail(newEmail).then(()=>{
    users.get().then((doc) => {
      if (doc.exists) {
        users
          .update({
            email: newEmail,
          })
          .then(function () {

            //   window.alert("Value successfully written!");
          })
          .catch(function (error) {
            window.alert(error.message);
            setLoading(false)
          });
      }
    });

    // UpdateSms("ss", "11",12221 )
    // SendUpdateEmail()
   setLoading(false)
    window.alert("Your Email has been updated Successfully ! Kindly use new Email for next login" );
    navigate("/")

  }).catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    window.alert(errorMessage);
    setLoading(false)
  });

  
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    window.alert(errorMessage);
    setLoading(false)
  
  });
}

const ref = firebase.firestore().collection('users').doc(userID)
  function getData(){

    ref
    .get()
    .then((doc) => {

      if (doc.exists) {
        setAddress(doc.data().address)
        setGender(doc.data().gender)
        setFatherName(doc.data().fathersname)

        setMobile(doc.data().phone)
        setName(doc.data().name)
        setWhatsapp(doc.data().whatsapp)
        setEmail(doc.data().email)
        setMid(doc.data().mid)
        doc.data().bloodgroup != undefined ? setBloodGroup(doc.data().bloodgroup) : setBloodGroup("")
        doc.data().married != undefined  && doc.data().married ? setIsMarried(true) : setIsMarried(false)
        doc.data().eligible != undefined && doc.data().eligible.toLowerCase() == "yes" ? setEligible(true) : setEligible(false)
        doc.data().spouse != undefined ? setSpouse(doc.data().spouse) : setSpouse("")
        doc.data().spousemobile != undefined ? setSpousemob(doc.data().spousemobile) : setSpousemob("")
        doc.data().doanniversary != undefined && doc.data().doanniversary !="" ? setDoa(Date.parse(DateFormatter(doc.data().doanniversary))): setDoa("")
        doc.data().pan !=undefined ? setPan(doc.data().pan) : setPan("")
        doc.data().aadhar != undefined ? setAadhar(doc.data().aadhar) : setAadhar("")
        doc.data().spousedobirth != undefined && doc.data().spousedobirth !="" ? setSpouseDobirth(Date.parse(DateFormatter(doc.data().spousedobirth))) : setSpouseDobirth("")
        doc.data().dobirth != undefined && doc.data().dobirth !=""? setDob(Date.parse(DateFormatter(doc.data().dobirth))) : setDob("")
        doc.data().salutation != undefined ? setSalutation(doc.data().salutation) : setSalutation("")
        doc.data().aliasname != undefined ? setAliasname(doc.data().aliasname) : setAliasname("")

     
      setLoading(false)


      
      
             } else {
        // doc.data() will be undefined in this case
      }
    })
    .catch((error) => {});
   
  }

  useEffect(()=>{
    getData()
  },[])

  function CheckEligibleReverse(str){
    if(str==='eligible')
      return true
    else
      return false

  }

 
  useEffect(()=>{
    if(isMarried)
      setMarriedDisplay('block')
    else
      setMarriedDisplay('none')
  },[isMarried])

 useEffect(()=>{


  if(isMarried==false){
  if(  fatherName.trim()  !=""  && bloodGroup !="" && dob !="" && address.trim() != ""   && gender!="" && pan.trim() !=""  && aadhar.trim().length == 12 && salutation !="" && aliasname.trim() !="" )
    setButtonDisabled(false)
    else 
      setButtonDisabled(true)
   } 


   if(isMarried){
    if( spouse.trim() !="" && fatherName.trim()  !="" && doa != "" && spousemob.trim().length ==10 && spouseDobirth !=""   && bloodGroup !="" && dob !="" && address.trim() != ""   && gender!=""  &&  pan.trim() !=""  && aadhar.trim().length == 12 && salutation !="" && aliasname.trim() !="" )
     setButtonDisabled(false)
    else
      setButtonDisabled(true)
    }
   
 },[ isMarried, spouse, doa, address, bloodGroup, dob, spousemob, whatsapp, gender, fatherName, pan, aadhar, salutation, aliasname])
 



  function CheckEligible(value){
    if(value)
      return "yes"
    else
      return "no"
    
  }
  function DateFormatter(date){
      
    var d = date.split('/')
    var d1 = d[0]
    var d2 = d[1]
    var d3 = d[2]

    if(d2.length==1)
      d2="0"+d2
    
    var finalDate = d2+"/"+d1+"/"+d3
   return finalDate

  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }

  const UpdateDetails =() =>{
  setLoading(true)
    const db = firebase.firestore();
    const users = db.collection("users").doc(currentUser.uid);

        users
          .update({
            address :address.toLowerCase(),
            bloodgroup:bloodGroup,
            dobirth:dateFormat(dob,"dd/mm/yyyy"),
            doanniversary:dateFormat(doa,"dd/mm/yyyy"),
            gender:gender,
            spousemobile:spousemob,
            spousedobirth : dateFormat(spouseDobirth,"dd/mm/yyyy"),
            phone:mobile,
            spouse:spouse,
            eligible:CheckEligible(eligible),
            married: isMarried,
            fathersname: fatherName,
            pan :pan,
            aadhar: aadhar,
            aliasname: aliasname,
            salutation :salutation,

          })
          .then(function () {

          //  UpdateSms(name, mobile)
          //   SendUpdateWhatsapp(whatsapp)
          //   UpdateEmail(email, name,mid)
            window.alert("Profile Successfully Updated!");
            navigate("/")
          })
          .catch(function (error) {
            window.alert(error);
          });     
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
 
  };
  useEffect(()=>{
    if(newPassword.trim()!= "" && confirmNewPassword.trim() !=""){
        if(newPassword == confirmNewPassword && currentPassword !=""){
            setPasswordButtonDisabled(false)
            setShowPasswordErrorMessage(false)
        }else{
            setPasswordButtonDisabled(true)
            setShowPasswordErrorMessage(true)
        }
    }else{
        setPasswordButtonDisabled(true)
       
    }
  },[newPassword, confirmNewPassword, currentPassword])

  function ChangePassword(){
    setLoading(true)
    const emailCred  = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email, currentPassword);
        firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
      .then(() => {
        // User successfully reauthenticated.

        const db = firebase.firestore();
        
      
       firebase.auth().currentUser.updatePassword(newPassword).then(()=>{
        db.collection("users")
        .doc(userID)
        .update({
          password: newPassword,
        })
        .then(function () {

        })
        .catch(function (error) {
          window.alert( error.message);
        });
       UpdateSms(name, mobile)
       
        window.alert("Your Password has been updated Successfully !")
        navigate("/")
        
     
       }).catch((error) => {
        var errorMessage = error.message;
        window.alert(errorMessage)
      });




      }).catch((error) => {
        var errorMessage = error.message;
        window.alert(errorMessage)
      });
      
}

  useEffect(()=>{
    if(!isMarried){
      setSpouse("")
      setSpouseDobirth("")
      setDoa("")
      setSpousemob("")
    }
  },[isMarried])
 
  function otpGenerator(){
    setShowOtp(true)
    setNewWhatsappDisabled(true)
    setShowErrorMessage(false)
    var max = 99999;
    var min = 10000
    const otp = Math.floor(Math.random() * (max - min) + min)

    // Whatsapp Send OT{}
    setOtpToBeChecked(otp)
    
  
  }

  function MobileOtpGenerator(){
    setShowMobileOtp(true)
    setNewMobileDisabled(true)
    setShowErrorMessage(false)
    var max = 99999;
    var min = 10000
    const otp = Math.floor(Math.random() * (max - min) + min)
    console.log(otp)
    // Whatsapp Send OT{}
    setMobileOtpToBeChecked(otp)
    
  
  }
  function CheckOtp(){
    if(otpToBeChecked == otpRecieved )
    {
      UpdateWhatsapp()
    }else{
      setShowErrorMessage(true)
      setErrorMessage("Wrong OTP Entered")
    }
  }

  function CheckMobileOtp(){
    if(mobileOtpToBeChecked == mobileOtpRecieved )
    {
      UpdateMobile()
    }else{
      setShowErrorMessage(true)
      setErrorMessage("Wrong OTP Entered")
    }
  }
  function UpdateWhatsapp(){
    setLoading(true)
    firebase.firestore().collection("users").doc(userID).update({
      whatsapp : newWhatsapp
    }).then(()=>{
      window.alert("Whatsapp No. updated")
      navigate("/")
    })
  }

  function UpdateMobile(){
    setLoading(true)
    firebase.firestore().collection("users").doc(userID).update({
      phone : newMobile
    }).then(()=>{
      window.alert("Mobile No. updated")
      navigate("/")
    })
  }

  function Reset(){
    setOtpDisabled(true)
    setShowOtp(false)
    setOtpToBeChecked("")
    setOtpRecieved("")
    setNewWhatsappDisabled(false)
  }

  function MobileReset(){
    setMobileOtpDisabled(true)
    setShowMobileOtp(false)
    setMobileOtpToBeChecked("")
    setMobileOtpRecieved("")
    setNewMobileDisabled(false)
  }


  if(loading)
    return (<LoaderUi/>)

  return (
    <Fragment>
   <div className="flex-wrapper">
   <IdleTimeContainer />
      {/* <LoginNavbar */}
    <Navbar/>
      <Sidebar />
      <div style={{ paddingTop: "10vh" }}></div>

    <TabContext value={value} >
      <div className="sideMargin">
    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons>
            <Tab label="Update Profile" value="1" />
            <Tab label="Update Email" value="2" />
            <Tab label="Update Password" value="3" />
            <Tab label="Update Whatsapp" value="4" />
            <Tab label="Update Mobile" value="5" />
           
          </TabList>
          </div>



      
  

    <TabPanel value="1">
    <div className="sideMargin">
    <Alert severity="info">For Changing Name Please Contact the Administartor </Alert>

    <div className="inputBx1">
                 <span>Salutation</span><br/>
                 <select value={salutation} style={{width:"40%"}} onChange={(e)=> setSalutation(e.target.value)} >
                  <option value={""}>Choose</option>
                  <option value={"Mr."} >Mr. </option>
                  <option value={"Mrs."} >Mrs. </option>
                  <option value={"Ms."} >Ms. </option>
                  <option value={"Dr."} >Dr. </option>
                  <option value={"CA"} >CA </option>
                  

                  
                </select> 
                
               </div>
    <div className="inputBx1">
         <span> Alias Name </span>
         <input
           type="text"
           className="form-control"
           placeholder="AliasName"
          
            value={aliasname}
            onChange={(e) => setAliasname(e.target.value)}
         />
      </div>
 
      <div className="inputBx1">
         <span>  Father's Name </span>
         <input
           type="text"
           className="form-control"
           placeholder="Father's Name"
          
            value={toTitleCase(fatherName)}
            onChange={(e) => setFatherName(e.target.value)}
         />
      </div>
 
   
      <div className="inputBx1">
         <span> Blood Group  </span><br></br>
         <select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} style={{width:"100px"}}>
           <option value="">NA</option>
           <option value="A+">A+</option>    
           <option value="A-">A-</option>   
           <option value="B+">B+</option>   
           <option value="B-">B-</option>                 
           <option value="A+">O+</option>    
           <option value="O-">O-</option>    
           <option value="AB+">AB+</option>    
         </select> 
        
          </div>
          <div className="inputBx1"
         ><span>
           Are You Eligible for blood donation ?</span>
           <label style={{marginLeft:"15px", color:"#4D3A78"}} > No
         </label>
         <Switch
         checked={eligible}
         onClick={()=> setEligible(!eligible)}
         color="primary"
      
       />
       <label style={{ color:"#4D3A78"}} > Yes
         </label>
          </div>

       <div className="inputBx1">
         <span>  Gender </span><br/>
         <select value={gender} onChange={(e)=> setGender(e.target.value)} style={{width:"170px"}}>
           <option value="">Choose</option>
           <option value="M">Male</option>    
           <option value="F">Female</option>           
         </select> 

         <br />
   
          </div>
          <div className="inputBx1">
         <span>  Date of Birth </span>
      
         <DatePicker dateFormat={"dd/MM/yyyy"} selected={dob} onChange={(date) => setDob(date)} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown />
        </div>
         <div className="inputrBx1">
         <span> Are You Married ?
         </span>  <span style={{marginLeft:"15px", fontSize: "20px", color:"#4D3A78"}} > No
         </span>
         <Switch
         checked={isMarried}
         onClick={()=> setIsMarried(!isMarried)}
         color="primary"
         style={{ fontSize: "20px"}}
       />
       <span style={{ fontSize: "20px", color:"#4D3A78"}} > yes
         </span>
         </div>
       <br/>
       <span  style={{display:marriedDisplay}}> 
          <div className="inputBx1">
            <span>Date of Anniversary</span>
     
            <DatePicker selected={doa} onChange={(date) => setDoa(date)} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown  />
         <br />
         </div> 

         <div className="inputBx1">
            <span> Spouse Name</span> 
         <input
           type="text"
           className="form-control"
           placeholder="Spouse Name"
          
           value={toTitleCase(spouse)}

           onChange={(e) => setSpouse(e.target.value)}
         />
          </div>
    <div className="inputBx1"
         ><span> Spouse Mobile</span> 
         <input
           type="number"
           className="form-control"
           placeholder="Spouse Mobile"
         
           maxLength={10}
           value={spousemob}

           onChange={(e) => setSpousemob(e.target.value)}
         />
        </div>
        <div className="inputBx1">
         <span> Spouse's Date of Birth</span>
      
         <DatePicker dateFormat={"dd/MM/yyyy"} selected={spouseDobirth} onChange={(date) => setSpouseDobirth(date)} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown />
        </div>
       </span>
     
       <div className="inputBx1">
         <span> Address </span>
         <input
           type="text"
           className="form-control"
           placeholder="Address"
          
           value={toTitleCase(address)}
           onChange={(e) => setAddress(e.target.value)}
         />
         </div>
         <div className="inputBx1">
         <span> Pan </span>
         <input
           type="text"
           className="form-control"
           placeholder="Pan"
          
           value={pan}
           onChange={(e) => setPan(e.target.value)}
         />
         </div><div className="inputBx1">
         <span> Aadhar </span>
         <input
           type="text"
           className="form-control"
           placeholder="Aadhar No."
          
           value={aadhar}
           maxLength={12}
           onChange={(e) => setAadhar(e.target.value)}
         />
         </div>
         <br />
         <br />

         <button
           disabled={buttonDisabled}
           onClick={()=>UpdateDetails()}
           className="buttons"
          
         >
           Update Profile
         </button>
    
       </div>
    </TabPanel>
    <TabPanel value="2">
    <div className="sideMargin">
        <div className="inputBx1">
                 <span>Current Email </span>
                 <input type="text"autoComplete="chrome-off" value={email} disabled={true}/> 
               </div>
               <div className="inputBx1">
                 <span>New Email </span>
                 <input type="text"autoComplete="chrome-off" value={newEmail} onChange={(e)=> setNewEmail(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Current Password </span>
                 <input type={showPassword?"text" : "password"}autoComplete="chrome-off" value={password} onChange={(e)=> setPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowPassword(!showPassword)}  /> Show Password</label>
                 {/* {showPasswordErrorMessage ?<p style={{color:"#4D3A78"}}> {passwordErrorMessage} </p> : ""} */}
               </div>
               <button
            onClick={() => ChangeEmail()}
            className="buttons"
            disabled={emailButtonDisabled}
          >
          Update Email
          </button>
               </div>
    </TabPanel>
    <TabPanel value="3">
      <div className="sideMargin">
        <div className="inputBx1">
                 <span>Current Password </span>
                 <input type={showPassword?"text" : "password"}autoComplete="chrome-off" value={currentPassword} onChange={(e)=> setCurrentPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowPassword(!showPassword)}  /> Show Password</label>
                 {/* {showPasswordErrorMessage ?<p style={{color:"#4D3A78"}}> {passwordErrorMessage} </p> : ""} */}
               </div>
               <div className="inputBx1">
                 <span>New Password</span>
                 <input type= {showNewPassword?"text" : "password"} autoComplete="chrome-off" value={newPassword} onChange={(e)=>setNewPassword(e.target.value) }/> 
                
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowNewPassword(!showNewPassword)}  /> Show Password</label>
                 {/* {showPasswordErrorMessage ?<p style={{color:"#4D3A78"}}> {passwordErrorMessage} </p> : ""} */}
               </div>
        <div className="inputBx1">
                 <span>Confirm New Password</span>
                 <input type={showConfirmPassword?"text":"password"} autoComplete="chrome-off" value={confirmNewPassword} onChange={(e)=>setConfirmNewPassword(e.target.value)} /> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox"  onChange={()=> setShowConfirmPassword(!showConfirmPassword)}  /> Show Password</label>
                 {showPasswordErrorMessage ?<Alert severity="error"> {passwordErrorMessage} </Alert> : ""}
               </div>
               <br/>
               <button
            onClick={() => {
             ChangePassword()
             
            }}
            className="buttons"
            disabled={passwordButtonDisabled}
          >
          Update Password
          </button>
               </div>
   </TabPanel>
   <TabPanel value="4"> 
   <div className="sideMargin"> 
   <div className="inputBx1">
      <span>Current Whatsapp</span>
      <input type={"text"} autoComplete="chrome-off" value={whatsapp} disabled={true} /> 
    </div>
    <div className="inputBx1">
      <span>New Whatsapp</span>
      <input type={"text"} maxLength={10} autoComplete="chrome-off" disabled={newWhatsappDisabled}  value={newWhatsapp} onChange={(e)=>setNewWhatsapp(e.target.value)}/> 
    </div>
    {!showOtp ? <button className="buttons"  disabled={otpDisabled} onClick={otpGenerator}>Get OTP</button> : <button className="buttons" onClick={Reset}>Reset</button>}

    {showOtp?<>
      <br/><br/>
      <Alert severity="info">  <Timer otpgen={otpGenerator}/></Alert>
    
      <div className="inputBx1">
      <span>Enter OTP</span>
      <input type={"text"} maxLength={6} autoComplete="chrome-off" value={otpRecieved} onChange={(e)=>setOtpRecieved(e.target.value)}/> 
    </div>
    <button className="buttons" onClick={CheckOtp}> Verify </button>
    {showErrorMessage&& (<Alert severity="error"> Wrong OTP Entered </Alert>)}
    </> :""
    }
    </div>
   </TabPanel>
   <TabPanel value="5">
    <div className="sideMargin">
    <div className="inputBx1">
      <span>Current Mobile</span>
      <input type={"text"} autoComplete="chrome-off" value={mobile} disabled={true} /> 
    </div>
    <div className="inputBx1">
      <span>New Mobile</span>
      <input type={"text"} maxLength={10} autoComplete="chrome-off" disabled={newMobileDisabled}  value={newMobile} onChange={(e)=>setNewMobile(e.target.value)}/> 
    </div>
    {!showMobileOtp ? <button className="buttons"  disabled={mobileOtpDisabled} onClick={MobileOtpGenerator}>Get OTP</button> : <button className="buttons" onClick={MobileReset}>Reset</button>}

    {showMobileOtp?<>
      <br/><br/>
      <Alert severity="info">  <Timer otpgen={MobileOtpGenerator}/></Alert>
    
      <div className="inputBx1">
      <span>Enter OTP</span>
      <input type={"text"} maxLength={6} autoComplete="chrome-off" value={mobileOtpRecieved} onChange={(e)=>setMobileOtpRecieved(e.target.value)}/> 
    </div>
    <button className="buttons" onClick={CheckMobileOtp}> Verify </button>
    {showErrorMessage&& (<Alert severity="error"> Wrong OTP Entered </Alert>)}
    </> :""
    }
    </div>
   </TabPanel>
    </TabContext>
         </div>
         <div style={{flexGrow:1}}></div>
          <Footer/>
    </Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import MultiImageInput from 'react-multiple-image-input';
import {getStorage,ref,getDownloadURL, uploadString} from "firebase/storage"
import {Modal, Button} from "react-bootstrap"

function AddBusinessModal({ setOpenModal}) {
    const [uid, setUid] = useState("")
    const [urls, setUrls] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
   const storage = getStorage()
    const [showNext, setShowNext] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("Please Enter a valid Email Address")
    
    const [showMobileError, setShowMobileError] = useState(false)
    const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")
    
    const [businessCategory, setBusinessCategory] = useState([])
    const [businessSubCategory, setBusinessSubCategory] = useState([])
    const [dbBizSubtype, setDbBizSubtype] = useState([])
    const [displayBusinessSubCategory, setDisplayBusinessSubCategory] = useState([[]])
    const [biztype, setBiztype] = useState("")
    const [bizsubtype, setBizsubtype] = useState("")
    const [bizwhatsapp, setBizwhatsapp] = useState("")
    const [bizowner, setBizowner] = useState("")
    const [bizmobile, setBizmobile] = useState("")
    const [bizemail, setBizemail] = useState("")
    const [bizname, setBizname] = useState("")
    const [images, setImages] = useState({})
    const [time, setTime] = useState("")
    const [businessID, setBusinessID] = useState("")
    const [bizaddress, setBizaddress] = useState("")
   const {currentUser} = useContext(AuthContext)
   const userID = currentUser.uid
  function getbiztype(){
      const ref = firebase.firestore().collection("BusinessCategories")
  
      ref.onSnapshot((querySnapshot)=>{
          let items=[]
          let items1=[]
          let itemsJson ={}
          querySnapshot.forEach((doc)=>{
             items.push(doc.id)
             itemsJson[doc.id] = Object.keys(doc.data())
             items1.push(itemsJson)
          });
        
          setBusinessCategory(items)
          setBusinessSubCategory(items1)
        })
  }
  
  
      
  useEffect(()=>{
      getbiztype()
     
      
  },[])
    
  
    const ValidateEmail = (value) =>{
      if (value !== "undefined") {
  
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
        if (!pattern.test(value)) {
      
          setShowEmailError(true)
          
      
         
      
        } else {
          setShowEmailError(false)
        }
      
      }
    }
      
  
    
  useEffect(()=>{
  
   if(biztype.trim() != "" && bizsubtype.trim()!= ""&& bizaddress.trim() !="" && bizname.trim() !="" && bizemail.trim() != "" && bizmobile.trim().length == 10 && bizwhatsapp.trim().length == 10 && Object.values(images).length >0)
     setButtonDisabled(false)
  else{
      setButtonDisabled(true)
  
    }
  },[biztype, bizemail, bizmobile, bizname, bizowner, bizsubtype, bizwhatsapp, images, bizaddress])
   
   function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }
      
    function checkMobile(value){
        if(value.length>10)
          return value.slice(0,10)
        else
          return value
    }
  
   
  useEffect(()=>{
    if(dbBizSubtype.length >0){
      for(let i=0; i<dbBizSubtype.length; i++){
          handleBizType(dbBizSubtype[i],i)
      }
    }
      
  },[dbBizSubtype])
  
    const UpdateBusiness=(y,x)=>{
      setLoading(true)
    
      const business = firebase.firestore().collection("users").doc(userID).collection('business');
    
        
        // let x = (new Date()).getTime().toString()
        // let y = "business" + x
        const ref = business.doc(y)
       // setBusinessID(y)
     
     ref.update({
      biztype: biztype,
      bizsubtype: bizsubtype,
      bizowner: bizowner.trim(),
      bizemail : bizemail.trim(),
      bizmobile : bizmobile.trim(),
      bizaddress : bizaddress.trim(),
      bizname: bizname.trim(),
      bizwhatsapp: bizwhatsapp.trim(),
      time: x,
      id:y,
      
  
     }).then(()=>{
    //  UploadImages(y)
    window.alert("New Business Added!!")
    setOpenModal(false)
    window.location.reload()
     }).catch(function(error){
       
     })
   
    } 
  
    function UploadImages(){
      setLoading(true)
      let x = (new Date()).getTime().toString()
      setTime(x)
      let id = "business" + x
      setBusinessID(id)
      const z = Object.values(images)
     let temp=[]
      let y =-1
      Object.values(images).map(async(image, key) => {
        const fileRef=ref(storage,`business/${userID}/${id}/images${key}.png`)
        // const arr = image.split(",")
        const snapshot= await uploadString(fileRef,image,'data_url' )
        const photoURL= await getDownloadURL(fileRef)
        
      //  temp.push(photoURL)
        setUrls((prevState) => [...prevState, photoURL]);
      })
      // z.forEach((image, index)=>{
      //   y++
      //   const ref = firebase.storage().ref(`business/${userID}/${id}`).child(`images${y}.png`)
      //   const arr = image.split(",")
     
      //   ref.putString(arr[1], 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
      //      snapshot.ref.getDownloadURL().then((url)=>{
      //        let x = y+1
      //       //  businessRef.update({
      //       //    [`images${index}`] : url,
      //       //    images: x.toString()
      //       //  })
            
      //       setUrls(prevstate=>[...prevstate,url])
       
      //      })
          
      //   })
      // })
      
     // window.alert("New Business Added")
      // // history.push("/my-profession")
    }
  
  useEffect(()=>{
    const z = Object.values(images)
    let temp={}
  
    if(z.length > 0 && (z.length == urls.length)){
      urls.forEach((url, index)=>{
        temp[index] = url
      })
     
      const businessRef = firebase.firestore().collection("users").doc(userID).collection('business').doc(businessID);
      businessRef.set({images : temp}).then(()=>{
        // window.alert("New Business Added !!")
        UpdateBusiness(businessID, time)
     //   // history.push("/my-profession")
      })
      
    }
 
  },[urls])
   
    
     const handleBizType=(e)=>{
      
       let bsub = [...displayBusinessSubCategory]
      
       if(e== ""){
        bsub=[]
       } 
       else{
        
        bsub= businessSubCategory[0][e.toLowerCase()]
  
       }
       setDisplayBusinessSubCategory(bsub)
     }
     Array.prototype.insert = function(index,item){
       this.splice(index,0, item)
     };
   

  return (

    <Modal
    show={true}
    onHide={setOpenModal}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
    <Modal.Title>
    <h1 style={{fontSize:"20px"}}>Add New Profession </h1>
    </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="inputBx1">
                  <span>Business Type</span><span style={{color:"red"}}>*</span><br/>
                  <select value={biztype}  onChange={(e)=> {setBiztype(e.target.value); handleBizType(e.target.value)} }>         
                  <option value="">Choose</option>
                 {businessCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>))}
                    </select>  
                </div>
                <div className="inputBx1">
                  <span>Business Sub Type</span><span style={{color:"red"}}>*</span><br/>
                  <select value={bizsubtype}  name="bizsubtype" onChange={(e)=> setBizsubtype(e.target.value)} >         
                  <option value="">Choose</option>
                 {displayBusinessSubCategory != undefined && displayBusinessSubCategory.length >0 &&(displayBusinessSubCategory.map((val, key)=> (<option value={val} key={key}>{toTitleCase(val)}</option>)))}
                    </select>  
                </div>
                <div className="inputBx1">
                  <span>Business Name</span><span style={{color:"red"}}>*</span><br/>
                  <input type="text" value={bizname}  onChange={(e)=> setBizname(e.target.value)}/> 
                </div>
                <div className="inputBx1"  >
                  <span>Business Mobile</span><span style={{color:"red"}}>*</span><br/>
                  <input type="number"  value={bizmobile} maxLength={10} name="bizmobile" onChange={(e)=> setBizmobile(e.target.value)}/> 
                  {showMobileError ?<p style={{color:"#4D3A78"}}> {mobileErrorMessage} </p> : ""}
                </div>
                <div className="inputBx1">
                  <span> Business Email</span> <span style={{color:"red"}}>*</span><br/>
                  <input type="text" value={bizemail} name="bizemail"  onChange={(e)=> setBizemail(e.target.value)}/> 
                  {showEmailError ?<p style={{color:"#4D3A78"}}> {emailErrorMessage} </p> : ""}
                </div>

                <div className="inputBx1">
                  <span>Business Owner</span><span style={{color:"red"}}>*</span> <br/>
                  <input type="text" value={bizowner}  name="bizowner" onChange={(e)=> setBizowner(e.target.value)}/> 
                </div>
                <div className="inputBx1">
                  <span>Business Whatsapp</span> <span style={{color:"red"}}>*</span> <br/>
                  <input type="text" value={bizwhatsapp} maxLength={10}  name="bizwhatsapp" onChange={(e)=> setBizwhatsapp(e.target.value)}/> 
                </div>
                <div className="inputBx1">
                  <span>Business Address</span><span style={{color:"red"}}>*</span> <br/>
                  <input type="text" value={bizaddress}  name="bizaddress" onChange={(e)=> setBizaddress(e.target.value)}/> 
                </div>

                <div className="inputBx1">
                  <span>Business Image</span><span style={{color:"red"}}>*</span><span><i style={{fontSize:"12px"}}>(Maximum 5)</i></span><br/>
                  <MultiImageInput
       images={images}
       setImages={setImages}
       theme="light"
       max={5}
       allowCrop={false}
   
     />

                </div>


    </Modal.Body>
    <Modal.Footer>

              <Button variant="secondary" onClick={()=> setOpenModal(false)}>
                Close
              </Button>
              <Button variant="primary"         onClick={() => {
           UploadImages()
            }}
             disabled={buttonDisabled}>
                Save
              </Button>

            </Modal.Footer>
    </Modal>
 
  );
}

export default AddBusinessModal;
import React, { useContext, useEffect, useState } from "react";
import AdminContext from "./AdminContext"
import firebase from "../Common Components/firebase"
import LoaderUi from "../Common Components/LoaderUi";
import { AuthContext } from "../auth/Auth";


const AdminState = (props) =>{
   
    const [loading, setLoading] = useState(false);
    const [currentUserType, setCurrentUserType] = useState("")
    const {currentUser} = useContext(AuthContext)
    useEffect(()=>{
    if(currentUser)
        firebase.firestore().collection("users").doc(currentUser.uid).get().then((doc)=>{
            if(doc.exists)
                if(doc.data().type)
                    setCurrentUserType(doc.data().type)
        })
    },[currentUser])
    
        
      
          if(loading)
            return(<LoaderUi/>)
    return(
        <AdminContext.Provider value={{currentUserType}}>
            {props.children}
        </AdminContext.Provider>
    )
}

export default AdminState;
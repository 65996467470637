import _ from "lodash";

export const placeholderManImage = `https://firebasestorage.googleapis.com/v0/b/mymmuz-nearby-f58e2.appspot.com/o/users%2Fplaceholder%20man%20Image.jpeg?alt=media&token=8abe09b4-3164-4148-9336-be15a6492e93`
export const logo = "https://firebasestorage.googleapis.com/v0/b/mvt-demo-ca374.appspot.com/o/MVT%20Backgrounbdless%20Logo.png?alt=media&token=02174d00-0133-4f72-a3b9-f44ed6cf029a"
export function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }

  export function StringToTime(str){
    if(str != "" && str != undefined){
      const time = str.toString()
      const timeArr = time.split("/")
      console.log(timeArr )
      var mydate = new Date(timeArr[2], timeArr[1] - 1, timeArr[0]); 
      console.log(mydate.toDateString())
      // console.log(mydate.toDateString());
      return mydate.toDateString()
    }else{
      return str
    }
  }

  export function SingleToDouble(str){
    if(str.length == 2)
      return str
    else
      return `0${str}`
  }

 export function JsonArrUnique(arr) {
    var cleaned = [];
    arr.forEach(function(itm) {
        var unique = true;
        cleaned.forEach(function(itm2) {
            if (_.isEqual(itm, itm2)) unique = false;
        });
        if (unique)  cleaned.push(itm);
    });
    return cleaned;
}
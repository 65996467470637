import React, { useState, useEffect, useContext } from "react";
import { placeholderManImage } from "../Common Components/CommonFunctions";
import "./UpdateModal.css";
import { Modal, Button } from "react-bootstrap";
function  MemberImageDisplayModal({ setOpenModal, imageSrc}) {

  return (
    <Modal
        show={true}
        onHide={setOpenModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
        <img src={imageSrc} className="modalImg" onError={(e)=>{e.target.onerror = null; e.target.src=placeholderManImage}} style={{border:"1px solid #4D3A78"}} />
            </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setOpenModal(false) }}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
  );
}

export default MemberImageDisplayModal;
import React, { useState, useEffect, useContext } from "react";
import {Modal, Button} from "react-bootstrap"

export default function MyBusinessImageViewModal({ setOpenModal, imageArr}) {


  return (

    <Modal
    show={true}
    onHide={setOpenModal}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
    <Modal.Title>

    </Modal.Title>
    </Modal.Header>
   <Modal.Body>
    {Object.values(imageArr).map((image)=>(
        <img src={image} style={{margin:"2px"}} width={200}/>
    ))}
   </Modal.Body>
    <Modal.Footer>

              <Button variant="secondary" onClick={()=> setOpenModal(false)}>
                Close
              </Button>
              

            </Modal.Footer>
    </Modal>
 
  );
}


import React,{useState, useEffect, useContext} from 'react';
import { ButtonSignOut } from '../Common Components/ButtonSignOut';
import { ArrowDownwardRounded } from '@mui/icons-material';
import { ArrowUpwardRounded } from '@mui/icons-material';
import "../Navbar.css"
import { SidebarData}  from "./SideMenuItems";
import firebase from '../Common Components/firebase';
import { AuthContext } from '../auth/Auth';
import MVTLogo from "../Assets/MVTBackgroundlessLogo.png"
import LoaderGif from "../Assets/MVT_GIF.gif"

import { useLocation } from "react-router-dom";

export const  Navbar = () =>{

        const [clicked, setClicked] = useState(false);
        const [show, setShow] = useState(false)
        const [currentUserType, setCurrentUserType] = useState(false)
        const {currentUser} = useContext(AuthContext)
        const userid = currentUser.uid
        const state = useLocation()
        let path = state.pathname;
        let arr = path.split("/");
        let mid = arr[1];
         function checkPath(id){
           let x= id.split("/")
      
           return x[1]
         }
        useEffect(()=>{
            firebase.firestore().collection("users").doc(userid).get().then((doc)=>{
              setCurrentUserType(doc.data().type)
            })
           })
     

        return(
            <nav className="NavbarItems" ><div className="navbar-logo"><img src={LoaderGif} width={400}></img></div> 
            <div className="menu-icon" onClick={()=>setClicked(!clicked)}>
                <i className={clicked? "fas fa-times":"fas fa-bars"}></i> </div>
            <ul className={clicked? "nav-menu active" : "nav-menu"} style={{overflow:"scroll"}}>
                   
                {SidebarData.map((item, index) => {
                    
                    return(<li key={index}><a href={item.url}  className={checkPath(item.url)==mid?"selected nav-links":"nav-links"} >{item.title}</a>  </li>   )
                                })}
                                 
            {/* <li><a className="nav-links" href="/"  >My Profile</a>  </li> 
            <li><a className="nav-links" href="/member-portal"  >Member Directory</a>  </li> 

             <li><a className="nav-links" href="/gallery"  >Manch Activities</a>  </li> 
            */}
                <li  className="row" onClick={()=>{window.location.pathname= '/secretariat'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath('/secretariat')==mid?"selected nav-links":"nav-links"}> Secretariat</div>                            
                     </li>
                 {currentUserType == 'admin'?<>
                     <li  className="row" onClick={()=>{window.location.pathname= '/all-members'}}> 
                         <div style={{cursor:'pointer'}} className={checkPath('/all-members')==mid?"selected nav-links":"nav-links"}>All Members</div>                            
                     </li>
                     <li  className="row"  onClick={()=>{window.location.pathname= '/newreg'}}>
                         
                         <div style={{cursor:'pointer'}} className={checkPath("/newreg")==mid?"selected nav-links":"nav-links"}>Pending Registrations</div>                            
                     </li>

                     </>:""}
             <li>  <ButtonSignOut style={{fontSize:"1.2rem", paddingBottom:"11px"}}>Sign Out</ButtonSignOut>  </li> 
            </ul>

            </nav>
        );

}
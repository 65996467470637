import React, { Fragment, useEffect, useState, useContext } from "react";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
//import { LoginNavbar } from "../Navbar/LoginNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "@mui/material";
import axios from "axios";
import "../Registration.css"
import RegistrationPart2 from "./RegistrationPart2";
import Carousel from "react-material-ui-carousel";

export default function RegistrationPart1(props) {

  const[showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showNextPart, setShowNextPart] = useState(false)
 const [aliasName, setAliasName] = useState(props.aliasname)
  const [pan, setPan] = useState(props.pan)
  const [aadhar, setAadhar] = useState(props.aadhar)
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState(props.doa)
  const [address, setAddress] = useState(props.address)
  const [spouse, setSpouse] = useState(props.spouse);
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [salutation, setSalutation]  = useState("")
  const [marriedDisplay, setMarriedDisplay] = useState(props.marriedDisplay)
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")


  const [spouseMobile, setSpouseMobile] = useState(props.spousemobile)
  const [children, setChildren] = useState(props.children)  
  function nextPart(){
      setShowNextPart(true)
  }

    
    useEffect(()=>{
        if(name.trim() !="" && fathername.trim() !="" && aliasName.trim() !="" && gender.trim() !="" && salutation !="")
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
    },[name, fathername, aliasName, gender, salutation])
  
  return (
    <Fragment>
        {/* <LoginNavbar/>  
       <LoginPageNavbar/> */}
        {!showNextPart ?
            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
          </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             <div className="inputBx">
                 <span>Salutation</span>
                 <select value={salutation} onChange={(e)=> setSalutation(e.target.value)} >
                  <option value={""}>Choose</option>
                  <option value={"Mr."} >Mr. </option>
                  <option value={"Mrs."} >Mrs. </option>
                  <option value={"Ms."} >Ms. </option>
                  <option value={"Dr."} >Dr. </option>
                  <option value={"CA"} >CA </option>
                  

                  
                </select> 
                
               </div>
               <div className="inputBx">
                 <span>Full Name</span>
                 <input type="text" value={name} autoComplete="off" disabled={true}/> 
                
               </div>
               <div className="inputBx">
                 <span>Father's / Husband's Name</span>
                 <input type="text" value={fathername} autoComplete="off" onChange={(e)=> setFathername(e.target.value)}/> 
               </div>
               <div className="inputBx">
                 <span>Alias Name (The Name you often called with)</span>
                 <input type="text" value={aliasName} autoComplete="off" onChange={(e)=> setAliasName(e.target.value)}/> 
               </div>

               <div className="inputBx">
                 <span>Gender</span><br/>
                 <select value={gender} onChange={(e)=> setGender(e.target.value)} >         
                 <option value="">Choose</option>
                          <option value="M">Male</option>    
                         <option value="F">Female</option>  
                         <option value="O">Others</option>           
                   </select>  
               </div>

             
               <div className="inputBx">             
                 <input type="button" value="Next"  disabled={buttonDisabled} onClick={nextPart} /> 
               </div>

            
           </div>
         </div>
       </section>

: <RegistrationPart2 name={name} gender={gender} spousemobile={spouseMobile}   fathername={fathername} bloodGroup={bloodGroup} email={email} married={props.married}  password={password} mobile={mobile}  memstatus={props.memstatus} membershipId={membershipId}  whatsapp={whatsapp}  address={address} dob={dob} doa={doa} spouse={spouse} children={children} marriedDisplay={marriedDisplay}  pan={pan} aadhar={aadhar} aliasname={aliasName} spousedob={props.spousedob} salutation={salutation} images={props.images}/>}
         
    </Fragment>
  );
}


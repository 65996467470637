import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";
import { ExportCSVCommon } from "../Common Components/ExportCSVCommon";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { IconButton, useScrollTrigger } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TitleCase from 'react-title-case';
import { placeholderManImage } from "../Common Components/CommonFunctions";
import { Modal, Button } from "react-bootstrap";

function ViewRegisteredMemberModal({ setOpenModal, userDetails}) {
    const [memstatus, setMemstatus] = useState(userDetails.memstatus)
    const [memType, setMemType] = useState(userDetails.memtype)
    const [userID, setUserId] = useState(userDetails.userid)
    const [mId, setMid]  = useState(userDetails.mid)
    const [name, setName] = useState(userDetails.name)
    const [membershipTypes, setMembershipTypes] = useState({})

    useEffect(()=>{
      firebase.firestore().collection("website").doc("membershiptypes").get().then((doc)=>{
        setMembershipTypes(doc.data())
      })
    },[])


 
  return (
    <Modal show={true}    onHide={setOpenModal}
    backdrop="static"
    keyboard={false} dialogClassName="memberDetailsModal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
         <center>
         <div className="card2 ">
                 <div className="upper-container">
                   <div className="image-container1">
                     <img
                       src={
                         userDetails.image
                       }
                       onError={(e) => {
                         e.target.src = placeholderManImage
                         e.target.onError = null;
                       }}
                  
                       width="200px"
                  
                     ></img>
                   </div>
                 </div>
                 <div className="lower-container">
                   <h3> <TitleCase string={`${userDetails.name}`} /></h3>
                   <h4>{userDetails.mid}</h4>
                   <h3 style={{ color: "#A03840" }}>Personal Details</h3>
                   <p>Date of Birth : {userDetails.dobirth} </p>
                   <p>Blood Group: {userDetails.bloodgroup}</p>
                   <h3 style={{ color: "#A03840" }}>Contact Details</h3>
                   <p> Address: {userDetails.address}</p>
                   <p>Email: {userDetails.email}</p>
                   <p>Phone: {userDetails.phone}</p>
                   <p>Whatsapp: {userDetails.whatsapp}</p>
                  
                   {userDetails.married == "yes" ? <>
                   <h3 style={{ color: "#A03840" }}>Marriage  Details</h3>
                   <p>Date of Anniversary : {userDetails.doanniversary}</p>
                   <p>Spouse: {userDetails.spousename} </p>
                   {userDetails.spousemob != "" ? <p> Spouse Mobile  : {userDetails.spousemob} </p>: ""}
                   {userDetails.spouseemail != "" ? <p> Spouse Email  : {userDetails.spouseemail} </p>: ""}
                   </> :""}
                   <h3 style={{color:"#A03840"}}> Membership Details </h3>
                   <p>Membership Status : {toTitleCase( userDetails.memstatus)}</p>
                   {userDetails.memtype != undefined && (<p>Membership Type : {membershipTypes[userDetails.memtype]}</p>)}

                   <h3 style={{ color: "#A03840" }}>Operations</h3>

                   <IconButton className="IconButton" href={"mailto:" + userDetails.email}>
                     <EmailIcon style={{ fontSize: 50 }} />{" "}
                   </IconButton>
                   <IconButton className="IconButton" href={"tel:" + userDetails.phone}>
                     {" "}
                     <PhoneInTalkRoundedIcon style={{ fontSize: 50 }} />{" "}
                   </IconButton>
                   <IconButton className="IconButton"
                     href={"https:www.google.com/maps/dir " + userDetails.address}
                   >
                     {" "}
                     <LocationOnRoundedIcon style={{ fontSize: 50 }} />{" "}
                   </IconButton>
                   <IconButton className="IconButton" href={"https:wa.me/+91" + userDetails.whatsapp}>
                     <WhatsAppIcon style={{ fontSize: 50 }}></WhatsAppIcon>
                   </IconButton>
                 </div>
               </div>  
               </center>          
      

      </Modal.Body>
      <Modal.Footer>

          <Button variant="secondary" onClick={()=> setOpenModal(false)}>
            Close
          </Button>

        </Modal.Footer>
    </Modal>
    // <div className="modalBackground1">
    //   <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"60vw", overflowY:"scroll"}}>
    //     <div className="titleCloseBtn">
    //       <button
    //         onClick={() => 
    //             setOpenModal(false)
    //         }
    //       >
    //         X
    //       </button>
    //     </div>
    //     <div className="title">
    //     </div>
       
    //     <div className="body">
    //     <center>
    //     <div className="card2 ">
    //             <div className="upper-container">
    //               <div className="image-container1">
    //                 <img
    //                   src={
    //                     userDetails.image
    //                   }
    //                   onError={(e) => {
    //                     e.target.src = placeholderManImage
    //                     e.target.onError = null;
    //                   }}
                  
    //                   width="200px"
                  
    //                 ></img>
    //               </div>
    //             </div>
    //             <div className="lower-container">
    //               <h3> <TitleCase string={`${userDetails.name}`} /></h3>
    //               <h4>{userDetails.mid}</h4>
    //               <h3 style={{ color: "#A03840" }}>Personal Details</h3>
    //               <p>Date of Birth : {userDetails.dobirth} </p>
    //               <p>Blood Group: {userDetails.bloodgroup}</p>
    //               <h3 style={{ color: "#A03840" }}>Contact Details</h3>
    //               <p> Address: {userDetails.address}</p>
    //               <p>Email: {userDetails.email}</p>
    //               <p>Phone: {userDetails.phone}</p>
    //               <p>Whatsapp: {userDetails.whatsapp}</p>
                  
    //               {userDetails.married == "yes" ? <>
    //               <h3 style={{ color: "#A03840" }}>Marriage  Details</h3>
    //               <p>Date of Anniversary : {userDetails.doanniversary}</p>
    //               <p>Spouse: {userDetails.spousename} </p>
    //               {userDetails.spousemob != "" ? <p> Spouse Mobile  : {userDetails.spousemob} </p>: ""}
    //               {userDetails.spouseemail != "" ? <p> Spouse Email  : {userDetails.spouseemail} </p>: ""}
    //               </> :""}
    //               <h3 style={{color:"#A03840"}}> Membership Details </h3>
    //               <p>Membership Status : {toTitleCase( userDetails.memstatus)}</p>
    //               {userDetails.memtype != undefined && (<p>Membership Type : {membershipTypes[userDetails.memtype]}</p>)}

    //               <h3 style={{ color: "#A03840" }}>Operations</h3>

    //               <IconButton className="IconButton" href={"mailto:" + userDetails.email}>
    //                 <EmailIcon style={{ fontSize: 50 }} />{" "}
    //               </IconButton>
    //               <IconButton className="IconButton" href={"tel:" + userDetails.phone}>
    //                 {" "}
    //                 <PhoneInTalkRoundedIcon style={{ fontSize: 50 }} />{" "}
    //               </IconButton>
    //               <IconButton className="IconButton"
    //                 href={"https://www.google.com/maps/dir// " + userDetails.address}
    //               >
    //                 {" "}
    //                 <LocationOnRoundedIcon style={{ fontSize: 50 }} />{" "}
    //               </IconButton>
    //               <IconButton className="IconButton" href={"https://wa.me/+91" + userDetails.whatsapp}>
    //                 <WhatsAppIcon style={{ fontSize: 50 }}></WhatsAppIcon>
    //               </IconButton>
    //             </div>
    //           </div>  
    //           </center>          
    //     </div>
    //     <div className="footer">
  
    //    <button
    //         onClick={() => {
    //          setOpenModal(false)
             
    //         }}
        
    //       >
    //       Cancel
    //       </button>
        
     
         
       
    //     </div>
     
    //   </div>
   
    // </div>
  );
}

export default  ViewRegisteredMemberModal;
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
} from "react";
import firebase from "./Common Components/firebase";
import { AuthContext } from "./auth/Auth";
import "./card.css";
import IdleTimeContainer from "./Common Components/IdleTimeContainer";
import { Navbar } from "./Navbar/Navbar";
import {LoginNavbar} from './Navbar/LoginNavbar'
import SideMenuBar from "./Navbar/SideMenuBar";
import LoaderUi from "./Common Components/LoaderUi";
import BirthdayPanel from "./Panels/BirthdayPanel";
import AnniversaryPanel from "./Panels/AnniversaryPanel";
import { placeholderManImage, toTitleCase } from "./Common Components/CommonFunctions";
import ImageModal from "./Modal/ImageModal";
import MemberInactiveModal from "./Modal/MemberInactiveModal";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddAffliationModal from "./Modal/AddAffliationModal";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditAffliationModal from "./Modal/EditAffliationModal";
import AddBusinessModal from './Modal/AddBusinessModal';
import UpdateBusinessModal from './Modal/UpdateBusinessModal';
import { Alert, IconButton } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { DeleteForever, EditOutlined, ShoppingBasketOutlined } from '@mui/icons-material';
import MyBusinessImageViewModal from "./Modal/MyBusinessImageModal";
import MyProductsDisplayModal from "./Modal/MyProductsDisplayModal";
import Footer from "./Footer/Footer";
function Welcome() {
  const [schools, setschools1] = useState([]);
  const [loading, setloading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [value, setValue] = useState("1")
  const [affliationModalOpen, setAffliationModalOpen] = useState(false)
  const [myAffliations, setMyAffliations] = useState([])
  const [showPanels, setShowPanels] = useState(true)
  const [affliationsForRender, setAffliationsForRender] = useState([])
  const [editAffliationModalOpen, setEditAffliationModalOpen] = useState(false)
  const [editAffliationId, setEditAffliationId] = useState("")
  const [AddBusinessModalOpen, setAddBusinessModalOpen] = useState(false)
  const [UpdateBusinessModalOpen, setUpdateBusinessModalOpen] = useState(false)
  const [businessIDToUpdate, setBusinessIDToUpdate] = useState("")
  const [myBusinesses, setmyBusinesses] =useState([]);
  const [viewBusinessImageObj, setViewBusinessImageObj] = useState({})
  const [viewBusinessImageModalOpen, setViewBusinessImageModalOpen] = useState(false)
  const [businessDataforRender, setBusinessDataforRender] = useState([])
  const [myProductsModalOpen, setMyProductsModalOpen] = useState(false)
  const [businessID, setBusinessId] = useState("")

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue =="2" || newValue=="3")
      setShowPanels(false)
    else
      setShowPanels(true)
  };
  const [memberInactiveModal, setMemberInactiveModal] = useState(false)

  // function CheckBusiness (){
  //   firebase.firestore().collection("users").doc(userId).collection("business").limit(1).get().then((query)=>{
  //   if(query.size==0)
  //     setModalOpen(true)
  //   });
    	
  // }

  // useEffect(()=>{
  //  CheckBusiness()
  // },[])
  /** Function that will set different values to state variable
   * based on which dropdown is selected
   */

  const ref = firebase.firestore().collection("users");

  const { currentUser } = useContext(AuthContext);
 
  const userId = currentUser.uid;
  const busRef = firebase.firestore().collection("users").doc(userId).collection("business");
 
  function getSchools() {
    


    ref.onSnapshot((querySnapshot) => {
      const items = [];

      querySnapshot.forEach((doc) => {
        if (doc.data().userid === currentUser.uid) items.push(doc.data());
      });
      setschools1(items);
     
      setloading(false);
    });


   

  }
  useEffect(()=>{
    firebase.firestore().collection("users").doc(userId).get().then((doc)=>{
      if(doc.data().memstatus != "active"){
        window.alert("Your Memberstatus is Inactive !! Please Contact the administartor !!")
        firebase.auth().signOut()
      }else{
        getSchools()
      }
    })
  })

  useEffect(()=>{
    firebase.firestore().collection("users").doc(userId).collection("affliations").onSnapshot((querySnapshot)=>{
      let temp =[]
      querySnapshot.forEach((doc)=>{
        let x={}
        x['organizationname'] = toTitleCase( doc.data().organizationname)
        x['post'] = toTitleCase( doc.data().post)
        x['startdate'] = `${doc.data().starttimemonth} / ${doc.data().starttimeyear}`
        x['enddate'] = doc.data().tilltoday == "yes" ? "Till Today" : `${doc.data().endtimemonth} / ${doc.data().endtimeyear}`
        x['id'] =   doc.data().affliationid
        temp.push(x)
      })
      setMyAffliations(temp)
    })
  },[])

  useEffect(()=>{
 
    let postsArray = JSON.parse(JSON.stringify(myAffliations));
    let userData = [];
    postsArray.map((item, index) => {
      
      item.action = (
          <>

          <EditIcon  style={{color:"green", marginLeft:"2px", cursor:"pointer"}} onClick={()=>EditAffliation(item.id)}/>
        <DeleteIcon style={{color:"red", marginLeft:"2px", cursor:"pointer"}} onClick={()=>DeleteAffliation(item.id)}/>

</>
      );
      userData.push(item);
    });
    setAffliationsForRender(userData);
  }, [myAffliations]);

  function DeleteAffliation(id){
    setloading(true)
    if(window.confirm("Are You Sure You want to Delete?\nThis can't be done !!")){

      firebase.firestore().collection("users").doc(userId).collection("affliations").doc(id).delete().then(()=>{
        window.alert("Affliation Details Deleted !!")
        window.location.reload()
      })
    }
 
  }

  function EditAffliation(id){
    setEditAffliationId(id)
    setEditAffliationModalOpen(true)
  }

  
  const data = {
    columns: [
       
        {
            label: 'Organization Name',
            field: 'organizationname',
            sort: 'asc',
            width: 150,
            
          },
          {
            label: 'Post Name',
            field: 'post',
            sort: 'asc',
            width: 150
          },   {
            label: 'Start Date',
            field: 'startdate',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'End Date',
            field: 'enddate',
            sort: 'asc',
            width: 150
          },   {
            label: 'Actions',
            field: 'action',
            sort: 'asc',
            width: 150
          }
         
    ],
    rows: affliationsForRender

  };
/////////////////////////////////////////////////////////////////////////////////////////////
/* My Businesses */
////////////////////////////////////////////////////////////////////////////////////////////////

function getmyBusinesses(){

  setloading(true);
  const items=[];
  firebase.firestore().collection("users").doc(userId).collection("business").onSnapshot((querySnapshot)=>{
  
    querySnapshot.forEach((doc)=>{
         
          items.push(doc.data());
    });
    setmyBusinesses(items);
    setloading(false);
   
  })
}  



useEffect(()=>{
  getmyBusinesses();
},[]);


function DeleteBusiness(id){
 
    let x = window.confirm("Are You Sure ? \n This action can't be undone!!")
    
    if(x){
     setloading(true)
    const ref = firebase.firestore().collection("users").doc(userId).collection("business").doc(id)
     const storageRef =  firebase.storage().ref(`business/${userId}/${id}/`)
    
     storageRef.listAll().then((listResults) => {
       const promises = listResults.items.map((item) => {
         return item.delete();
       });
       Promise.all(promises);
     });
   
     ref.delete().then(()=>{
       window.alert("Business Deleted !!")
       window.location.reload()
   })

   
   }
}
function MyProducts(id){
  setBusinessId(id)
  setMyProductsModalOpen(true)
}

useEffect(()=>{
 
  let postsArray = JSON.parse(JSON.stringify(myBusinesses));
  let userData = [];
  postsArray.map((item, index) => {
    item.serialno =(<>
      <b>{index+1}.</b>
    </>)
    item.viewImage=(
      <center>
      <PreviewIcon style={{ cursor:"pointer"}} onClick={()=>{setViewBusinessImageModalOpen(true); setViewBusinessImageObj(item.images)}}/>
      </center>
    )

    item.products=(
      <center>
      <ShoppingBasketOutlined  style={{ cursor:"pointer"}} onClick={()=> MyProducts(item.id) }/>
      </center>
    )
    
    item.action = (
        <>

        <EditIcon  style={{color:"green", marginLeft:"2px", cursor:"pointer"}}onClick={()=>{setUpdateBusinessModalOpen(true); setBusinessIDToUpdate(item.id)}} />
      <DeleteIcon style={{color:"red", marginLeft:"2px", cursor:"pointer"}} onClick={()=>DeleteBusiness(item.id)}/>

</>
    );

    userData.push(item);
  });
  setBusinessDataforRender(userData);
}, [myBusinesses]);
const businessData = {
  columns: [

          {
          label: 'Sl No.',
          field: 'serialno',
          sort: 'asc',
          width: 150,
          
        },
      {
          label: 'Business Name',
          field: 'bizname',
          sort: 'asc',
          width: 150,
          
        },
        
      {
        label: 'Business Type',
        field: 'biztype',
        sort: 'asc',
        width: 150,
        
      },
      
      {
        label: 'Business Subtype',
        field: 'bizsubtype',
        sort: 'asc',
        width: 150,
        
      },
      {
        label: 'Business Mobile',
        field: 'bizmobile',
        sort: 'asc',
        width: 150
      }, 
        {
          label: 'Business Whatsapp',
          field: 'bizwhatsapp',
          sort: 'asc',
          width: 150
        },   
        {
          label: 'Business Email',
          field: 'bizemail',
          sort: 'asc',
          width: 300
        },
        
        {
          label: 'View Image',
          field: 'viewImage',
          sort: 'asc',
          width: 150
        }, 
        {
          label: 'Products',
          field: 'products',
          sort: 'asc',
          width: 150
        }, 
        
        {
          label: 'Actions',
          field: 'action',
          sort: 'asc',
          width: 150
        }
       
  ],
  rows: businessDataforRender

};

  if (loading) {
    return (<LoaderUi/>);
  }


  return (
    <Fragment>
      <div className="flex-wrapper">
      {AddBusinessModalOpen && <AddBusinessModal setOpenModal={setAddBusinessModalOpen} />}
      {UpdateBusinessModalOpen && <UpdateBusinessModal setOpenModal={setUpdateBusinessModalOpen} businessId={businessIDToUpdate}/>}
      {viewBusinessImageModalOpen && <MyBusinessImageViewModal setOpenModal={setViewBusinessImageModalOpen} imageArr={viewBusinessImageObj}/>}
      {affliationModalOpen && <AddAffliationModal setOpenModal={setAffliationModalOpen} userId={userId}/>}
     {memberInactiveModal ? <MemberInactiveModal setOpenModal={setMemberInactiveModal}/> :""}
     {editAffliationModalOpen && <EditAffliationModal setOpenModal={setEditAffliationModalOpen} userId={userId} affliationId={editAffliationId}/>}
     {myProductsModalOpen && <MyProductsDisplayModal setOpenModal={setMyProductsModalOpen} businessID={businessID} />}
    
      {/* {/* <LoginNavbar */}
      <Navbar />
      <SideMenuBar />
      <IdleTimeContainer />
     
     {showPanels && ( <div className="mobileViewDisappear">
      <BirthdayPanel/>
      <AnniversaryPanel/>
      </div>)}
      <div style={{ paddingTop: "10vh" }}></div>
      <TabContext value={value}>
      <div className="sideMargin"> 
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="My Profile" value="1" />
            <Tab label="My Affliations" value="2" />
            <Tab label="My Profession" value="3" />
           
          </TabList>
       </div>
        <TabPanel value="1">
      {imageModalOpen && <ImageModal setOpenModal={setImageModalOpen} />}
   
     

      <div
       
        className="justify-content-center "
      >
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          {schools.map((school) => (
            <div key={school.userid}>
              <div className="card1 ">
                <div className="upper-container">
                  <div className="image-container">
                    <label for="file-input">
                      <img
                        src={school.image}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = placeholderManImage
                        }}
                        style={{cursor:"pointer"}}
                        onClick={()=> setImageModalOpen(true)}
                        
                        width="200px"
                      />
                    </label>
                  </div>
                </div>
                <div className="lower-container">
                  <h3> {school.salutation}  {toTitleCase(school.name)}</h3>
                  <h4>{school.mid}</h4>
                  <h3 style={{ color: "#A03840" }}>Personal Details</h3>
                  <p>Alias Name : {school.aliasname}</p>
                  <p> Father's Name : {toTitleCase(school.fathersname)} </p>
                  <p>Date of Birth : {school.dobirth} </p>
                  <p>Blood Group: {school.bloodgroup}</p>
                  {school.pan != undefined && (<p>PAN : {school.pan}</p>)}
                  {school.aadhar != undefined && (<p>Aadhar :{school.aadhar}</p>)}
                  {school.married== "yes" &&(<> <h3 style={{ color: "#A03840" }}>Marriage Details</h3>
                  {(school.doanniversary != "" && school.doanniversary != undefined) ? <p>DOA: {school.doanniversary} </p> :""}
                  {(school.spousename != "" && school.spousename != undefined) ? <p>Spouse: {toTitleCase(school.spousename)} </p> :""}
                  {(school.spousemob != "" && school.spousemob != undefined) ? <p>Spouse's Mobile: {toTitleCase(school.spousemob)} </p> :""}
                  </>)}
                  <h3 style={{ color: "#A03840" }}>Contact Details</h3>
                  <p> Address: {toTitleCase(school.address)}</p>
                  <p>Email: {school.email}</p>
                  <p>Phone: {school.phone}</p> 
                  <p>Whatsapp: {school.whatsapp}</p> 
                  <h3 style={{color:"#A03840"}}> Membership Details </h3>
                  {school.memtype != undefined && (<p>Membership Type : {toTitleCase(school.memtype)}</p>)}

            <p style={{fontStyle:"italic",  color:'red', paddingBottom:"20px"}}><b>Click on Profile Picture to Update!!</b></p>
                                </div>
              </div>
            </div>
          ))}
          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
      </div>
      </TabPanel>
      <TabPanel value="2">
      <div style={{ justifyContent:"flex-end",alignContent:"flex-end", display:"flex", marginRight:"2%"}}>
        <button onClick={()=> setAffliationModalOpen(true)} style={{borderRadius :"10px", backgroundColor:"#4D3A78", padding:"10px", color:"white"}}>Add Affiations</button>
        </div>
        <div className="sideMargin">
          {myAffliations.length == 0 ? <Alert severity='info'>No Affiations are Present !! </Alert>:
        <MDBDataTable   striped
      bordered
      small
      data={data} scrollX/>}
      </div>
      </TabPanel>
      <TabPanel value="3">
      <div className="sideMargin">
      <div style={{ justifyContent:"flex-end",alignContent:"flex-end", display:"flex", marginRight:"2%"}}>
      <button  onClick={()=>setAddBusinessModalOpen(true)} className	="buttons" >Add New Business</button>
      </div>
      {myBusinesses.length == 0 ?
      
      <Alert severity='info'>No Businesses to Display !! Please Add to View</Alert>
         
        : <>
          <MDBDataTable data={businessData} small striped scrollX   />

    </> }
        </div>
      </TabPanel>
        </TabContext>
        </div>
        <div style={{flexGrow:1}}></div>
     <Footer/>
   
    </Fragment>
  );
}

export default Welcome;

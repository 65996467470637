import React, {useEffect, useState, useRef} from "react";
import {useIdleTimer} from "react-idle-timer"
import Modal from "react-modal"
import firebase from "../Common Components/firebase"

Modal.setAppElement("#root")
const IdleTimeContainer =()=>{
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const IdleTimerRef = useRef(null); 
    const SessionTimeout = useRef(null);

    const Idle = ()=>{
        setModalIsOpen(true)
        SessionTimeout.current = setTimeout(signOut,120000)
    }
    const StayActive=()=>{
        setModalIsOpen(false)
        clearTimeout(SessionTimeout.current)
    }

    const signOut=()=>{
        firebase.auth().signOut()
        clearTimeout(SessionTimeout.current)

    }
    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: IdleTimerRef,
        timeout: 60 * 3 * 1000,
        onIdle: Idle})
    return(
        <div  idleTimer={idleTimer}>
       
        </div>
    )
}

export default IdleTimeContainer
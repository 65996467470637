import React, { Fragment, useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";
import "../Registration.css"
import RegistrationPart3 from "./RegistrationPart3";
import Timer from "../Common Components/Timer";
import { OTPSms } from "../Common Components/SmsCommunication";
import Carousel from "react-material-ui-carousel";
//import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
import { StringToTime } from "../Common Components/CommonFunctions";

export default function RegistrationPart2(props) {
 
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(Date.parse(props.dob))
  const [address, setAddress] = useState(props.address)
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [otp, setOtp] = useState(-1)
  const [otpDisabled, setOtpDisabled] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [otpToBeChecked , setOtpToBeChecked] = useState("")
  const [otpEntered, setOtpEntered] = useState("")
  const [count, setCount] = useState(0)
  const [mobileVerified, setMobileVerified] = useState(false)
  const [aliasName, setAliasName] = useState(props.aliasname)
  const [pan, setPan] = useState(props.pan)
  const [aadhar, setAadhar] = useState(props.aadhar)
  const [spouseMobile, setSpouseMobile] = useState(props.spousemobile)
  const [otpButtonDisabled, setOtpButtonDisabled] = useState(true)
  const [doa, setDoa] = useState(props.doa)
  const [spouse, setSpouse] = useState(props.spouse);
  var max = 99999;
    var min = 10000
  
  useEffect(()=>{
    setOtp(Math.floor(Math.random() * (max - min) + min))
    setCount(0)
  },[count])

  useEffect(()=>{
    if(mobile.length == 10)
      setOtpButtonDisabled(false)
    else 
      setOtpButtonDisabled(true)
  },[mobile])

  const [showNextPart, setShowNextPart] = useState(false)
 
  
    function GetOtp(){
      setShowTimer(true)
    }
    function Reset(){
      setShowTimer(false)
    }
  function nextPart(){
      setShowNextPart(true)
  }
  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }

  useEffect(()=>{
 
    if( mobileVerified  && address.trim() !="" && (dob.toString().trim().length !=0 && dob != NaN && dob !="")){
        setButtonDisabled(false)
    } 
    else{
        setButtonDisabled(true)
    }
  },[ address, dob, mobileVerified])


  const otpGenerator = () =>{
    
    setCount(1)
    setShowTimer(true)
     
  try{
   // setOtpToBeChecked(otp)

   OTPSms(otp, membershipId, mobile)
       setOtpToBeChecked("123456")
  } catch(err){
      
    };

   
    
    // setShowOtpSentMessage(true)
  
    
    // setVerifyDisabled(true)
    // setShowErrorMessage(false)
    // setOtpDisabled(true)
  

  }
 

  function Verify(){
 
    if(otpEntered == otpToBeChecked){
      setMobileVerified(true)
    }else{
      window.alert("Wrong OTP Entered")
    }
  }
  return (
    <Fragment>
        {/* <LoginNavbar/>  
       <LoginPageNavbar/> */}
        {!showNextPart ? 
            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
          {!mobileVerified ?<>
               <div className="inputBx">
            
                 <span>Mobile No. <i> (Without +91) </i> </span>
                 <input type="text" value={mobile}  maxLength={10}disabled ={otpDisabled || showTimer } onChange={(e)=> setMobile(e.target.value)} /> 
                
               </div>
          

               <div className="inputBx">
               
               {showTimer ? "":<>  <input type="button" value="Get OTP"  disabled={otpButtonDisabled} style={{width:"50%"}} onClick={otpGenerator}/> </> }
               {showTimer ? <><input type="button" value={"Reset"} onClick={Reset}/><div style={{float:"right", width:"100%"}}><Timer otpgen={otpGenerator}/></div></>:""}
               </div>
              {showTimer ? 
               <div className="inputBx">
                 
                 <input type="text" value={otpEntered}  onChange={(e)=> setOtpEntered(e.target.value)} style={{width:"50%"}} placeholder="Enter OTP"/> 
                 <input type="button" value="Verify"  onClick={Verify} style={{width:"30%"}}/> 
               </div>:""}
</> : <><div className="inputBx">
  <span>Mobile</span>
                <input type ="text" value ={mobile} disabled={true}/>
  </div></> }
               <div className="inputBx">
                 <span>Address  </span>
                 <input type="text" value={address} autoComplete="off" onChange={(e)=> setAddress(e.target.value)}/> 
               </div>




               <div className="inputBx">
                 <span>Date of Birth</span><br/>
                 <DatePicker selected={dob}autoComplete="off" onChange={(date) => setDob(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown placeholderText="Enter DOB" dateFormat={"dd/MM/yyyy"}/>
               </div>

               <div className="inputBx">             
                 <input type="button" value="Next"  onClick={nextPart}  disabled={buttonDisabled}/> 
               </div>

            
           </div>
         </div>
       </section>

:<RegistrationPart3 married={props.married} spousedob={props.spousedob} salutation={props.salutation} name={name} gender={gender}  memstatus={props.memstatus} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} mobile={mobile} membershipId={membershipId} whatsapp={whatsapp}  address={address} dob={dateFormat(dob,"dd/mm/yyyy")} images={props.images} doa={doa} spouse={spouse}  spousemobile={spouseMobile}  pan={pan} aadhar={aadhar} aliasname={aliasName}  />}
         
    </Fragment>
  );
}

































import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import {Modal, Button} from "react-bootstrap"
import { MDBDataTable } from "mdbreact";
import { toTitleCase } from "../Common Components/CommonFunctions";
import firebase from "../Common Components/firebase";

function ViewMemberAffliations({ setOpenModal, userId}) {
    const [affliationData, setAffliationData] = useState([])

    useEffect(()=>{
        firebase.firestore().collection("users").doc(userId).collection("affliations").onSnapshot((querySnapshot)=>{
          let temp =[]
          querySnapshot.forEach((doc)=>{
            let x={}
            x['organizationname'] = toTitleCase( doc.data().organizationname)
            x['post'] = toTitleCase( doc.data().post)
            x['startdate'] = `${doc.data().starttimemonth} / ${doc.data().starttimeyear}`
            x['enddate'] = doc.data().tilltoday == "yes" ? "Till Today" : `${doc.data().endtimemonth} / ${doc.data().endtimeyear}`
            x['id'] =   doc.data().affliationid
            temp.push(x)
          })
          setAffliationData(temp)
        })
      },[])
      
 const data = {
    columns: [
       
        {
            label: 'Organization Name',
            field: 'organizationname',
            sort: 'asc',
            width: 150,
            
          },
          {
            label: 'Post Name',
            field: 'post',
            sort: 'asc',
            width: 150
          },   {
            label: 'Start Date',
            field: 'startdate',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'End Date',
            field: 'enddate',
            sort: 'asc',
            width: 150
          }
    ],
    rows: affliationData
  
  };

  return (
    
    <Modal
        show={true}
        onHide={setOpenModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Afflliations </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MDBDataTable striped data={data} scrollX small/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setOpenModal(false)}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

  );
}

export default  ViewMemberAffliations;
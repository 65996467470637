import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import { set } from "date-fns";
import { ExportCSVCommon } from "../Common Components/ExportCSVCommon";
import { Modal, Button } from "react-bootstrap";
import dateFormat from "dateformat";
import  DatePicker  from "react-datepicker";
import { Alert } from "@mui/material";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "../Common Components/CommonFunctions";

export default function AddNewRegDataModal({ setOpenModal,allNewRegData}) {
console.log(allNewRegData)
  const [whatsapp, setWhatsapp] = useState("")
  const [isAvailable, setIsavailable] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showMidErrorMessage, setShowMidErrorMessage] = useState(false)
  const [availableDisabled, setAvailableDisabled] = useState(true)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [mid, setMid] = useState("")
  const [name, setName] = useState("") 
  const [fathername, setFathername] = useState("");
  const [bloodGroup, setBloodGroup] = useState("")
  const [gender, setGender] = useState("")
  const [dob, setDob] = useState("")
  const [doa, setDoa] = useState("")
  const [address, setAddress] = useState("")
  const [spouse, setSpouse] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [salutation, setSalutation] = useState("")
  const [aliasName, setAliasName] = useState("")
  const [isMarried, setIsMarried] = useState(false)
  const [marriedDisplay, setMarriedDisplay] = useState(false)
  const [eligible, setEligible] = useState(false)
  const [spouseMobile, setSpouseMobile] = useState("")
  const [spouseDob, setSpouseDob] = useState("")
  const [aadhar, setAadhar] = useState("")
  const [pan, setPan] = useState("")
  const navigate= useNavigate()


  useEffect(()=>{
    if(whatsapp.trim().length ==10 && mid.trim() !="")
        setAvailableDisabled(false)
    else
        setAvailableDisabled(true)
  },[whatsapp, mid])

  useEffect(()=>{
    if(name.trim() !="")
      setSaveDisabled(false)
    else
      setSaveDisabled(true)
  },[name])
  useEffect(()=>{
    if(isMarried)
      setMarriedDisplay(true)
    else{
      setMarriedDisplay(false)
      setSpouse("")
      setDoa()
      setSpouseMobile("")

    }
  },[isMarried])
  function CheckEligible(value){
    if(value)
      return "yes"
    else
      return "no"
    
  }
  function CheckAvailability(){
    for(let i=0; i<allNewRegData.length ; i++){
        if(allNewRegData[i]['whatsapp'] == whatsapp){
            setShowErrorMessage(true)
            return
        }
        if(allNewRegData[i]['mid'] == mid){
          setShowMidErrorMessage(true)
          return
      }
    }
    setShowErrorMessage(false)
    setShowMidErrorMessage(false)
    setIsavailable(true)
  }

  function SaveDetails(){
    console.log("jjj")
    firebase.firestore().collection("newreg").doc(mid.trim()).set({
      address :address,
      bloodgroup:bloodGroup,
      dobirth:dateFormat(dob,"dd/mm/yyyy") ,
      doanniversary: dateFormat(doa,"dd/mm/yyyy"),
      email:email,
      name: name.toLowerCase(),
      gender:gender,
      fathername: toTitleCase(fathername),
      mid: mid,
      name:toTitleCase(name),
      mobile:mobile,
      spouse:toTitleCase(spouse),
      whatsapp:whatsapp,
      spousemobile: spouseMobile,
      eligible:CheckEligible(eligible),
      married : isMarried,
      memstatus : "active",
      salutation :salutation,
      spousedobirth : dateFormat(spouseDob,"dd/mm/yyyy"),
      pan :pan,
      aadhar:aadhar,
      registrationstatus :"n",
      aliasname :aliasName
    }).then(()=>{
      window.alert("New Member added to the App")
      window.location.reload()
    })
  }

  return (
    <Modal show={true}    onHide={setOpenModal}
    backdrop="static"
    keyboard={false}>
    <Modal.Header closeButton>
    <h1 style={{fontSize:"20px"}}>Add New Member</h1> 
    </Modal.Header>
    <Modal.Body>
        <div className="inputBx1">
            <span>Enter Whatsapp No.</span>
            <input type="text" value={whatsapp} maxLength={10} disabled={isAvailable} onChange={(e)=> setWhatsapp(e.target.value)}/>
        </div>
        <div className="inputBx1">
            <span>Enter MID</span>
            <input type="text" value={mid} maxLength={10} disabled={isAvailable} onChange={(e)=> setMid(e.target.value)}/>
        </div>
        {showErrorMessage&& (<Alert severity="error">Whatsapp No. already Exists </Alert>)}
        {showMidErrorMessage&& (<Alert severity="error">Membership ID is already taken </Alert>)}
        {isAvailable&& (<Alert severity="success">Whatsapp No. & Membership Id available for new user </Alert>)}
       {!isAvailable &&(<button className="buttons" onClick={CheckAvailability} disabled={availableDisabled}>Check Availability</button>)} 
       {isAvailable && (
        <>
        <div className="inputBx1">
            <span>Name</span>
            <input type="text" value={name} onChange={(e)=> setName(e.target.value)}/>
        </div>
        <div className="inputBx1">
                 <span>Salutation</span><br/>
                 <select value={salutation} onChange={(e)=> setSalutation(e.target.value)} >
                  <option value={""}>Choose</option>
                  <option value={"Mr."} >Mr. </option>
                  <option value={"Mrs."} >Mrs. </option>
                  <option value={"Ms."} >Ms. </option>
                  <option value={"Dr."} >Dr. </option>
                  <option value={"CA"} >CA </option>
                </select> 
                
        </div>

        <div className="inputBx1">
                 <span>Father's / Husband's Name</span>
                 <input type="text" value={fathername} autoComplete="off" onChange={(e)=> setFathername(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Alias Name (The Name you often called with)</span>
                 <input type="text" value={aliasName} autoComplete="off" onChange={(e)=> setAliasName(e.target.value)}/> 
               </div>

               <div className="inputBx1">
                 <span>Gender</span><br/>
                 <select value={gender} onChange={(e)=> setGender(e.target.value)} >         
                 <option value="">Choose</option>
                          <option value="M">Male</option>    
                         <option value="F">Female</option>  
                         <option value="O">Others</option>           
                   </select>  
               </div>
               <div className="inputBx1">
                 <span>Email  </span>
                 <input type="text" value={email} autoComplete="off" onChange={(e)=> setEmail(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Mobile </span>
                 <input type="text" value={mobile} autoComplete="off" onChange={(e)=> setMobile(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Address  </span>
                 <input type="text" value={address} autoComplete="off" onChange={(e)=> setAddress(e.target.value)}/> 
               </div>




               <div className="inputBx1">
                 <span>Date of Birth</span><br/>
                 <DatePicker selected={dob}autoComplete="off" onChange={(date) => setDob(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown placeholderText="Enter DOB" dateFormat={"dd/MM/yyyy"}/>
               </div>

               <div className="inputBx1">
                   <label style={{ fontSize: "20px"}} > Is he/she Married?
     </label>  <label style={{marginLeft:"15px", fontSize: "20px", color:"#4D3A78"}} > No
       </label>    <Switch
      checked={isMarried}
      onClick={()=> setIsMarried(!isMarried)}
      color="primary"
      style={{ fontSize: "20px"}}
    />
    <label style={{ fontSize: "20px", color:"#4D3A78"}} > yes
      </label>
    <br/>
               </div>
               {marriedDisplay ?<> 
              
               <div className="inputBx1">
                 <span>Date of Anniversary</span><br/>
                 <DatePicker selected={doa} onChange={(date) => setDoa(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown dateFormat={'dd/MM/yyyy'}/>
               </div>
               <div className="inputBx1">
                 <span>Spouse's Name</span>
                 <input type="text" value={spouse} autoComplete="off" onChange={(e)=> setSpouse(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Spouse's Mobile</span>
                 <input type="text" value={spouseMobile} autoComplete="off" onChange={(e)=> setSpouseMobile(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Spouse's DOB</span><br/>
                 <DatePicker selected={spouseDob} onChange={(date) => setSpouseDob(date)} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown dateFormat={'dd/MM/yyyy'}/>
               </div>
            

              

               </>:""}
               <div className="inputBx1">
<span>Blood Group</span><br/>
<select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} >         
<option value="">Choose</option>
<option value="A+">A+</option>    
<option value="A-">A-</option>          
<option value="B+">B+</option>   
<option value="B-">B-</option>                 
<option value="A+">O+</option>    
<option value="O-">O-</option>    
<option value="AB+">AB+</option>            
  </select>  
</div>


<div className="inputBx1">
<span>Eligible for Blood Donation ?</span><br/>
<label style={{marginLeft:"15px", fontSize: "20px", color:"#4D3A78"}} > No
</label>
<Switch
checked={eligible}
onClick={()=> setEligible(!eligible)}
color="primary"
style={{ fontSize: "20px"}}
/>
<label style={{ fontSize: "20px", color:"#4D3A78"}} > yes
</label>
</div>

               <div className="inputBx1">
                 <span>Aadhar  </span>
                 <input type="text" value={aadhar} autoComplete="off" maxLength={12} onChange={(e)=> setAadhar(e.target.value)}/> 
               </div>
               <div className="inputBx1">
                 <span>Pan  </span>
                 <input type="text" value={pan} autoComplete="off" onChange={(e)=> setPan(e.target.value)}/> 
               </div>
        </>
       )}
        
      </Modal.Body>
      <Modal.Footer>
              {isAvailable && (<Button variant="primary" disabled={saveDisabled} onClick={SaveDetails}>
                Save
              </Button>)}
              <Button variant="secondary" onClick={()=> setOpenModal(false)}>
                Close
              </Button>
    
            </Modal.Footer>
      </Modal>
   
  );
}


import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "../Common Components/firebase";
import { Navbar } from "../Navbar/Navbar"
import { AuthContext } from "../auth/Auth";

import "react-datepicker/dist/react-datepicker.css";

import Sidebar from "../Navbar/SideMenuBar";
import LoaderUi from "../Common Components/LoaderUi";
import { LoginNavbar } from "../Navbar/LoginNavbar";
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import { toTitleCase } from "../Common Components/CommonFunctions";



export default function BloodBankFamily() {
    const relationArray = ["Father", "Mother", "Brother","Sister","Wife", "Son", "Daughter"]
    const [relation, setRelation] = useState('')
    const [name, setName] = useState("")
    const [oname, setOname] = useState("")
    const [oemail, setOemail] = useState("")
    const [oaddress, setOaddress] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [mobile, setMobile] = useState("")
    const [bloodGroup, setBloodGroup] = useState("")
    const [showDeleteButton, setShowDeleteButton] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)

const {currentUser} = useContext(AuthContext)
const userID = currentUser.uid
// function CheckBusiness (){
//   firebase.firestore().collection("users").doc(userID).collection("business").limit(1).get().then((query)=>{
//   if(query.size==0)
//     setModalOpen(true)
//   });
    
// }

// useEffect(()=>{
//  CheckBusiness()
// },[])
    function getUserData(){
        const ref = firebase.firestore().collection("users").doc(userID)
        ref.get().then((doc)=>{
            setOname(doc.data().name)
            setOemail(doc.data().email)
            setOaddress(doc.data().address)
          
        })
    }


  function getData(r){
    let x = userID + r
    const ref = firebase.firestore().collection('BloodBankFamily').doc(x)
    ref
    .get()
    .then((doc) => {

      if (doc.exists) {
        
      setName(doc.data().name)
      setMobile(doc.data().phone)
      setRelation(doc.data().relation)
      setBloodGroup(doc.data().bloodgroup)
      setWhatsapp(doc.data().whatsapp)
      setShowDeleteButton(true)            
    } else {
        setName("")
        setMobile("")
        setBloodGroup("NA")
        setShowDeleteButton(false)
      }
    })
    .catch((error) => {});
   
  }

  useEffect(()=>{
    getUserData()
  },[])

  useEffect(()=>{
    if(relation !="")
        getData(relation)
    else{
      setBloodGroup("NA")
      setName("")
      setMobile("")
      setShowDeleteButton(false)
    }
  },[relation])

 useEffect(()=>{
   if(relation != "" && name.trim() != "" && bloodGroup != "NA" && mobile.length == 10 &&  whatsapp.length == 10)
     setButtonDisabled(false)
    else
      setButtonDisabled(true)
   
 },[relation, bloodGroup, mobile, name.length, whatsapp])
 

  
  function checkMobile(value){
      if(value.length>10)
        return value.slice(0,10)
      else
        return value
  }

  const UpdateDetails =() =>{
    const db = firebase.firestore();
    let y = (new Date()).getTime().toString()
    let x = userID + relation+y
    const users = db.collection("BloodBankFamily").doc(x);

        users
          .set({
              relation: relation,
              name:name,
              phone: mobile,
              oname:oname,
              oemail : oemail,
              oaddress : oaddress,
              reg: (new Date()).toString(),
              bloodgroup : bloodGroup,
              whatsapp : whatsapp,
              userid: userID,
              image:""


          })
          .then(function () {
            window.alert("Data Saved successfully")
            setName("")
            setMobile("")
            setBloodGroup("NA")
            setRelation("")
            

          })
          .catch(function (error) {
            window.alert(error);
          });     
  }
 
  const DeleteDetails =() =>{
    const db = firebase.firestore();
  
    let x = userID + relation 
    const users = db.collection("BloodBankFamily").doc(x);

        users.delete().then(function () {
            window.alert("Data Deleted successfully")
            setName("")
            setMobile("")
            setBloodGroup("NA")
            setRelation("")
            

          })
          .catch(function (error) {
            window.alert(error);
          });     
  }
 

  return (
    <Fragment>
   <>
   <IdleTimeContainer />
      {/* <LoginNavbar */}
    <Navbar/>
      <Sidebar />
      <div style={{ paddingTop: "120px", zIndex: 0 }}></div>

      

      <div
        className="d-flex justify-content-center"
        
      >
        <div className="d-flex p-6 col-example"> </div>
      </div>

      <div
        className="d-flex justify-content-center"
      
      >
        <div className="d-flex p-6 col-example">
          <h1>Family Blood Group Enrollment</h1>
        </div>
      </div>
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          <div className="p-2 flex-fill bd-highlight col-example form-group">

          <label style={{ fontSize: "30px"}}> Select Relation <span style={{color:"#4D3A78"}}>*</span> </label><br></br>
            <select value={relation} onChange={(e)=> setRelation(e.target.value)} style={{width:"50%", height:"40px",borderRadius:"5px", marginTop:"1px"}}>
              <option value=""> Choose </option>
              {relationArray.map((doc, key)=>(
                  <option value={doc} key={key}> {doc} </option>
              ))}
            </select> 
                  <br/>
          <label style={{ fontSize: "30px" }}>  Name <span style={{color:"#4D3A78"}}>*</span> </label>
            <input
              type="text"
              className="form-control"
              placeholder="Person Name"
              style={{ width: "100%" }}
               value={toTitleCase (name)}
               onChange={(e) => setName(e.target.value)}
            />

            
          <label style={{ fontSize: "30px" }}>Mobile No.<span style={{color:"#4D3A78"}}>*</span> </label>
            <input
              type="text"
              className="form-control"
              placeholder="Mobile"
              style={{ width: "100%" }}
               value={mobile}
               onChange={(e)=> setMobile(checkMobile(e.target.value))}
               maxLength="10"
              
            />
              <label style={{ fontSize: "30px" }}>Whatsapp<span style={{color:"#4D3A78"}}>*</span> </label>
            <input
              type="text"
              className="form-control"
              placeholder="Whatsapp"
              style={{ width: "100%" }}
               value={whatsapp}
               onChange={(e)=> setWhatsapp(checkMobile(e.target.value))}
               maxLength="10"
              
            />

            <label style={{ fontSize: "30px"}}>Blood Group <span style={{color:"#4D3A78"}}>*</span> </label><br></br>
            <select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} style={{width:"100px"}}>
              <option value="NA">NA</option>
              <option value="A+">A+</option>    
              <option value="A-">A-</option>   
              <option value="B+">B+</option>   
              <option value="B-">B-</option>                 
              <option value="O+">O+</option>    
              <option value="O-">O-</option>    
              <option value="AB+">AB+</option>    
            </select> 
            <br></br> <br/>

            
            <button
             disabled={buttonDisabled}
              onClick={()=>UpdateDetails()}
              className="btn btn-primary btn-block"
              style={{ width: "50%", backgroundColor: "#4D3A78" }}
            >
              Save Data
            </button> <br/> <br/>
          {showDeleteButton ? 
            <button
              onClick={()=>DeleteDetails()}
              className="btn btn-primary btn-block"
              style={{ width: "50%", backgroundColor: "#4D3A78" }}
            >
              Delete Data
            </button>:""}
          </div>

          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
         </>
      
    </Fragment>
  );
}

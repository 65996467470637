import React from "react";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../auth/Auth";
import firebase from "../Common Components/firebase"
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import { Navbar } from "../Navbar/Navbar";
import Sidebar from "../Navbar/SideMenuBar";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PersonIcon from '@mui/icons-material/Person';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import  Carousel from "react-material-ui-carousel";
import CategoryIcon from '@mui/icons-material/Category';
import { toTitleCase } from "../Common Components/CommonFunctions";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { MDBDataTable } from "mdbreact";
import LoaderUi from "../Common Components/LoaderUi";
import MyBusinessImageViewModal from "../Modal/MyBusinessImageModal";
import Footer from "../Footer/Footer";


export default function BusinessDetails(){

    const [business, setBusiness] = useState({})
    const [businessesArray, setBusinessesArray] = useState([])
    const [products, setProducts] = useState([])
    const [productsForRender, setProductsForRender] = useState([])
    const [loading, setLoading] = useState(true)
    const [productImageModal, setProductImageModal] = useState(false)
    const [productsImageArr, setProductsImageArr] = useState({})
    const [userId, setUserId] = useState("")
 
    const state = useLocation()
    let path = state.pathname;
    let arr = path.split("/");
    const businessId = arr[2]
 

    const {currentUser} = useContext(AuthContext)
    const uid = currentUser.uid

    function GetBusiness(){
      const ref =  firebase.firestore().collection('users')
     
      ref.onSnapshot((querySnapshot)=>{
        querySnapshot.forEach((usersdoc)=>{
            const ref1 =  ref.doc(usersdoc.id).collection('business')
            let temp =[]
            ref1.onSnapshot((querySnapshot1)=>{
                querySnapshot1.forEach((businessdoc)=>{
                  let x= businessdoc.data()
                  x['userid'] = usersdoc.id
                  temp.push(x)
                })
                setBusinessesArray(businesses=>[...businesses,...temp])
                setLoading(false)
            })
            

        })
      })

    
  }
    useEffect(()=>{
 
   
        let postsArray = JSON.parse(JSON.stringify(products));
        let userData = [];
        postsArray.map((item, index) => {
          item.image = (
           <img src={item['images'][0]} stle={{cursor:"pointer"}} width={100} style={{borderRadius:"50%"}} onClick={()=>{setProductsImageArr(item.images); setProductImageModal(true)}} />
          );
          userData.push(item);
        });
      setProductsForRender(userData);
      }, [products]);
    
    const data = {
        columns: [
          {
            label: 'Image',
            field: 'image',
            sort: 'asc',
            width: 150,
          },
           
            {
                label: 'Product Name',
                field: 'productname',
                sort: 'asc',
                width: 150,
                
              },
              {
                label: 'Description',
                field: 'productdesc',
                sort: 'asc',
                width: 150,
              },
              {
                label:'Price',
                field: 'productprice',
                sort: 'asc',
                width: 150
              },
             
        ],
        rows: productsForRender

      };


    useEffect(()=>{
        
      GetBusiness()
    },[])
useEffect(()=>{
 if(userId !=""){
  firebase.firestore().collection("users").doc(userId).collection("business").doc(businessId).collection("products").onSnapshot((querySnapshot)=>{
    let items =[]
    querySnapshot.forEach((doc)=>{
        items.push(doc.data())
    })

    setProducts(items)
    setLoading(false)
})
 }
    

 
},[userId])
 

    useEffect(()=>{
      if(businessId !=""){
  
        for(let i=0; i<businessesArray.length; i++){
          if(businessesArray[i]['id'] == businessId){
            console.log(businessesArray[i])
            setUserId(businessesArray[i]['userid'])
            setBusiness(businessesArray[i])
          }
        }
      }
    },[businessId,businessesArray])

    if(loading)
        return (<LoaderUi/>)
    return(<>
    <IdleTimeContainer/>
    <Sidebar/>
    <Navbar/>
    {productImageModal && (<MyBusinessImageViewModal setOpenModal={setProductImageModal} imageArr={productsImageArr}/>)}
    <div style={{paddingTop:"120px"}}/>
    <div className="sideMargin flex-wrapper">
    <div>
    <div className="box1">
    {business.images &&(
    <ImageList sx={{ width: "auto", height: 400, overflow: "scroll" }} variant="woven" cols={2} rowHeight={164}>
      {Object.values(business.images).map((item) => (
        <ImageListItem key={item}>
          <img
            src={`${item}?w=164&h=164&fit=fill&auto=format`}
            srcSet={`${item}?w=164&h=164&fit=fill&auto=format&dpr=2 2x`}
          
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>)}
    </div>
    <div className="box2">
    <h1>{business.bizname}</h1>    

<h3> <PersonIcon/> {business.bizowner}</h3>
<h4 style={{fontSize:"16px"}}><CategoryIcon/> {toTitleCase(business.biztype)}</h4>
<h4 style={{fontSize:"16px"}}><AssessmentIcon/> {toTitleCase(business.bizsubtype)}</h4>

<hr/>
 <span onClick={()=> window.location.href=`mailTo:${business.bizemail}`} style={{cursor:"pointer"}}> <EmailIcon/> {business.bizemail} </span><br/>
 <span onClick={()=> window.location.href=`tel:${business.bizmobile}`} style={{cursor:"pointer"}}> <PhoneInTalkRoundedIcon/> {business.bizmobile} </span> <br/>
 <span onClick={()=> window.location.href=`https://wa.me/91${business.bizwhatsapp}`} style={{cursor:"pointer"}}> <WhatsAppIcon/> {business.bizwhatsapp} </span> <br/>
 <span onClick={()=> window.location.href=`https://www.google.com/maps/dir//${business.bizaddress}`} style={{cursor:"pointer"}}> <LocationOnRoundedIcon/> {business.bizaddress} </span> 


    </div>
    </div>

   <h1>Product Details</h1>
   
 <MDBDataTable data={data} striped small scrollX />

    </div>
    <div style={{flexGrow:1}}></div>
          <Footer/>
    </>)
}
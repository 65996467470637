import React, { Fragment, useEffect, useState, useContext } from "react";
import "../Registration.css"
import RegistrationPart1 from "./RegistrationPart1";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import dateFormat from "dateformat"
import Carousel from "react-material-ui-carousel";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
import axios from "axios";
import firebase from "../Common Components/firebase";

export default function Registration(props) {


  const [confirmPassword, setConfirmPassword] = useState("")

  const[showConfirmPassword, setShowConfirmPassword] = useState(false) 
  const [password, setPassword] = useState("");
   const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("Please Enter a valid Email Address")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("Password doesn't match")
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false)
  const [showNextPart, setShowNextPart] = useState(false)
  const [pan, setPan] = useState(props.pan)
  const [aadhar, setAadhar] = useState(props.aadhar)
  const [aliasName, setAliasName] = useState(props.aliasname)
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState(props.doa)
  const [address, setAddress] = useState(props.address)
  const [spouse, setSpouse] = useState(props.spouse);
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  const [mobile, setMobile] = useState(props.mobile);
  const [marriedDisplay, setMarriedDisplay] = useState(props.marriedDisplay)
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")
  const [eligible, setEligible] = useState(false)
  const [spouseMobile, setSpouseMobile] = useState(props.spousemobile)
  const [children, setChildren] = useState(props.children)  

 
  useEffect(()=>{
    if(email.trim() != "" && password.trim() !="" && confirmPassword.trim() !="" && !showPasswordErrorMessage && !showEmailError){

      setButtonDisabled(false)
    }
    else
      setButtonDisabled(true)

  },[password, confirmPassword, showPasswordErrorMessage, email])
    

  useEffect(()=>{
    if(password.trim() != "" && confirmPassword.trim() !="")
    {
      if(password != confirmPassword)
        setShowPasswordErrorMessage(true)
      else
        setShowPasswordErrorMessage(false)
    }
    else{
      setShowPasswordErrorMessage(false)
    }
  },[password, confirmPassword])
  const ValidateEmail = (value) =>{
    if (value !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
      if (!pattern.test(value)) {
    
        setShowEmailError(true)
        
    
       
    
      } else {
        setShowEmailError(false)
      }
    
    }
  }

  function nextPart(e){
  //  axios.get(`https://us-central1-mymmuz-nearby-f58e2.cloudfunctions.net/verifyEmail?email=${email.trim()}`).then((res)=>{
  //   if(res['data']){
  //     window.alert("This email is already in use !!")
      
  //   }else{
    setShowNextPart(true)

  //   }

  //  }).catch((err)=>{})  
  //  e.preventDefault()
  // firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim())
  //        .then((userCredential) => {
  //        // Signed in 
  //        var user = userCredential.user;
       
       
  //        userCredential.user.sendEmailVerification()
  //         console.log(user.uid)
        
  //      }).catch((error)=> window.alert("Some Error Occured\nPlease Try Again !!"+ error), window.location.reload())

  }

  return (
    <Fragment>
      {/* <LoginNavbar/>  
       <LoginPageNavbar/> */}
      {!showNextPart ? 
            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               <div className="inputBx">
                 <span>Email</span>
                 <input type="text" autoComplete="off"value={email} onChange={(e)=> {setEmail(e.target.value);ValidateEmail(e.target.value)}} /> 
                 {showEmailError ?<p style={{color:"#4D3A78"}}> {emailErrorMessage} </p> : ""}
               </div>
               <div className="inputBx">
                 <span>Password</span>
                 <input type={!showPassword ?"password":"text"} autoComplete="off" value={password}  onChange={(e)=> setPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" onChange={()=> setShowPassword(!showPassword)}  /> Show Password</label>
               </div>

               <div className="inputBx">
                 <span>Confirm Password</span>
                 <input type={!showConfirmPassword ?"password":"text"}value={confirmPassword} autoComplete="off" onChange={(e)=> setConfirmPassword(e.target.value)}/> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" name="" onChange={()=> setShowConfirmPassword(!showConfirmPassword)}  /> Show Password</label>
                 {showPasswordErrorMessage ?<p style={{color:"#4D3A78"}}> {passwordErrorMessage} </p> : ""}
               </div>


               <div className="inputBx">             
               <input type="button" value="Next"  disabled={buttonDisabled} onClick={nextPart} /> 
               </div>


            
           </div>
         </div>
       </section>
:<RegistrationPart1 married={props.married} name={name} gender={gender} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} mobile={mobile} membershipId={membershipId} eligible={eligible} whatsapp={whatsapp}  address={address} dob={dob} doa={doa} spouse={spouse} children={children}  spousemobile={spouseMobile}   marriedDisplay={marriedDisplay}  pan={pan} aadhar={aadhar} aliasname={aliasName} memstatus={props.memstatus} spousedob={props.spousedob}  images={props.images}/>}

         
    </Fragment>
  );
}


import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import IdleTimeContainer from "../Common Components/IdleTimeContainer";
import firebase from "../Common Components/firebase.js";
import { Fragment } from "react";
import { Navbar } from "../Navbar/Navbar";
import "../card.css";
import { AuthContext } from "../auth/Auth";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TitleCase from 'react-title-case';
import LoaderUi from "../Common Components/LoaderUi";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import BirthdayPanel from "../Panels/BirthdayPanel";
import AnniversaryPanel from "../Panels/AnniversaryPanel";

import { placeholderManImage } from "../Common Components/CommonFunctions";

export default function Memberdetails() {
  const state = useLocation();
  let path = state.pathname;
  let arr = path.split("/");
  let mid = arr[2];

  const [schools, setschools1] = useState([]);
  const [loading, setloading1] = useState([false]);
  const [currentUserType,setCurrentUserType] = useState("")
  const [dob, setDob] = useState("")
  const [doa, setDoa] = useState("")
  const [birthDate, setBirthDate] = useState();
  const [birthMonth, setBirthMonth] = useState();
  const [birthYear, setBirthYear] = useState();
  const [annDate, setAnnDate] = useState();
  const [annMonth, setAnnMonth] = useState();
  const [annYear, setAnnYear] = useState();
  const [isAdmin, setIsAdmin] = useState(false)
  const [uid, setUid] = useState("")
  const [modalOpen, setModalOpen] = useState(false)

  const { currentUser } = useContext(AuthContext);

  const currentUserId = currentUser.uid;

  function CheckBusiness (){
    firebase.firestore().collection("users").doc(currentUserId).collection("business").limit(1).get().then((query)=>{
    if(query.size==0)
      setModalOpen(true)
    });
    	
  }

  useEffect(()=>{
   CheckBusiness()
  },[])

  const ref1 = firebase.firestore().collection("users").doc(currentUserId);


  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
         
          setCurrentUserType(doc.data().type)
       
       }
       
       else {
        }
      })
      .catch((error) => {});
     
    
  }, []);


  const ref = firebase.firestore().collection("users");
  
  function getSchools() {
    
    setloading1(true);

    ref.onSnapshot((querySnapshot) => {
      const items = [];
      

      querySnapshot.forEach((doc) => {
      
        if (doc.data().mid === mid){ 
          setUid(doc.id)
          items.push(doc.data());
          setDob(doc.data().dobirth);
          setDoa(doc.data().doanniversary);

        }
      });
      setschools1(items);
      setloading1(false);
    });

    
  }


  useEffect(()=>{
    const dobarr = dob.split("/")
   
    const month =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"]

    setBirthDate(dobarr[0])
    setBirthYear(dobarr[2])
    setBirthMonth(month[dobarr[1]-1])

    
  },[dob])


  
  useEffect(()=>{
    const doaarr = doa.split("/")
   
    const month =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"]

    setAnnDate(doaarr[0])
    setAnnYear(doaarr[2])
    setAnnMonth(month[doaarr[1]-1])

    
  },[doa])

  useEffect(()=>{

    if(currentUserType == "admin")
      setIsAdmin(true)


  },[currentUserType])

  useEffect(() => {
    getSchools();
   
  }, []);


  if (loading) {
    return (<LoaderUi/>);
  }

  return (
    <Fragment>
      <IdleTimeContainer/>
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
      <BirthdayPanel/>
      <AnniversaryPanel/>
      <div style={{ paddingTop: "120px" }}></div>
      <div
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
        className="justify-content-center "
      >
        <div className="d-flex bd-highlight example-parent">
          <div className="p-2 flex-fill bd-highlight col-example"></div>

          {schools.map((school) => (
            <div key={school.userid}>
              <div className="card2 ">
                <div className="upper-container">
                  <div className="image-container1">
                    <img
                      src={
                        school.image
                      }
                      onError={(e) => {
                        e.target.src = placeholderManImage
                        e.target.onError = null;
                      }}
                  
                      width="200px"
                  
                    ></img>
                  </div>
                </div>
                <div className="lower-container">
                  <h3> <TitleCase string={`${school.name}`} /></h3>
                  <h4>{school.mid}</h4>
                  <h3 style={{ color: "yellowgreen" }}>Personal Details</h3>
                  <p>Date of Birth : {" "}{birthDate}-{birthMonth}  {isAdmin? -birthYear:""} </p>
                  <p>Blood Group: {school.bloodgroup}</p>
                  <h3 style={{ color: "yellowgreen" }}>Contact Details</h3>
                  <p> Address: {school.address}</p>
                  <p>Email: {school.email}</p>
                  <p>Phone: {school.phone}</p>
                  <p>Whatsapp: {school.whatsapp}</p>
                  
                  {school.married == "yes" ? <>
                  <h3 style={{ color: "yellowgreen" }}>Marriage  Details</h3>
                  <p>Date of Anniversary : {" "}{annDate}-{annMonth}  {isAdmin? -annYear:""} </p>
                  <p>Spouse: {school.spousename} </p>
                  <p> No. of Children : {school.noofchildren} </p>
                  {school.spousemob != "" ? <p> Spouse Mobile  : {school.spousemob} </p>: ""}
                  {school.spouseemail != "" ? <p> Spouse Email  : {school.spouseemail} </p>: ""}
                  </> :""}
                  <h3 style={{ color: "yellowgreen" }}>Operations</h3>

                  <IconButton className="IconButton" href={"mailto:" + school.email}>
                    <EmailIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton className="IconButton" href={"tel:" + school.phone}>
                    {" "}
                    <PhoneInTalkRoundedIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton className="IconButton"
                    href={"https://www.google.com/maps/dir// " + school.address}
                  >
                    {" "}
                    <LocationOnRoundedIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                  <IconButton className="IconButton" href={"https://wa.me/+91" + school.whatsapp}>
                    <WhatsAppIcon style={{ fontSize: 50 }}></WhatsAppIcon>
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
          <div className="p-2 flex-fill bd-highlight col-example"></div>
        </div>
      </div>
    </Fragment>
  );
}


 import React, { Fragment, useEffect, useState, useContext } from "react";
 import firebase from "../Common Components/firebase";
 import "react-datepicker/dist/react-datepicker.css";
 import "../RegistrationBusiness.css"
 import MultiImageInput from 'react-multiple-image-input';
// import { useHistory } from 'react-router-dom';
 import Carousel from "react-material-ui-carousel";
 import { createUserWithEmailAndPassword} from "firebase/auth"
 import LoaderUi from "../Common Components/LoaderUi";
 //import { LoginNavbar } from "../Navbar/LoginNavbar";
 import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
 import {auth} from '../Common Components/firebase'
import { useNavigate } from "react-router-dom";

 export default function RegistrationPart5(props) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
   const[membershipId, setMid] = useState(props.membershipId)
   const [name, setName] = useState(props.name);
   const [fathername, setFathername] = useState(props.fathername);
   const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
   const [gender, setGender] = useState(props.gender)
   const [dob, setDob] = useState(props.dob)
   const [doa, setDoa] = useState(props.doa)
   const [address, setAddress] = useState(props.address)
   const [spouse, setSpouse] = useState(props.spouse);
   const [whatsapp, setWhatsapp] = useState(props.whatsapp);
   const [email, setEmail] = useState(props.email);
   const [password, setPassword] = useState(props.password);
   const [mobile, setMobile] = useState(props.mobile);
   const [buttonDisabled, setButtonDisabled] = useState(true)
   const [married, setMarried] = useState(props.married)
   const [pan, setPan] = useState(props.pan)
   const  [aadhar, setAadhar] = useState(props.aadhar)
   const [showMobileError, setShowMobileError] = useState(false)
   const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")
   const [images, setImages] = useState({})
   const [eligible, setEligible] = useState(false)
   const [acknowledgmentChecked, setAcknowledgementChecked] = useState(false)
   const [aliasName, setAliasName] = useState(props.aliasname)
  // const history = useHistory()
   
 
 
   function handleSubmit  (e) {
    
    setLoading(true)
     e.preventDefault()
      
     firebase.auth().createUserWithEmailAndPassword(email,password)
         .then((userCredential) => {
         // Signed in 
         var user = userCredential.user;
       
       
        userCredential.user.sendEmailVerification()

          UpdateDetails(user.uid);
        
       })
     
 
   }

   useEffect(()=>{
    if(Object.values(images).length == 1 && acknowledgmentChecked ){
      setButtonDisabled(false)
    }
    else{
      setButtonDisabled(true)
    }
   },[acknowledgmentChecked, images])
 
   function CheckEligible(value){
     if(value)
       return "yes"
     else
       return "no"
     
   }
   
   function checkMobile(value){
       if(value.length>10)
         return value.slice(0,10)
       else
         return value
   }
 
  
 
   const UpdateDetails =(userID) =>{
 
     const db = firebase.firestore();
     const users = db.collection("users").doc(userID);
 
         users
           .set({
             address :address.toLowerCase(),
             bloodgroup:bloodGroup,
             dobirth:dob,
             doanniversary:doa,
             email:email,
             name: name.toLowerCase(),
             gender:gender,
             image:"",
             fathersname: fathername.toLowerCase(),
             mid: membershipId,
             name:name.toLowerCase(),
             password:password,
             phone:mobile,
             spouse:spouse.toLowerCase(),
             userid:userID,
             whatsapp:whatsapp,
             spousemobile: props.spousemobile,
             eligible:CheckEligible(eligible),
             married : married,
             memtype:"general",
             type :"general",
             memstatus : props.memstatus,
             salutation :props.salutation,
             spousedobirth : props.spousedob,
             pan : pan,
             aadhar :aadhar,
             aliasname : aliasName
             
           })
           .then(function () {
           
             UploadImage(userID)
          
           })
           .catch(function (error) {
             window.alert("Some Error Occured\nPlease try again  !!");
             window.location.reload()
             
           });     
   }
 
   function UploadImage(userID){
     let arr = Object.values(images)

   
     let arr1 = arr[0].split(",")
     const ref =  firebase.storage().ref(`users/${userID}/`).child(`profile.jpg`)
     const userRef = firebase.firestore().collection("users").doc(userID)
     let arr2 = arr1[1]
 
     ref.putString(arr2, 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
       snapshot.ref.getDownloadURL().then((url)=>{
         
         userRef.update({
           image : url,
         }).then(()=>{
           UpdateReg()
          
         }).catch(()=>{
           window.alert("Some Error Occured ! \n Please Try Again !!")
           window.location.reload()

         })
        
   
       })
   }).catch((error)=>{
     window.alert("Some Error Occured ! \n Please Try Again !!"+error)
     window.location.reload()

   })
 }
 
   const UpdateReg=()=>{
     const users = firebase.firestore().collection("newreg").doc(membershipId);
     //let map = new Map([mid,"r"])
    users.update({
     registrationstatus :"r"
    }).then(function(){
      window.alert("Registration Successfull !!")
      navigate("/")
    }).catch(function(error){
      window.alert("Some Error Occured ! \n Please Try Again  !!")
      window.location.reload()

    })
   }
 
   if(loading){
     return (<LoaderUi/>)
   }
   
   return (
     <Fragment>
  
  {/* <LoginNavbar/>  
    <LoginPageNavbar/> */}
 <section>
 <div className="imgBx">
 <Carousel className="carousel1" indicators={false} autoPlay={true}>
             {Object.values(props.images).map((doc)=>(
             <> <img src ={doc} /></> 
             ))}
 </Carousel>
 </div>
 <div className="contentBx">
 <div className="formBx">
  <h2>Registration</h2>
  
 
    <div className="inputBx">
    <span>Profile Pic</span>
    <MultiImageInput
       images={images}
       setImages={setImages}
      
       max={1}
       theme={{
         background: '#ffffff',
         outlineColor: '#111111',
         textColor: 'rgba(255,255,255,0.6)',
         buttonColor: '#ff0e1f',
    
         
         modalColor: '#ffffff'}}
       allowCrop={false}
    
     />
     </div>
     <div className="inputBx">
       <input type={"checkbox"} checked={acknowledgmentChecked} onChange={()=> setAcknowledgementChecked(!acknowledgmentChecked)} style={{display:"inline", width:"10%"}} /> <span style={{display:"inline"}}>I confirm that details provided by me is correct and best to my knowledge</span>
     </div>
    <div className="inputBx">             
    <input type="button" value="Next"  onClick={(e=> handleSubmit(e))}  disabled={buttonDisabled}/> 
    </div>
 
 
 </div>
 </div>
 </section>
 
 
          
     </Fragment>
   );
 }
 
 
import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow, MDBDataTable} from "mdbreact";
import { IconButton } from '@mui/material';
import { Navbar } from "../Navbar/Navbar";
import "../MemberDirectory.css";
import "../Modal.css";
import { AuthContext } from "../auth/Auth";
import SideMenuBar from "../Navbar/SideMenuBar"
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { Add, AddAPhoto, DeleteForever, Edit, EditAttributesOutlined, EditLocationOutlined, EditOutlined } from '@mui/icons-material';
//import { useHistory } from 'react-router-dom';
import UpdateProductModal from "../Modal/UpdateProductModal"
import EditProductsModal from '../Modal/EditProductsModal';
import { Modal } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function MyProductsDisplayModal({setOpenModal, businessID}) {

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [editProductsModalOpen, setEditProductsModalOpen] = useState(false)
  const [bizName, setBizname] = useState("")
  const [productid, setProductid] = useState("")
  const [displayProductArr, setDisplayProductArr] = useState([])
  
  
  let downloadUrl = []
  let url ={}
  const [downloadUrlArray, setDownloadUrlArray] = useState([])
  let x=""
 // const history = useHistory()
  const {currentUser} = useContext(AuthContext);
  const uid = currentUser.uid

  const ref1 = firebase.firestore().collection('users').doc(uid)
 


let  ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID).collection("products");

function ValidBusiness(){
  const ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID)
  ref.get().then((doc)=>{

      if(!doc.exists){
          window.alert("Business Does not Exists !!")
          // history.push("/my-profession")
      }
      else{
            setBizname(doc.data().bizname)
      }
  })
}

useEffect(()=>{
  ValidBusiness()
},[])
 
 function getDownloadURLArray(mid,url){
   let temp =[]
   const storageref = firebase.storage().ref().child(url)
   storageref.getDownloadURL().then(onResolve, onReject)

   function onResolve(foundUrl){
    let x ={}
  
     x[mid] = foundUrl
  
     downloadUrl.push(x)   
     setDownloadUrlArray(downloadUrl)
    

   }

   function onReject(err){
    let x ={}
     x[mid] =""

   }

 }


 function getSchools(){

   setloading(true);
   const items=[];
   ref.onSnapshot((querySnapshot)=>{
   
     querySnapshot.forEach((doc)=>{
          
           items.push(doc.data());
     });
     setschools(items);
     setloading(false);
    
   })
 }  
 
 function getDownloadUrlJson(){
   let tempKeys =[]
   let tempValues= []
   
  for (let i=0; i<downloadUrlArray.length; i++){
    
    for(let value of Object.values(downloadUrlArray[i])){
      tempValues.push(value)
    }

    for(let value of Object.keys(downloadUrlArray[i])){
      tempKeys.push(value)
   }

   


  }
  for (let i=0; i<tempKeys.length; i++){
    url[tempKeys[i]] = tempValues[i]
  }
 
  x=JSON.stringify(url)
 
 }
 useEffect(()=>{
   getSchools();
 },[]);

 function AddNewProducts(){
     if(schools.length >= 10){
         window.alert("Only 10 Products are allowed")
     }
     else{
         setProductModalOpen(true)
     }
 }
 
 function EditProducts(id){
   setProductid(id)
   setEditProductsModalOpen(true)
 }
 function DeleteProducts(id){
     let x = window.confirm("Are You Sure ? \n This action can't be undone!!")
     if(x){
       setloading(true)
     const ref = firebase.firestore().collection("users").doc(uid).collection("business").doc(businessID).collection("products").doc(id)
     const storageRef =  firebase.storage().ref(`business/${uid}/${id}/products/${id}`)
     
     storageRef.listAll().then((listResults) => {
       const promises = listResults.items.map((item) => {
         return item.delete();
       });
       Promise.all(promises);
     });
   
     ref.delete().then(()=>{
       window.alert("Product Deleted !!")
       window.location.reload()
   })
    
    }
 }

 useEffect(()=>{
 
  let postsArray = JSON.parse(JSON.stringify(schools));
  let userData = [];
  postsArray.map((item, index) => {
    item.serialno =(<>
      <b>{index+1}.</b>
    </>)
//     item.viewImage=(
//       // <center>
//       // <PreviewIcon style={{ cursor:"pointer"}} onClick={()=>{setViewBusinessImageModalOpen(true); setViewBusinessImageObj(item.images)}}/>
//       // </center>
//     )

//     item.products=(
//       <center>
//       <ShoppingBasketOutlined  style={{ cursor:"pointer"}} onClick={()=> MyProducts(item.id) }/>
//       </center>
//     )
    
    item.action = (
        <>

        <EditIcon  style={{color:"green", marginLeft:"2px", cursor:"pointer"}}onClick={()=>{setEditProductsModalOpen(true); setProductid(item.id)}} />
      <DeleteIcon style={{color:"red", marginLeft:"2px", cursor:"pointer"}} onClick={()=>DeleteProducts(item.id)}/>

</>
   );

    userData.push(item);
  });
  setDisplayProductArr(userData);
}, [schools]);
 if(loading){
   return (<LoaderUi/>)
 }


 const data ={
  columns: [

    {
    label: 'Sl No.',
    field: 'serialno',
    sort: 'asc',
    width: 50,
    
  },
  {
    label: 'Product Name',
    field: 'productname',
    sort: 'asc',
    width: 150,
    
  },
  {
    label: 'Product Price',
    field: 'productprice',
    sort: 'asc',
    width: 150,
    
  },
  {
    label: 'Product Description',
    field: 'productdesc',
    sort: 'asc',
    width: 150,
    
  },
  
  {
    label: 'Action',
    field: 'action',
    sort: 'asc',
    width: 150,
    
  }
],

rows : displayProductArr

 }





return (
<Modal show={true} onHide={setOpenModal}
        backdrop="static"
        keyboard={false}  > 
    <Modal.Header closeButton><h1 style={{fontSize:"20px"}}>Products</h1></Modal.Header>
   <Modal.Body>
      {productModalOpen && (<UpdateProductModal setOpenModal={setProductModalOpen} businessId={businessID} businessName={bizName} userID={uid}/>)}
      {editProductsModalOpen && (<EditProductsModal setOpenModal={setEditProductsModalOpen} businessId={businessID} businessName={bizName} userID={uid} productid={productid}/>)}
   
      <div style={{ justifyContent:"flex-end",alignContent:"flex-end", display:"flex", marginRight:"2%"}}>
      <button className	="buttons"   onClick={AddNewProducts}>Add New Products</button>

      </div>
  

      <MDBDataTable striped small data={data} scrollX />
  
  
  

</Modal.Body> 

 </Modal>
  );
}

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../auth/Auth";


const PrivateRoute = ({children}) => {
  const {currentUser} = useContext(AuthContext);
  console.log("hh")
  return (
   <>
    {currentUser ? children : <Navigate to="/login" />}
   </>
  );
};


export default PrivateRoute
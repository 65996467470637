import React, { useState, useEffect} from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";



function MemberInactiveModal({ setOpenModal}) {
   

  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"30vw",overflowY:"scroll"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !!Membership Inactive!! </h2> </center>
        </div>
        
        <div className="body">
        <center>
       <span style={{fontWeight:"500"}}>Your Membership Status is Inactive.<br/> Please contact the Administrator </span> 
        </center>
        </div>
        <div className="footer">
       <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Ok
          </button>
        
        </div>
        
      </div>
   
    </div>
  );
}

export default MemberInactiveModal;
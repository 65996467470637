import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
function NoticeUploadModal({ setOpenModal, publisher, userid}) {
    const [text, setText] = useState("");

    const [id, setId] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [year, setYear] = useState("")
    const [currentUserType, setCurrentUserType] = useState("");
    const [url, setUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [image, setImage] = useState("")
    const [imagePresent, setImagePresent] = useState(false)
    const [timeString, setTimeString] = useState("")

    
    const [months, setMonths] = useState([          
      "January",
      "February",
       "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ])
    
    useEffect(()=>{
    if(title.trim() != ""){
      setButtonDisabled(false)
    }
    else{
      setButtonDisabled(true)
    }
     
    },[title])
    
    const handleChange =async () => {
      
      
    
      const storageRef = firebase
        .storage()
        .ref(`notice/${id}/`)
        .child("notice");
      await  storageRef.put(image)
      setUrl(await storageRef.getDownloadURL())

    
      
    };
    
    useEffect(() => {
      let y = (new Date().getTime().toString())
      let x =  y + userid
      setTimeString(y)
      setId(x);
      currentDateTime();
    }, []);
    useEffect(()=>{
      if(image == ""){
        setImagePresent(false)
      }else{
        setImagePresent(true)
      }
    },[image])
    
    function GetId(){

    }
    function currentDateTime() {
      var date = new Date();
      let x = `${date.getDate().toString()}-${months[date.getMonth()]}-${1900 + date.getYear()}`;
      let y = `${singleToDouble(date.getHours().toString())}:${singleToDouble(
        date.getMinutes().toString()
      )}`;
      setYear(`${1900+date.getYear()}`)
      setCurrentDate(x);
      setCurrentTime(y);
    }

                
   
    
    
    function singleToDouble(str) {
      if (str.length == 1)
        //let x=
        return `0${str}`;
    
      return str;
    }

    useEffect(()=>{
     if(image != "" && url !=""){
      const users = firebase.firestore().collection("notice").doc(id);
    
      users
        .set({
          text: text,
          userid: userid,
          id:id,
          year:year,
          title: title,
          timestring : timeString,
          url:url,
          time: `${currentDate} ${currentTime}`,
          publisher: publisher
        })
        .then(function () {
      
          window.alert("Notice Successfully Uploaded!");
            setOpenModal(false)
        
        })
        .catch(function (error) {
          window.alert(error);
        });
     }
    },[image, url])
    

    
    const UpdateDetails = () => {
      //const users = newRef.doc(eventId);
      setLoading(true)
      if(image !=""){
        handleChange()
      }else{

        const users = firebase.firestore().collection("notice").doc(id);
    
      users
        .set({
          text: text,
          userid: userid,
          id:id,
          year:year,
          title: title,
          url:url,
          timestring: timeString,
          time: `${currentDate} ${currentTime}`,
        })
        .then(function () {
      
          window.alert("Notice Successfully Uploaded!");
            setOpenModal(false)
        
        })
        .catch(function (error) {
          window.alert(error);
        });
    
      }
    
        
    };
    
    
  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"80vh"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Upload Notice !! </h2> </center>

        </div>
        {loading? <ModalLoaderUi/>: <>
        <div className="body">
        <div className="inputBx1">
                <span>Notice Title</span>
                <input type="text" value={title} onChange={(e)=> setTitle(e.target.value)}/>
               </div>
               <div className="inputBx1">
                 <span>Notice Content</span>
                <textarea  onChange={(e)=> setText(e.target.value)} value={text} />
               </div>
        <div className="inputBx1">
          <span> Notice File </span>
              <input type="file" id="imageFileInput" onChange={(e)=> setImage(e.target.files[0])} />
           {imagePresent ?   <p style={{color:"red", float:"right", cursor:"pointer"}} onClick={()=>{setImage(""); document.getElementById("imageFileInput").value=""}}>Cancel File</p> :""} 
               </div>

        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
            UpdateDetails()
             
            }}
            disabled={buttonDisabled}
          >
          Upload Notice
          </button>
      
        </div>
        </>} 
      </div>
     
    </div>
  );
}

export default NoticeUploadModal;






































import { Navbar } from '../Navbar/Navbar';
import '../App.css';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
 import firebase from '../Common Components/firebase';
 import { AuthContext } from "../auth/Auth";
 import { MDBCol, MDBIcon, MDBContainer, MDBRow, MDBDataTable} from "mdbreact";
 // import '@fortawesome/fontawesome-free/css/all.min.css';
// import'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
//import { useHistory } from 'react-router-dom';
import Sidebar from '../Navbar/SideMenuBar';
import LoaderUi from '../Common Components/LoaderUi';
import NewRegExportModal from '../Modal/NewRegExportModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import AddNewRegDataModal from '../Modal/AddNewRegDataModal';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
function NewRegData() {

 
    const [newRegArray, setNewRegArray ] = useState([])
    const [allExportArray, setAllExportArray] = useState([])
    const [activeExportArray, setActiveExportArray ] = useState([])
    const [inActiveExportArray, setInActiveExportArray] = useState([])
    const [loading1, setloading1] = useState(true)
    const[invoicesforRender,setInvoicesForRender] = useState([])
    const [exportModalOpen, setExportModalOpen] = useState(false)
    const {currentUser} = useContext(AuthContext)
    const [allNewReg, setAllNewReg] = useState([])
    const [addNewRegModalOpen, setAddNewRegModalOpen] = useState(false)
    const userid = currentUser.uid
    const navigate = useNavigate()
   // const history = useHistory()
    useEffect(()=>{
      firebase.firestore().collection("users").doc(userid).get().then((doc)=>{
        if(doc.data().type == "admin"){
          setloading1(false)
        }else{
          window.alert("You are not Authorized to Access this Page")
          navigate("/")
        }
      })
    },[])
    useEffect(()=>{
      const ref  = firebase.firestore().collection("newreg")
      ref.onSnapshot((querySnapshot)=>{
          let temp=[]
          let tempall =[]
          let exportAllTemp = []
          let exportActiveTemp =[]
          let exportInActiveTemp = []          
          querySnapshot.forEach((doc)=>{
            let x={}
            tempall.push(doc.data())
          if(doc.data().registrationstatus == "n"){
            temp.push(doc.data())

            x["Membership Id"] = doc.data().mid
            x["Membership Status"] = doc.data().memstatus
            x["Name"] = doc.data().name
            x["Aliasname"] = doc.data().aliasname
            x["Father's Name / Husband Name"] = doc.data().fathername
            x["Whatsapp"] = doc.data().whatsapp
            x["Mobile"] = doc.data().mobile
            x["Email"] = doc.data().email
            x["Address"] = doc.data().address
            x["Blood Group"] = doc.data().bloodgroup
            x["D.O.B"] = doc.data().dob
            x["PAN"] = doc.data().pan
            x["Aadhar"] = doc.data().aadhar
            x["D.O.A"] = doc.data().doa
            x['Spouse Name'] = doc.data().spouse
            x["Spouse Mobile"] =doc.data().spousemobile
            x["Spouse DOB"] = doc.data().spousedobirth
            exportAllTemp.push(x)
            if(doc.data().memstatus == "active")
              exportActiveTemp.push(x)
            if(doc.data().memstatus != "active")
              exportInActiveTemp.push(x)

          }
                

              
          })
         
         setNewRegArray(temp)
        
        //  setloading1(false)
         setInActiveExportArray(exportInActiveTemp)
         setActiveExportArray(exportActiveTemp)
         setAllExportArray(exportAllTemp)
          setAllNewReg(tempall)
      })
    },[])

       
      

   function Delete(id){
    if(window.confirm(`Are you sure to delete the Membership ID :- ${id} ?\nThis can't be undone !!`))
    firebase.firestore().collection("newreg").doc(id).delete().then(()=>{
      window.alert("Membership Id Deleted !!")
      window.location.reload()
    })
   }



    useEffect(()=>{
 
    let postsArray = JSON.parse(JSON.stringify(newRegArray));
    let userData = [];
    postsArray.map((item, index) => {
      item.id = (
        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.mid}</div>
      );
      item.serialno =(<div><b>{index+1}</b></div>)
      item.action = (
          <>
    
    <EditIcon style={{color:"green", cursor:"pointer", margin:"2px"}}/>
    <DeleteIcon style={{color:"red", cursor:"pointer", margin:"2px"}} onClick={()=>Delete(item.mid)}/>
    <PermPhoneMsgIcon style={{color:"black", cursor :"pointer", margin:"2px"}} />
</>
      );
      userData.push(item);
    });
    setInvoicesForRender(userData);
  }, [newRegArray]);


    const data = {
        columns: [
          {
            label: 'Sl No.',
            field: 'serialno',
            sort: 'asc',
            width: 150,
          },
           
            {
                label: 'Membership ID',
                field: 'mid',
                sort: 'asc',
                width: 150,
                
              },
              {
                label: 'Membership Status',
                field: 'memstatus',
                sort: 'asc',
                width: 150
              }, 
              {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150,
              },
              {
                label: 'Alias Name',
                field: 'aliasname',
                sort: 'asc',
                width: 150,
              },
        
          {
            label: "Father's Name / Husband's Name",
            field: 'fathername',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Dob',
            field: 'dob',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Whatsapp',
            field: 'whatsapp',
            sort: 'asc',
            width: 150,
        
          },
     
          {
            label: 'Mobile No',
            field: 'mobile',
            sort: 'asc',
            width: 150
          }, {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Blood Group',
            field: 'bloodgroup',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Address',
            field: 'address',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Date of Anniversary',
            field: 'doa',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Spouse Name',
            field: 'spouse',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Spouse Mobile',
            field: 'spousemobile',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Spouse DoB',
            field: 'spousedobirth',
            sort: 'asc',
            width: 150
          }, 
          {
            label: 'Aadhar',
            field: 'aadhar',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Pan',
            field: 'pan',
            sort: 'asc',
            width: 150,
          },

           {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: invoicesforRender

      };
    
      
    if(loading1)
      return(<LoaderUi/>)

  return (
   <>
   {exportModalOpen && <NewRegExportModal setOpenModal={setExportModalOpen} activeUnregisterdMembers={activeExportArray} inactiveregisteredMembers={inActiveExportArray} allUnregisteredMembers={allExportArray}/>}
    {addNewRegModalOpen && <AddNewRegDataModal setOpenModal={setAddNewRegModalOpen} allNewRegData={allNewReg} />}
    <Sidebar/>
    <Navbar/>
    <div style={{paddingTop:"100px"}}></div>
  <div  className="divmargin flex-wrapper" >
  <MDBContainer  
    >
      <MDBRow>  <MDBCol md="1" >
      </MDBCol>
      
      <MDBCol md="8"> 

      </MDBCol>
      <MDBCol md='3'> 
      <button className='buttons' onClick={()=> setAddNewRegModalOpen(true)}>Add New</button>
    <button  className='buttons' style={{margin:"5px"}}onClick={()=> setExportModalOpen(true)}>Export</button>
    </MDBCol>
      
      </MDBRow>
     
    </MDBContainer>

  <MDBDataTable
      striped
      bordered
      small
      scrollX
      data={data}
     
    />
  </div>
  <div style={{flexGrow:1}}></div>
  <Footer/>
    </>



  );
}

export default NewRegData;
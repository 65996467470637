import React,{Fragment, useContext, useEffect, useState} from 'react';
import firebase from "../Common Components/firebase";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { IconButton, TextField } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Navbar } from '../Navbar/Navbar';
import "../MemberDirectory.css";
import { AuthContext } from "../auth/Auth";
import SideMenuBar from '../Navbar/SideMenuBar'
import { LoginNavbar } from '../Navbar/LoginNavbar';
import LoaderUi from '../Common Components/LoaderUi'
import { placeholderManImage, toTitleCase } from '../Common Components/CommonFunctions';
import MemberImageDisplayModal from '../Modal/MemberImageDisplayModal';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ViewAffliationDialog from '../Dialogs/ViewffliationDialog';
import ViewMemberAffliations from '../Modal/ViewMemberAffliations';
import Footer from '../Footer/Footer';



function MemberDirectory() {

  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [modalImage, setModalImage] = useState("");
  const [userType, setUserType] = useState("")
  const[searchTerm, setSearchTerm] = useState("");
  const [imageModal, setImageModal] = useState(false)
  const {currentUser} = useContext(AuthContext);
  const [dialogueOpen, setDialogueOpen] = useState(false)
  const [memberName,setMemberName] = useState("")
  const [viewAffliationModalOpen,setViewAfflliationModalOpen] = useState(false)
  const [viewAffliationUserId, setViewAfflliationModalUserId] = useState("")
  const uid = currentUser.uid
  const [modalOpen, setModalOpen] = useState(false)
  function CheckBusiness (){
      /*firebase.firestore().collection("users").doc(uid).collection("business").limit(1).get().then((query)=>{
    if(query.size==0)
      setModalOpen(true)
    });*/
    	
  }

  useEffect(()=>{
   CheckBusiness()
  },[])

  const ref1 = firebase.firestore().collection('users').doc(uid)
  useEffect(() => {
    ref1
      .get()
      .then((doc) => {
        if (doc.exists) {
          
          setUserType(doc.data().type)
        
               } else {
          // doc.data() will be undefined in this case
        }
      })
      .catch((error) => {});
     
    
  }, []);
  


let  ref = firebase.firestore().collection("users");





 function getSchools(){

   setloading(true);
   const items=[];
   ref.onSnapshot((querySnapshot)=>{
   
     querySnapshot.forEach((doc)=>{
         if(doc.data().memstatus == "active")
           items.push(doc.data());
     });
     
     setschools(items);
     setloading(false);

     
   })
 }  
 
 
 useEffect(()=>{
   getSchools();
 },[]);

 function OpenImageModal(imageSrc){
   setModalImage(imageSrc)
   setImageModal(true)
 }
 function ShowAffliation(id, salutation, name){
    setMemberName(`${salutation} ${name}`)
    setViewAfflliationModalUserId(id)
    firebase.firestore().collection("users").doc(id).collection("affliations").limit(1).get().then((doc)=>{
     
      if(doc.size==0){
        setDialogueOpen(true)
      
      }
      else{

        setViewAfflliationModalOpen(true)
  
      }
    })
       
 }


 if(loading){
   return (<LoaderUi/>)
 }







return (
    <Fragment >
      <IdleTimeContainer/>
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
     
      {imageModal && (<MemberImageDisplayModal imageSrc={modalImage} setOpenModal={setImageModal}/>)}
      {viewAffliationModalOpen && <ViewMemberAffliations setOpenModal={ setViewAfflliationModalOpen} userId={viewAffliationUserId} />}
      <div className="divmargin flex-wrapper">
        {dialogueOpen &&(<ViewAffliationDialog membername={memberName} setDialogueClose={setDialogueOpen} />)}
           <div style={{paddingTop:"120px",zIndex:0}}></div>
    <MDBContainer  
    >
      <MDBRow>  <MDBCol md="3" >
      </MDBCol>
      
      <MDBCol md="6">  
      
            <TextField id="standard-basic" label="Search By Name" style={{width :"100%", fontSize:"20px"}} variant="standard" value={searchTerm} onChange={(e)=> setSearchTerm(e.target.value)} />

      
      </MDBCol>
      <MDBCol md='1'></MDBCol> 
      <MDBCol md='2'></MDBCol>
      
      </MDBRow>
     
    </MDBContainer>
  
  
  
    

      {schools
      .filter((val)=>{
        if(searchTerm.trim() == ""){  return val}
        else if(val.name.toLowerCase().includes(searchTerm.toLowerCase()) ){return val}      
      })
      .map((school,key)=>(       
 
        <div  key={school.mid} >     
    
        <div  style={{padding:"20px",cursor:"pointer"}} className="d-flex bd-highlight example-parent justify-content-around">
        <div className="p-2  bd-highlight col-example" ></div>

           <div className="p-2  flex-shrink-1 bd-highlight col-example" >
           
          <img src={school.image} onError={(e)=>{e.target.onerror = null; e.target.src=placeholderManImage}} onClick={()=> OpenImageModal(school.image)} width="200px" style={{ width: 150,
              height: 150,
              borderRadius: 100/ 2,
              overflow: "hidden",
              border:"1px solid #4D3A78"
            }} 

            
               />
 <div className="iconContainer1">
            
            <IconButton className="IconButton"  href={"mailto:"+  school.email}  ><EmailIcon style={{fontSize:30}}  /> </IconButton> 
            <IconButton className="IconButton" href={"tel:"+  school.phone}   > <PhoneInTalkRoundedIcon style={{fontSize:30}}  /> </IconButton>
            <IconButton className="IconButton" href={"https://www.google.com/maps/dir// "+  school.address}  > <LocationOnRoundedIcon style={{fontSize:30}}  /> </IconButton>
            <IconButton className="IconButton" href={"https://wa.me/"+ "+91"+school.whatsapp}   ><WhatsAppIcon style={{fontSize:30}} ></WhatsAppIcon></IconButton>
            <IconButton className="IconButton" onClick={()=>ShowAffliation(school.userid, school.salutation, school.name)}  ><AccessibilityNewIcon style={{fontSize:30}} /></IconButton>
             
             </div>
</div>

<div className="p-2 flex-fill flex-grow-1 bd-highlight col-example" style={{textAlign:"center", border:"2px solid #A03840"}} onClick={()=> window.location.href=`/member-portal/${school.mid}`}>
 
         <p style={{color:"#A03840", fontSize:"30px", fontWeight:"400" }}> {school.salutation}&nbsp;{toTitleCase (`${school.name}`)}</p>
         
         <p style={{color:"#4D3A78", fontSize:"30px" }} > {school.mid}</p>
 
        
          </div>
          
          <div className="iconContainer2" >
            
           <IconButton className="IconButton"  href={"mailto:"+  school.email}  ><EmailIcon style={{fontSize:80}}  /> </IconButton> 
           <IconButton className="IconButton" href={"tel:"+  school.phone}   > <PhoneInTalkRoundedIcon style={{fontSize:80}}  /> </IconButton>
           <IconButton className="IconButton" href={"https://www.google.com/maps/dir// "+  school.address}  > <LocationOnRoundedIcon style={{fontSize:80}}  /> </IconButton>
           <IconButton className="IconButton" href={"https://wa.me/"+ "+91"+school.whatsapp}   ><WhatsAppIcon style={{fontSize:80}} ></WhatsAppIcon></IconButton>
           <IconButton className="IconButton" onClick={()=>ShowAffliation(school.userid, school.salutation, school.name)}  ><AccessibilityNewIcon style={{fontSize:80}} /></IconButton>
            
            </div>
        </div></div>
        
      
      
            
      ))}
 
 </div>   
 <div style={{flexGrow:1}}></div>
 <Footer/>
    </Fragment>   
  );
}

export default MemberDirectory;

import React, { useEffect, useState } from "react"
import { AuthContext } from "../auth/Auth";
import IdleTimeContainer from "../Common Components/IdleTimeContainer"
import GalleryUploadModal from "../Modal/GalleryUploadModal";
import { LoginNavbar } from "../Navbar/LoginNavbar"
import { Navbar } from "../Navbar/Navbar";
import SideMenuBar from "../Navbar/SideMenuBar";
import firebase from "../Common Components/firebase";
import "./Gallery.css"
import MediaQuery from "react-responsive";

import Carousel from 'react-material-ui-carousel'
import { DeleteForeverOutlined, EditAttributesOutlined, EditOutlined } from "@mui/icons-material";
import GalleryUpdateModal from "../Modal/GalleryUpdateModal";
import GalleryImages from "./GalleryImages";
import { Box } from "@mui/system";
import { placeholderManImage, toTitleCase } from "../Common Components/CommonFunctions";
import {Alert, Avatar, Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import LoaderUi from "../Common Components/LoaderUi";
import Footer from "../Footer/Footer";

export default function GalleryMain(){
  const [loading, setLoading] = useState(true)
    const [galleryModalOpen, setGalleryModalOpen] =  useState(false)
    const [publisher, setPublisher] = useState("")
    const [currentUserType, setCurrentUserType] = useState("")
    const [yearArray, setYearArray] = useState([])
    const [selectedYear, setSelectedYear] = useState("")
    const [gallery, setGallery] = useState([])
    const [displayArray, setDisplayArray] = useState([])
    const [galleryUpdateModalOpen, setGalleryUpdateModalOpen] = useState(false)
    const [imageObject, setImageObject] = useState({})
    const [showGalleryImages, setShowGalleryImages] = useState(false)
    const [showGalleryImagesObj, setShowGalleryImagesObj] = useState({})
    const [usersArr, setUsersArr] = useState([])




    const userid = firebase.auth().currentUser.uid

    useEffect(()=>{
        const ref = firebase.firestore().collection("users").doc(userid)
         ref.get().then((doc)=>{

        setCurrentUserType(doc.data().type)
          })
        },[])

        function getUsersArray(){
          firebase.firestore().collection("users").onSnapshot((querySnapshot)=>{
            let temp =[]
            querySnapshot.forEach((doc)=>{
              temp.push(doc.data())
            })
            setUsersArr(temp)
          })
        }
        function setYearArrayFn(){
            const date = (new Date()).getFullYear()
            setSelectedYear(date.toString())
            let temp=[]
            for(let i=0; i<10; i++){
              temp.push((date-i).toString())
            }
            setYearArray(temp)
          }
          useEffect(()=>{
            getUsersArray()
          },[])
          useEffect(()=>{
        
              setYearArrayFn()
              getGallery()
             
          },[usersArr])
          function getGallery(){
            const ref = firebase.firestore().collection("gallery").orderBy('time','desc')
            ref.onSnapshot((querySnapshot)=>{
              let temp =[]
              querySnapshot.forEach((doc)=>{
                let x= doc.data()
                x['publishername'] = getUserName(doc.data().userid)
                temp.push(x)
              })
              setGallery(temp)
              setLoading(false)
            })
            
          }


          function getUserName(id){
            let name =""
            for(let i=0; i< usersArr.length; i++){
              if(usersArr[i]['userid'] == id){
                let s =  usersArr[i].salutation ?  usersArr[i].salutation :""
                name = s+" "+ toTitleCase(usersArr[i]['name'])
                return name
              }
            }
            return name
          }

          useEffect(()=>{
            if(selectedYear !=""){
              let temp=[]
              for(let i=0; i< gallery.length; i++){
                if(gallery[i]['year'] == selectedYear){
                    temp.push(gallery[i])
                }

              }
              setDisplayArray(temp)
            }
          },[selectedYear, gallery])

          function DeleteGallery(id){
              
              let x = window.confirm("Are You Sure?\nThis can't be undone")
              if(x){
                const ref = firebase.firestore().collection("gallery").doc(id)
                ref.delete().then(()=> window.alert("Post Deleted !!"))
              }
          }

          function EditGallery(imgobj){
            setImageObject(imgobj)
            setGalleryUpdateModalOpen(true)
          }

          function ShowGalleryImagesFunc(img){
            setShowGalleryImagesObj(img)
            setShowGalleryImages(true)
          }
          if(loading)
            return (<LoaderUi/>)
    return(
        <>
        <IdleTimeContainer />
      {/* <LoginNavbar */}
      <Navbar/>
      <SideMenuBar />
      
      {galleryModalOpen && (<GalleryUploadModal setOpenModal={setGalleryModalOpen} publisher={publisher} userid={userid }/>)}
      {galleryUpdateModalOpen && (<GalleryUpdateModal setOpenModal={setGalleryUpdateModalOpen} imageObject={imageObject} userid={userid} />)}
      <div style={{paddingTop:"100px"}}></div>
      <div className="flex-wrapper">
      {!showGalleryImages ? 
    <>
     {currentUserType == "admin" ?  <div className="d-flex justify-content-end">
        <div className="d-flex p-2 col-example">
          <button
            onClick={() => setGalleryModalOpen(true)}
            className="buttons"
          >
              Upload Gallery
          </button>
          <br />
          <br />
        </div>
      </div>:""}
      <span className="GallerySideMargin">Select Year</span> <br/>
    <select style={{ width:"40%", borderRadius:"30px", fontSize:"16px", padding:"10px"}} className="GallerySideMargin"value={selectedYear} onChange={(e)=> setSelectedYear(e.target.value)}> 
    {yearArray.map((doc,key)=>(<option value={doc}>{doc}</option>))}
    </select>
    <div className="GallerySideMargin img-grid" style={{marginTop:"2vh"}}>
      {displayArray.length == 0 &&(<Alert severity="info">No Data to Display !!</Alert>)}
      {displayArray.map((doc, key)=>(

        <Card style={{ width:300, maxWidth:300, boxShadow:"2px 2px grey", cursor:"pointer"}} onClick={()=>ShowGalleryImagesFunc(doc)}>
          <CardHeader title={doc.publishername} avatar={<Avatar src={placeholderManImage} />}/>
          <CardMedia
          component="img"
          id='myimg'
          height="auto"
          image= {doc.image[0]}
          title="MVT Gallery"/>
      <CardContent style={{color:"#A03840"}}>
          <Typography gutterBottom variant="h5" component="h2">
           <SubtitlesIcon/> {doc.title} 
          </Typography>
      </CardContent>
 </Card>
      ))}
           
          

          </div>
         
 </> : <GalleryImages setShowGalleryFront={setShowGalleryImages} images={showGalleryImagesObj}/>}

 </div>
 <div style={{flexGrow:1}}></div>
          <Footer/>
  </>
  )
}


import React, { useState, useEffect} from "react";
import "./UpdateModal.css";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { toTitleCase } from "../Common Components/CommonFunctions";

export default function BusinessDetailsModal({ setOpenModal, productObj}) {
 
 const [loading, setLoading] = useState(false)
 console.log(productObj)

   

  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"60vw",overflowY:"scroll", display:"flex", flexDirection:"column"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}> Business Details  </h2> </center>
        </div>
        {loading ? <ModalLoaderUi/> :<>
   <div className="body" style={{flex:"50% !important", display:"flex !important"}}>
            <h3 style={{fontSize:"1.7rem"}}> Name :- {productObj.bizname}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Owner :- {productObj.bizowner}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Type :- {toTitleCase(productObj.biztype)}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Subtype :- {productObj.bizsubtype}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Phone :- {productObj.bizmobile}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Whatsapp :- {productObj.bizwhatsapp}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Email :- {productObj.bizemail}</h3>
            <h3 style={{fontSize:"1.7rem"}}> Address :- {productObj.bizaddress}</h3>

            <center>
             <IconButton className="IconButton" href={"mailto:"+  productObj.bizemail}  ><EmailIcon style={{fontSize:100}}  /> </IconButton> 
           <IconButton className="IconButton" href={"tel:"+  productObj.bizmobile}   > <PhoneInTalkRoundedIcon style={{fontSize:100}}  /> </IconButton>
           <IconButton className="IconButton" href={"https://www.google.com/maps/dir// "+  productObj.bizaddress}  > <LocationOnRoundedIcon style={{fontSize:100}}  /> </IconButton>
           <IconButton className="IconButton" href={"https://wa.me/"+ "+91"+productObj.bizwhatsapp}   ><WhatsAppIcon style={{fontSize:100}} ></WhatsAppIcon></IconButton>
           </center>

      </div>
        <div className="footer">

       <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
        </div>
        </>}
      </div>
   
    </div>
  );
}

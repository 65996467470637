import React, { Fragment, useEffect, useState, useContext } from "react";
import * as Loader from "react-loader-spinner";

 export default function ModalLoaderUi(){

    return(<>
  
   <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {'https://firebasestorage.googleapis.com/v0/b/mvt-demo-ca374.appspot.com/o/PS%20Logo.png?alt=media&token=342b8068-8f46-4183-af88-73c1e1042532'  }width="100px" style={{margin:"20px"}}/>
        
          </div></div>
          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          

        <h1> Processing Your Request</h1><br/>
   
          </div></div>


          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          
        <Loader.ThreeDots

    color="#4D3A78"
    height={70}
    width={200}
    
    />
          </div></div>

         
    </>
    )
}

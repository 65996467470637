import React, { useCallback, useContext,useEffect,useState } from "react";
import { Fragment } from "react";
import app from "../Common Components/firebase";
import { AuthContext } from "./Auth.js";
import {LoginNavbar} from '../Navbar/LoginNavbar';
import {LoginPageNavbar} from '../Navbar/LoginPageNavbar';
import Carousel from "react-material-ui-carousel";
import './Login.css'
import axios from "axios";
import LoaderGif from "../Assets/MVT_GIF.gif"
import { Navigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import MVT_1 from '../Assets/SidePanels/MVT_1.jpeg'
import MVT_2 from '../Assets/SidePanels/MVT_2.jpeg'
import MVT_3 from '../Assets/SidePanels/MVT_3.jpeg'
import MVT_4 from '../Assets/SidePanels/MVT_4.jpeg'


const Login = () => {

  const [showPassword, setShowPassword] = useState(false)
  const [password1, setPassword1] = useState("")
  const [images, setImages] = useState({0:MVT_1, 1:MVT_2, 1:MVT_3, 2:MVT_4})
   

 
  const handleLogin = 
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {

      app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
      setPassword1(password.value)
     return app.auth().signInWithEmailAndPassword(email.value, password.value);

  }).catch((error)=>{

    window.alert(error)
  })
  
        // await app
        //   .auth()
        //   .signInWithEmailAndPassword(email.value, password.value);
     
      } 
      catch (error) {
         window.alert(error);
      }
    }



  const { currentUser } = useContext(AuthContext);
  const UpdatePasswordOnLogin=(x)=>{
    const users = app.firestore().collection("users").doc(currentUser.uid);
    //let map = new Map([mid,"r"])
   users.update({
     password:x
   }).then(function(){

   }).catch(function(error){
    
   })
  }

  function WhatsAppLoginMsg(name, whatsapp){
  const msg = `Dear ${name},%0aYou have successfully logged into your MAYUM Account.%0aIf you haven't done this, kindly change your MAYUM password as soon as possible or contact the administrator.%0aRegards,%0aMarwari Yuva Manch%0aMuzaffarpur`
  
  }

  const forgotPassword = () => {
  
    const Email = window.prompt("Enter the Email")

    if(Email!=null && Email!=""){
    app.auth().sendPasswordResetEmail(Email)
        .then(function () {
            alert('Please check your email...')
        }).catch(function (e) {
            
        }) 
      }
    }
  if (currentUser) {
    app.firestore().collection("users").doc(currentUser.uid).get().then((doc)=>{
      // LoginSms( toTitleCase( doc.data().name), doc.data().phone)
      // WhatsAppLoginMsg( toTitleCase( doc.data().name), doc.data().whatsapp)
    })
    UpdatePasswordOnLogin(password1)
    return <Navigate to="/" />;
  }

  return (   
    <Fragment>
        
       {/* <LoginNavbar/>  
       <LoginPageNavbar/>         <div style={{paddingTop:"120px",zIndex:0}}></div> */}
       <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(images).map((doc)=>(
            <> <img src ={doc} className={"images1"} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
          <center>  <img src={LoaderGif} width={150} /><br/></center>
             <h2>Login</h2>
             <form onSubmit={handleLogin}>
               <div className="inputBx">
                 <span>Username</span>
                 <input type="text" name="email" /> 
               </div>
               <div className="inputBx">
                 <span>Password</span>
                 <input type={showPassword ?"text":"password" }name="password" /> 
               </div>
               <div className="remember">
                 <label>  <input type="checkbox" name="" onChange={()=> setShowPassword(!showPassword)} /> Show Password</label>
               </div>
               <div className="remember">
                 <label style={{cursor:"pointer"}} onClick={()=> forgotPassword()}> Forgot Password </label>
               </div>
               <div className="inputBx">
                 
                 <input type="submit" value="Sign In" /> 
               </div>
               <div className="inputBx">
              <p>Dont Have an account ? <a onClick={()=>{window.location.pathname= '/registration'}}style={{cursor:"pointer"}}> Sign Up</a></p>
               </div>
             </form>
           </div>
       
         </div>
       </section>
       <Footer/>
    </Fragment>
  );
};

export default Login;




// <Fragment>
   

export const MenuItems =[
    {
        title:'Home',
        url :'https://mymmuzaffarpur.com/',
        cName:'nav-links'
    },

    {
        title:'About Us',
        url :'https://mymmuzaffarpur.com/about-us/',
        cName:'nav-links'
    },

    {
        title:'Services',
        url :"https://mymmuzaffarpur.com/our-services/",
        cName:'nav-links'
    },

    {
        title:"Contact",
        url :"https://mymmuzaffarpur.com/stay-connected/",
        cName:'nav-links'

    },
    
    {
        title:"Explore",
        url :"https://mymmuzaffarpur.com/e-pay-tnc/",
        cName:'nav-links-portal'

    }
   

]
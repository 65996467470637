import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import {UpdateSms} from "../Common Components/SmsCommunication";
import { set } from "date-fns";
import { ExportCSVCommon } from "../Common Components/ExportCSVCommon";
import { Modal, Button } from "react-bootstrap";
function NewRegExportModal({ setOpenModal, allUnregisteredMembers, activeUnregisterdMembers, inactiveregisteredMembers}) {

  

  return (
    <Modal show={true}    onHide={setOpenModal}
    backdrop="static"
    keyboard={false}>
    <Modal.Header closeButton>
    <h1 style={{fontSize:"20px"}}>Export Unregistered Member Data</h1> 
    </Modal.Header>
    <Modal.Body>
    <center>
            <br/>
            <ExportCSVCommon buttonText={"Export All Unregistered Members"} csvData={allUnregisteredMembers} fileName={"MVT All Unregistered Members"}/>
            <br/><br/>
            <ExportCSVCommon buttonText={"Export Active Unregistered Members"} csvData={activeUnregisterdMembers} fileName={"MVT Active Unregistered Members"}/>
            <br/><br/>
            <ExportCSVCommon buttonText={"Export Inactive Unregistered Members"} csvData={inactiveregisteredMembers} fileName={"MVT Inactive Unregistered Members"}/>
            <br/>
        </center>
      </Modal.Body>
      <Modal.Footer>

              <Button variant="secondary" onClick={()=> setOpenModal(false)}>
                Close
              </Button>
    
            </Modal.Footer>
      </Modal>
   
  );
}

export default NewRegExportModal;
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";
import { initializeApp } from "@firebase/app";

const firebaseConfig = {
 
  
  apiKey: "AIzaSyBMR0_Q3dqcuOOvJCNMYoGocLva0eSdtFE",
  authDomain: "mvt-real-wallah.firebaseapp.com",
  projectId: "mvt-real-wallah",
  storageBucket: "mvt-real-wallah.appspot.com",
  messagingSenderId: "968576232096",
  appId: "1:968576232096:web:eec20e0d62bf294288f7c5",
  measurementId: "G-L8Y02E61HZ"
  };

  firebase.initializeApp(firebaseConfig);
  // const app=initializeApp(firebaseConfig)
  // export const auth=getAuth(app)
  
export default firebase;



import React, { Fragment, useEffect, useState, useContext } from "react";
import LoaderGif from "../Assets/MVT_GIF.gif"

 export default function LoaderUi(){

    return(<>
    <div style={{paddingTop:"200px"}}></div>
   <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {LoaderGif} width="300px" />
        
          </div></div>
     
         
    </>
    )
}

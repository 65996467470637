import { useScrollTrigger } from "@mui/material";
import { add } from "lodash";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/Auth";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import LoaderGif from "../Assets/MVT_GIF.gif"

export default function FamilyBloodBankModal({setOpenModal, oname}){

    const [name, setName] = useState("")
    const [relation, setRelation] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [bloodGroup, setBloodGroup] = useState("")
    const [whatsApp, setWhatsapp] = useState("")
    const [address, setAddress] = useState("")
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const {currentUser} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const uid = currentUser.uid
    function SaveFamilyDetails(){
        setLoading(true)
        const currentTime = ((new Date()).getTime()).toString()
        const tempRelation = relation.replace(/\s/g,'').toLowerCase()
        const id = uid + tempRelation + currentTime
        firebase.firestore().collection("BloodBankFamily").doc(id).set({
            name : name.trim(),
            relation : relation,
            whatsapp : whatsApp.trim(),
            email  : email.trim(),
            userid : uid,
            phone : mobile.trim(),
            address : address.trim(),
            bloodgroup : bloodGroup,
            oname : oname
        }).then(()=>{
            window.alert("Family Member Enrolled for Trust Blood Blank")
            window.location.reload()
        })
    }
    useEffect(()=>{
        if(name.trim() !="" && mobile.length == 10 && whatsApp.length == 10 && email.trim() !="" && address.trim() !="" ){
            setButtonDisabled(false)
        }
        else{
            setButtonDisabled(true)
        }
    },[name, email, address, mobile, whatsApp])
    return(
        <Modal show={true}         
        onHide={setOpenModal}
        backdrop="static"
        keyboard={false} >
            <Modal.Header closeButton>
              <h1 style={{fontSize:"20px"}}>  Family Blood Bank Enrollment </h1>
            </Modal.Header>
            <Modal.Body>
              {
                loading ?<center> <img src={LoaderGif} width={200}/></center>:<>
                 <div className="inputBx1">
                    <span>Name</span>
                    <input type="text" value={name} onChange={(e)=> setName(e.target.value)}/>
                </div>

                <div className="inputBx1">
                    <span>Relation</span>
                    <input type="text" value={relation} onChange={(e)=> setRelation(e.target.value.replace(/[^\w\s]/gi, ''))}/>
                </div>
                <div className="inputBx1">
                    <span>Blood Group</span> <br/>
                    <select  style={{ cursor:"pointer"}} value={bloodGroup}onChange={(e)=>setBloodGroup(e.target.value)}  >
                        <option value="">Choose</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB-">AB-</option>
                        <option value="AB+">AB+</option>
                    </select>
                </div>
                <div className="inputBx1">
                    <span>Mobile</span>
                    <input type="text" value={mobile} maxLength={10} onChange={(e)=> setMobile(e.target.value)}/>
                </div>

                <div className="inputBx1">
                    <span>Whatsapp</span>
                    <input type="text" value={whatsApp} maxLength={10} onChange={(e)=> setWhatsapp(e.target.value)}/>
                </div>

                <div className="inputBx1">
                    <span>Email</span>
                    <input type="text" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                </div>
                <div className="inputBx1">
                    <span>Address</span>
                    <input type="text" value={address} onChange={(e)=> setAddress(e.target.value)}/>
                </div>
                
                </>
              } 
            </Modal.Body>
            {!loading&& (
            <Modal.Footer>
                <Button variant="secondary" onClick={()=> setOpenModal(false)}> Close</Button>
                <Button variant="primary" disabled={buttonDisabled} onClick={()=> SaveFamilyDetails()}> Save</Button>
            </Modal.Footer>)}
        </Modal>
    )
}
import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";

import MultiImageInput from 'react-multiple-image-input';
import {UpdateSms} from "../Common Components/SmsCommunication";
import { getStorage, ref,uploadString, getDownloadURL } from "firebase/storage";
function GalleryUpdateModal({ setOpenModal, imageObject , userid}) {
    const [id, setId] = useState(imageObject['id']);
    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [year, setYear] = useState(imageObject['year'])
    const [url, setUrl] = useState([])
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState(imageObject["title"])
    const [image, setImage] = useState(imageObject['image'])
    const [imagePresent, setImagePresent] = useState(false)
    const [timeString, setTimeString] = useState("")
    const [yearArray, setYearArray]  = useState([])
    const [time, setTime] = useState("")
    const storage = getStorage()
    const [galleryid, setGalleryID] = useState(imageObject['id'])
    const [fileCount, setFileCount] = useState(0)
    const [months, setMonths] = useState([          
      "January",
      "February",
       "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ])
    
    useEffect(()=>{
    if(title.trim() != ""){
      setButtonDisabled(false)
    }
    else{
      setButtonDisabled(true)
    }
     
    },[title])
    

    
    function setYearArrayFn(){
        const date = (new Date()).getFullYear()
        setYear(date.toString())
        let temp=[]
        for(let i=0; i<10; i++){
          temp.push((date-i).toString())
        }
        setYearArray(temp)
      }
      useEffect(()=>{
         
          setYearArrayFn()
          GetFileCount()
         
      },[])

    
    useEffect(() => {
      let y = (new Date().getTime().toString())
      let x =  y + userid
      setTimeString(y)
      setId(x);
      currentDateTime();
    }, []);
    useEffect(()=>{
      if(image == ""){
        setImagePresent(false)
      }else{
        setImagePresent(true)
      }
    },[image])
    
  
    function currentDateTime() {
      var date = new Date();
      let x = `${date.getDate().toString()}-${months[date.getMonth()]}-${1900 + date.getYear()}`;
      let y = `${singleToDouble(date.getHours().toString())}:${singleToDouble(
        date.getMinutes().toString()
      )}`;
      setYear(`${1900+date.getYear()}`)
      setCurrentDate(x);
      setCurrentTime(y);
    }

                
   
    
    
    function singleToDouble(str) {
      if (str.length == 1)
        //let x=
        return `0${str}`;
    
      return str;
    }

   
    const UpdateGallery=(y,x)=>{
      setLoading(true)
    
      const galleryRef = firebase.firestore().collection('gallery');
    
        
        // let x = (new Date()).getTime().toString()
        // let y = "business" + x
        const ref = galleryRef.doc(y)
       // setBusinessID(y)
     
     ref.update({
      userid : userid,
      time: x,
      id:y,
      title: title,
      year:year
      
  
     }).then(()=>{
    //  UploadImages(y)
    window.alert("Gallery Uploaded  Successfully !!")
    setOpenModal(false)
    window.location.reload()
     }).catch(function(error){
       
     })
   
    } 
    
    const UpdateDetails = () => {
      //const users = newRef.doc(eventId);
   
      setLoading(true)
  
      setTime((new Date()).getTime().toString())
 
      const z = Object.values(image)
     let temp=[]
      let y =-1
      
      z.forEach(async(image, index)=>{
        y++
        let imageNo = y+ fileCount+1
        if(!image.includes("https")){
     
          const fileRef=ref(storage,`gallery/${id}/images${imageNo}.png`)
 
           const snapshot= await uploadString(fileRef,image,'data_url' )
           const photoURL= await getDownloadURL(fileRef)
  

        setUrl((prevState) => [...prevState, photoURL]);
      }else{

              temp.push(image)
          }
         
    })
    setUrl((prevState) => [...prevState,...temp]);
}
    
    useEffect(()=>{
      const z = Object.values(image)
      let temp={}
  
      if(z.length > 0 && (z.length == url.length)){
        url.forEach((url, index)=>{
          temp[index] = url
     
        })
       
        const businessRef = firebase.firestore().collection("gallery").doc(galleryid);
        businessRef.update({image : temp}).then(()=>{
          // window.alert("New Business Added !!")
          UpdateGallery(galleryid, time)
       //   // history.push("/my-profession")
        })
        
      }
    
    },[url])
    function GetFileCount(){
        let y =0
        var listRef =firebase.storage().ref(`gallery/${id}`)
        

// Find all the prefixes and items.
        listRef.listAll()
        .then((res) => {
    
            res.items.forEach((itemRef) => {
                y++
            
            });
            setFileCount(y)
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }
  return (
    <div className="modalBackground1">
      <div className="modalContainer1" style={{ overflow:"scroll"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title">
  <center> <h2 style={{color:"red"}}>  !! Update Gallery !! </h2> </center>

        </div>
        {loading? <ModalLoaderUi/>: <>
        <div className="body">
            <div className="inputBx1">
            <span>Select Year</span> <br/>
    <select  value={year} onChange={(e)=> setYear(e.target.value)}> 
    {yearArray.map((doc,key)=>(<option value={doc}>{doc}</option>))}
    </select>
            </div> 
        <div className="inputBx1">
                <span>Caption</span>
                <input type="text" value={title} onChange={(e)=> setTitle(e.target.value)}/>
               </div>
               {/* <div className="inputBx1">
                 <span>Notice Content</span>
                <textarea  onChange={(e)=> setText(e.target.value)} value={text} />
               </div> */}
               <div className="inputBx1">
                 <span>Images</span>
                 <MultiImageInput setImages={setImage}  images={image} theme="light" allowCrop={false} max={9999}/>
               </div>
       
        </div>
        <div className="footer">
        <button
            onClick={() => {
             setOpenModal(false)
             
            }}
        
          >
          Cancel
          </button>
      
     
          <button
            onClick={() => {
            UpdateDetails()
             
            }}
            disabled={buttonDisabled}
          >
          Update Gallery
          </button>
      
        </div>
        </>} 
      </div>
     
    </div>
  );
}

export default GalleryUpdateModal;






































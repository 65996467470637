import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";
import { ExportCSVCommon } from "../Common Components/ExportCSVCommon";
import {Modal, Button} from "react-bootstrap"

function ExportRegisteredMembersModal({ setOpenModal, allRegisteredMembers, activeRegisteredMembers, inactiveRegisteredMembers}) {

  return (
    
    <Modal
        show={true}
        onHide={setOpenModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Button</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <center>
            <br/>
            <ExportCSVCommon fileName={"MVT All Registered Members"} csvData={allRegisteredMembers} buttonText={"Export All Registered Members"}/> <br/><br/>
            <ExportCSVCommon fileName={"MVT Active Registered Members"} csvData={activeRegisteredMembers} buttonText={"Export Active Registered Members"} /> <br/><br/>
            <ExportCSVCommon fileName={"MVT Inactive Registered Members"} csvData={inactiveRegisteredMembers}buttonText={"Export Inactive Unregisterd Members"}/><br/><br/>
            </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setOpenModal(false)}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
  //   <div className="modalBackground1">
  //     <div className="modalContainer1" style={{height:"auto", maxHeight:"90vh", width:"auto", minWidth:"60vw", overflowY:"scroll"}}>
  //       <div className="titleCloseBtn">
  //         <button
  //           onClick={() => 
  //               setOpenModal(false)
  //           }
  //         >
  //           X
  //         </button>
  //       </div>
  //       <div className="title">
  // <center> <h2 style={{color:"red"}}>  !!Export Registered Members!! </h2> </center>

  //       </div>
       
  //       <div className="body">
        
  //       </div>
  //       <div className="footer">
  //      <button
  //           onClick={() => {
  //            setOpenModal(false)
             
  //           }}
        
  //         >
  //         Cancel
  //         </button>
        
     
         
       
  //       </div>
     
  //     </div>
   
  //   </div>
  );
}

export default  ExportRegisteredMembersModal;
import React, { useState,useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Fragment } from "react";
import Registration from "./Registration";
//import { LoginNavbar } from "../Navbar/LoginNavbar";
import "../Registration.css"
import dateFormat from "dateformat"
import firebase from "../Common Components/firebase";
import Timer from '../Common Components/Timer';
import Carousel from "react-material-ui-carousel";
import { refType } from "@mui/utils";
import { Alert } from "@mui/material";
import MVT_1 from '../Assets/SidePanels/MVT_1.jpeg'
import MVT_2 from '../Assets/SidePanels/MVT_2.jpeg'
import MVT_3 from '../Assets/SidePanels/MVT_3.jpeg'
import MVT_4 from '../Assets/SidePanels/MVT_4.jpeg'

export default function MemberVerification() {

  const [status, setStatus] = useState("")
  const [allUsers, setAllUsers] = useState([])
  const [name,setName] = useState("")
  const [showName, setShowName] = useState(false)
  const [otpReceived, setOtpReceived] = useState("");
  const[mid, setMid] = useState("")
  const[otpDisabled, setOtpDisabled] = useState(false)
  const[verifyDisabled, setVerifyDisabled] = useState(false)
  const[showRegistrationForm, setShowRegistrationForm] = useState(false)
  const[errorMessage, setErrorMessage] = useState("")
  const[showErrorMessage, setShowErrorMessage] = useState(false)
  const [images, setImages] = useState({0:MVT_1, 1:MVT_2, 2:MVT_3, 3:MVT_4})
  const[showOtpSentMessage, setShowOtpSentMessage] = useState(false)  
  const[otpToBeChecked,setOtpToBeChecked] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [count, setCount] = useState(0)
  const [fathername, setFathername] = useState("");
  const [bloodGroup, setBloodGroup] = useState("")
  const [gender, setGender] = useState("")
  const [dob, setDob] = useState("")
  const [doa, setDoa] = useState("")
  const [address, setAddress] = useState("")
  const [spouse, setSpouse] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [spouseMobile, setSpouseMobile] = useState("")
  const [pan, setPan] = useState("")
  const [aliasName, setAliasName] = useState("")
  const [aadhar, setAadhar] = useState('')
  const [married, setMarried] = useState(false)
  const [spouseDob, setSpouseDob] = useState("")
  const [memstatus, setMemStatus] = useState(false)
  const [memberInactiveModal, setMemberInactiveModal] = useState(false)
  var max = 99999;
    var min = 10000
  const [otp, setOtp] = useState(-1);
 


  const otpCheck = () => {

    if (otpReceived == otpToBeChecked) {
       
        setShowRegistrationForm(true)
      
  }
  else{
      setErrorMessage("Wrong OTP Entered")
      setShowErrorMessage(true)
      
  }
}
 

useEffect(()=>{
  let items =[]
  firebase.firestore().collection("newreg").onSnapshot((querySnapshot)=>{
    querySnapshot.forEach((doc)=>{
      items.push(doc.data())  
    })
    setAllUsers(items)
  })
},[])
  


function GetUsersData(){

  for(let i=0; i< allUsers.length; i++){
    if(allUsers[i]["whatsapp"] == whatsapp){
      if(allUsers[i].registrationstatus == "n" && allUsers[i].memstatus == "active"){
        setMemStatus(allUsers[i].memstatus)
        allUsers[i].name != undefined && setName(allUsers[i].name)
        allUsers[i].whatsapp != undefined && setWhatsapp(allUsers[i].whatsapp)
        allUsers[i].aliasname != undefined && setAliasName(allUsers[i].aliasname)
        allUsers[i].bloodgroup != undefined && setBloodGroup(allUsers[i].bloodgroup)
        allUsers[i].fathersname != undefined && setFathername(allUsers[i].fathersname)
        allUsers[i].gender != undefined && setGender(allUsers[i].gender)
        allUsers[i].address != undefined && setAddress(allUsers[i].address)
        allUsers[i].pan != undefined && setPan(allUsers[i].pan)
        allUsers[i].aadhar != undefined && setAadhar(allUsers[i].aadhar)
        allUsers[i].spouse != undefined && setSpouse(allUsers[i].spouse)
        allUsers[i].dobirth != undefined && setDob(allUsers[i].dobirth)
        allUsers[i].doanniversary != undefined && setDoa(allUsers[i].doanniversary)
        allUsers[i].mid != undefined && setMid(allUsers[i].mid)
        allUsers[i].email != undefined && setEmail(allUsers[i].email)
        allUsers[i].mobile != undefined && setMobile(allUsers[i].mobile)
        allUsers[i].spousemobile != undefined && setSpouseMobile(allUsers[i].spousemobile)
        allUsers[i].spousedobirth != undefined && setSpouseDob(allUsers[i].spousedobirth)
        allUsers[i].married != undefined && allUsers[i].married == "yes" ? setMarried(true) : setMarried(false)
  
          setShowName(true)
          return;
    }
    if(allUsers[i].registrationstatus == "n" && allUsers[i].memstatus=="inactive"){
      window.alert("Your Membsership is Inactive !1\nPlease Contact the Administrator")
      return;
    }
    if(allUsers[i].registrationstatus == "r"){
      window.alert("Member Already Registered")
      return;
    }
  }
  }
  window.alert("Your Whatsapp No. is not registered with MVT !!\nPlease contact the Administrator")

}


useEffect(()=>{
  setOtp(Math.floor(Math.random() * (max - min) + min))
  setCount(0)
},[count])
  const otpGenerator = () =>{
    
    setCount(1)
       if(status=="r"){
        setErrorMessage("User Already Registered !!")
        setShowErrorMessage(true)
    } 
    else if(status==null || status==undefined ){
        setErrorMessage("Wrong MID / User not present")
        setShowErrorMessage(true)
    
    }
    else
      if( status.trim()==""){
  try{
    if(whatsapp.trim().length == 10){
     // setOtpToBeChecked(otp)
      setOtpToBeChecked("123456")
     
      const msg = `OTP is ${otp} for Registration in MaYuM Muz App vide Registration No. ${mid}.%0aWe welcome you onboard.%0aRegards,%0aMarwari Yuva Manch%0aMuzaffarpur`
  
      setShowOtpSentMessage(true)
      setVerifyDisabled(true)
      setShowErrorMessage(false)
      setOtpDisabled(true)
   
    }else{
      window.alert("Please Enter Valid Whatsapp Number")
      setOtp("")

    }
    
  } catch(err){
      
    };

   
    
   
  }

  }
  function Edit(){
    setOtpDisabled(false)
    setShowOtpSentMessage(false)
    setShowName(false)

  }

  return (
    <Fragment>
      {/* {memberInactiveModal && (<MemberInactiveModal setOpenModal={setMemberInactiveModal} />)} */}
         {/* <LoginNavbar/>  
       <LoginPageNavbar/> */}
        {!showRegistrationForm ?<>
        <section>
         <div className="imgBx">
          <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx"  style={{marginTop:"2px"}}>
             <h2>Registration</h2>
             <div className="inputBx">
                 <span>WhatsApp</span>
                 <input type="text" maxLength={10} disabled={showName}  value={whatsapp} autoComplete="off" onChange={(e)=> setWhatsapp(e.target.value)} style={{margin:"0px !important", padding:"0px !important"}}/> 
               </div>
              
              {showName ? <>
                <div className="inputBx">
                 <span>Membership Id</span>
                 <input type="text" value={mid} disabled={true} autoComplete="off" maxLength={4} onChange={(e)=> setMid(e.target.value)} /> 
               </div>
               <div className="inputBx">
                <span> Note:- If you want to change the whatsapp no., <a href={`mailto:itadmin@marwariviakstrust.com?subject=Whatsapp No. Change Request&body=Hi,%0a%0aI found my Whatsapp no. for the Membership ID :- ${mid} to be incorrect.%0a%0aI am unable to proceed with the Registration in MVT Portal.`}>Please contact the IT Admin</a> </span>
               </div>
             
               <div className="inputBx">             
               
                {!otpDisabled ?  <input type="button" value="Get Otp" onClick={()=> otpGenerator()}  style={{width:"50%", position:"inline"}} /> :  <input type="button" value="Reset" onClick={Edit}   style={{width:"50%", position:"inline"}} />}
               </div>
               
            {showErrorMessage ?<Alert severity="error">{errorMessage}</Alert>:""}
            {showOtpSentMessage?<><p> <Timer otpgen={otpGenerator}/> </p>           
              
               </>:""}

               </>:<>
               <div className="inputBx">
               <input type="button" value="Proceed" onClick={()=> GetUsersData()}  style={{width:"50%", position:"inline"}} /> 
               </div>
               </>} {/*Show Name Closure */}
               
            {otpDisabled ?<>
             <div className="inputBx">
                 <span>OTP</span>
                 <input type="number" value={otpReceived} onChange={(e)=> setOtpReceived(e.target.value)}    disabled={!verifyDisabled} /> 
               </div>
               <div className="inputBx">             
                 <input type="button" value="Verify Otp" onClick={()=> otpCheck()}    disabled={!verifyDisabled}  /> 
               </div></>:""}

          {!otpDisabled ? 
               <div className="inputBx">
              <p>Already Have an account ? <a onClick={()=> window.location.pathname="/login"} style={{cursor:"pointer"}}> Sign In</a></p>
               </div>:""}
           </div>
         </div>
       </section>
</>:  <Registration name={name} gender={gender} fathername={fathername} bloodGroup={bloodGroup} email={email}  mobile={mobile} membershipId={mid} whatsapp={whatsapp}  address={address} dob={dob} doa={doa} spouse={spouse} pan={pan} aadhar={aadhar} aliasname={aliasName} spousemobile={spouseMobile} married={married} images={images} memstatus={memstatus} spousedob={spouseDob}/>}
    </Fragment>
  );
}
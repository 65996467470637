import React from "react";
import "./Footer.css"

export default function Footer(){
    
    return(
        <div className="footer">
            <span className="footer-content sideMargin">Copyright &#169; 2022 <a href="https://pankhudisoftware.com/">Pankhudi Software</a>. All rights reserved.</span>
        </div>
    )
}
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const SidebarData =[
    {
        title:'My Profile',
        url :'/',
 
    },

    {
        title:'Member Directory',
        url :'/member-portal',
        cName:'nav-links'
    },
 
    {
        title:"Gallery",
        url:"/gallery"
    },
    
    // {
    //     title:"Notice Board",
    //     url:"/notice",
      
    // },
 
    {
        title:'Update Profile',
        url :"/update-profile",
        cName:'nav-links'
    },

    {
        title:"Business Directory",
        url:"/businesses",
      
    },

    {
        title:"Trust Blood Bank",
        url :"/blood-bank",
        cName:'nav-links'

    },   

]
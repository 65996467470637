import React, { useState, useEffect, useContext } from "react";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import { toTitleCase } from "../Common Components/CommonFunctions";
import Select from "react-select";
import { ExportCSVCommon } from "../Common Components/ExportCSVCommon";
import { Modal , Button} from "react-bootstrap";
import { MDBAlert } from "mdbreact";
function EditRegisteredMembersModal({ setOpenModal, userDetails}) {

    const [memstatus, setMemstatus] = useState(userDetails.memstatus)
    const [memType, setMemType] = useState(userDetails.memtype)
    const [userID, setUserId] = useState(userDetails.userid)
    const [mId, setMid]  = useState(userDetails['Membership Id'])
    const [name, setName] = useState(userDetails.Name)
    const [membershipTypes, setMembershipTypes] = useState({})

    useEffect(()=>{ 
      firebase.firestore().collection("website").doc("membershiptypes").get().then((doc)=>{
        setMembershipTypes(doc.data())
      })
    },[])

    function SaveDetails(){
      firebase.firestore().collection("users").doc(userID).update({
        memtype : memType,
        memstatus : memstatus
      }).then(()=>{
        window.alert("The record have been updated !!")
        window.location.reload()

      }).catch(()=>{
        window.alert("Some Error Occured !! Please Try again later")
        window.location.reload()
      })
    }
 
  return (
    <Modal show={true}    onHide={setOpenModal}
    backdrop="static"
    keyboard={false}>
    <Modal.Header closeButton>
    <h1 style={{fontSize:"18px"}}>  Edit Registered Member</h1> 
    </Modal.Header>
    <Modal.Body>
               <center>
               <h4>Name :- {toTitleCase(name)}</h4>
               <h4>Membership ID :- {mId}</h4>
               <br/>
               </center>
               <div className="inputBx">
                 <span>Membership Status</span>
               <select value={memstatus} onChange={(e)=> setMemstatus(e.target.value)}>
                   <option value={"active"}>Active</option>
                   <option value={"inactive"}>In-active</option>

               </select>

               </div>
               <div className="inputBx">
                 <span>Membership Type</span>
               <select value={memType} onChange={(e)=> setMemType(e.target.value)}>
                 {Object.keys(membershipTypes).map((data)=>(
                   <option value={data}>{membershipTypes[data]}</option>
                 ))}

               </select>
               </div>

    </Modal.Body>
    <Modal.Footer>
          <Button variant="primary" onClick={()=>SaveDetails()}>
            Save
          </Button>
          <Button variant="secondary" onClick={()=> setOpenModal(false)}>
            Close
          </Button>

        </Modal.Footer>
    </Modal>
  );
}

export default  EditRegisteredMembersModal;
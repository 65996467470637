import React, { Fragment, useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat"
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "@mui/material";
import axios from "axios";
import "../Registration.css"
import Carousel from "react-material-ui-carousel";
//import { LoginNavbar } from "../Navbar/LoginNavbar";
import { LoginPageNavbar } from "../Navbar/LoginPageNavbar";
import RegistrationPart5 from "./RegistrationPart5";
export default function RegistrationPart4(props) {
 
  const[membershipId, setMid] = useState(props.membershipId)
  const [name, setName] = useState(props.name);
  const [fathername, setFathername] = useState(props.fathername);
  const [bloodGroup, setBloodGroup] = useState(props.bloodGroup)
  const [gender, setGender] = useState(props.gender)
  const [dob, setDob] = useState(props.dob)
  const [doa, setDoa] = useState(props.doa)
  const [address, setAddress] = useState(props.address)
  const [spouse, setSpouse] = useState(props.spouse);
  const [confirmPassword, setConfirmPassword] = useState("")
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  
  const [showMobileError, setShowMobileError] = useState(false)
  const [mobileErrorMessage, setMobileErrorMessage] = useState("Please Enter a valid Mobile No.")

  const[showConfirmPassword, setShowConfirmPassword] = useState(false)
 
  const [password, setPassword] = useState(props.password);
  const [mobile, setMobile] = useState(props.mobile);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isMarried, setIsMarried] = useState(props.married)
  const [marriedDisplay, setMarriedDisplay] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [eligible, setEligible] = useState(false)
  const [spouseEmail,setSpouseEmail] = useState("")
  const [spouseMobile, setSpouseMobile] = useState(props.spousemobile)
  const [pan, setPan] = useState(props.pan)
  const [aadhar, setAadhar] = useState(props.aadhar)
  const [showNextPart, setShowNextPart] = useState(false)
  const [aliasName, setAliasName] = useState(props.aliasname)

  
  function nextPart(){
      setShowNextPart(true)
  }



  useEffect(()=>{
    if(bloodGroup !="" && pan.trim().length == 10 && aadhar.trim().length ==12)
      setButtonDisabled(false)
    else
      setButtonDisabled(true)
  },[pan,aadhar,bloodGroup])
 


 

  function CheckEligible(value){
    if(value)
      return "eligible"
    else
      return "ineligible"
    
  }

  return (
    
    <Fragment>
      {/* <LoginNavbar/>
      <LoginPageNavbar/> */}
    {!showNextPart ? 

            <section>
         <div className="imgBx">
         <Carousel className="carousel1" indicators={false} autoPlay={true}>
            {Object.values(props.images).map((doc)=>(
            <> <img src ={doc} /></> 
            ))}
          </Carousel>       
         </div>
         <div className="contentBx">
           <div className="formBx">
             <h2>Registration</h2>
             
               
         
              
         <div className="inputBx">
<span>Blood Group</span><br/>
<select value={bloodGroup} onChange={(e)=> setBloodGroup(e.target.value)} >         
<option value="">Choose</option>
<option value="A+">A+</option>    
<option value="A-">A-</option>          
<option value="B+">B+</option>   
<option value="B-">B-</option>                 
<option value="A+">O+</option>    
<option value="O-">O-</option>    
<option value="AB+">AB+</option>            
  </select>  
</div>


<div className="inputBx">
<span>Eligible for Blood Donation ?</span><br/>
<label style={{marginLeft:"15px", fontSize: "20px", color:"#4D3A78"}} > No
</label>
<Switch
checked={eligible}
onClick={()=> setEligible(!eligible)}
color="primary"
style={{ fontSize: "20px"}}
/>
<label style={{ fontSize: "20px", color:"#4D3A78"}} > yes
</label>
</div>


<div className="inputBx">
                 <span>Pan</span>
                 <input type="text" value={pan} maxLength={10} autoComplete="off" onChange={(e)=> setPan(e.target.value)}/> 
               </div><div className="inputBx">
                 <span>Aadhar</span>
                 <input type="text" value={aadhar} maxLength={12} autoComplete="off" onChange={(e)=> setAadhar(e.target.value)}/> 
               </div>

               
               <div className="inputBx">             
                 <input type="button" value="Next"  onClick={nextPart} disabled={buttonDisabled} /> 
               </div>

            
           </div>
         </div>
       </section>
         :<RegistrationPart5 spousemobile = {spouseMobile}  memstatus={props.memstatus} salutation={props.salutation} married={props.married} name={name} gender={gender} fathername={fathername} bloodGroup={bloodGroup} email={email} password={password} mobile={mobile} membershipId={membershipId} eligible={eligible} whatsapp={whatsapp}  address={address} dob={dob} doa={doa} spouse={spouse} pan={pan} aadhar={aadhar} aliasname={aliasName} spousedob={props.spousedob} images={props.images}/>}

         
    </Fragment>
  );
}




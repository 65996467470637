import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/Auth";
import "./UpdateModal.css";
import firebase from "../Common Components/firebase";
import LoaderUi from "../Common Components/LoaderUi";
import ModalLoaderUi from "../Common Components/ModalLoaderUi";
import MultiImageInput from 'react-multiple-image-input';
import {toTitleCase} from "../Common Components/CommonFunctions"
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { Button, Modal } from "react-bootstrap";

function UpdateProductModal({ setOpenModal, businessId, businessName, userID}) {
    const [productPrice, setProductprice] = useState("")
    const [productDesc, setProductDesc] = useState("")
    const [productname, setProductname] = useState("")
    const [bizname, setBizname] = useState("")
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState({})
    const [urls, setUrls] = useState([])
    const [time, setTime] = useState("")
    const [productid, setProductid] = useState("")
    const storage = getStorage()
    // const [businessName, setBusinessName] = useState("")


 
        function UploadImages(){
            setLoading(true)
            let x = (new Date()).getTime().toString()
            setTime(x)
            let id = "products" + x
            setProductid(id)
          
            const z = Object.values(images)
           let temp=[]
            let y =-1
            
            // z.forEach((image, index)=>{
            //   y++
            //   const ref = firebase.storage().ref(`business/${userID}/${businessId}/products/${id}`).child(`images${y}.png`)
            //   const arr = image.split(",")
           
            //   ref.putString(arr[1], 'base64' ,{contentType:'image/png'}).then((snapshot)=>{
            //      snapshot.ref.getDownloadURL().then((url)=>{
                  
            //       //  businessRef.update({
            //       //    [`images${index}`] : url,
            //       //    images: x.toString()
            //       //  })
                  
            //       setUrls(prevstate=>[...prevstate,url])
             
            //      })
                
            //   })
            // })
            Object.values(images).map(async(image, key) => {
              const fileRef=ref(storage,`business/${userID}/${businessId}/products/${key}.png`)
              // const arr = image.split(",")
              const snapshot= await uploadString(fileRef,image,'data_url' )
              const photoURL= await getDownloadURL(fileRef)
              
            //  temp.push(photoURL)
              setUrls((prevState) => [...prevState, photoURL]);
            })

        }

        useEffect(()=>{
            const z = Object.values(images)
            let temp={}
           
            if(z.length > 0 && (z.length == urls.length)){
              urls.forEach((url, index)=>{
                temp[index] = url
        
              })
             
              const businessRef = firebase.firestore().collection("users").doc(userID).collection('business').doc(businessId).collection("products").doc(productid);
              businessRef.set({images : temp}).then(()=>{
                // window.alert("New Business Added !!")
                UpdateProducts(productid, time)

              })
              
            }
    
          },[urls])
           
    const UpdateProducts=(productid, x)=>{
  
    const business = firebase.firestore().collection("users").doc(userID).collection('business').doc(businessId).collection("products");

   
      const ref = business.doc(productid)
  
   ref.update({
    
    productprice: productPrice, 
    productdesc : productDesc,  
    productname: productname,   
    time: x,
    id: productid

   }).then(()=>{
       window.alert("New Product Added !!")
      setOpenModal(false)
      window.location.reload()
     // // history.push("/")
   }).catch(function(error){
     
   })
 
  }


  return (
 <>
 <Modal show={true} onHide={setOpenModal}
        backdrop="static"
        keyboard={false} >
    <Modal.Header closeButton>
 <h1 style={{fontSize:"20px"}}> Update Product  </h1>
  </Modal.Header>
  <Modal.Body>

      
        <div className="body">
            
        <div className="inputBx1">
                 <span>Business Name</span><br/>
                 <input type="text" value={businessName}  disabled={true}/> 
               </div>
        <div className="inputBx1">
                 <span>Product Name</span><br/>
                 <input type="text" value={productname}  onChange={(e)=> setProductname(e.target.value)}/> 
               </div>
               <div className="inputBx1"  >
                 <span>Product Description</span><br/>
                 <input type="text"  value={productDesc} onChange={(e)=> setProductDesc(e.target.value)}/> 
               
               </div>
          
               <div className="inputBx1">
                 <span>Product Price</span> <br/>
                 <input type="text" value={productPrice} onChange={(e)=> setProductprice(e.target.value)}/> 
               </div>
     
               <div className="inputBx1">
                 <span>Business Image</span><span style={{color:"red"}}>*</span><span><i style={{fontSize:"12px"}}>(Maximum 5)</i></span><br/>
                 <MultiImageInput
      images={images}
      setImages={setImages}
      theme="light"
      max={5}
      allowCrop={false}
   
    />

               </div>


        </div>
  
        </Modal.Body>
        <Modal.Footer>
        <Button
            onClick={() => {
             setOpenModal(false)
             
            }}
            variant ={"secondary"}
          >
          
          Cancel
          </Button>
      
     
          <Button
            onClick={() => {
          UploadImages()
        
            }}
             disabled={buttonDisabled}
             variant ="primary"
          >
          Add Products
          </Button>
      
        </Modal.Footer>
     

        </Modal>
    </>
  );
}

export default UpdateProductModal;